import React from 'react';
import PropTypes from 'prop-types';

import PublisherFilter from './PublisherFilter';
import DoiPrefixFilter from './DoiPrefixFilter';
import HandlePrefixFilter from './HandlePrefixFilter';
import JournalFilter from './JournalFilter';
import FunderFilter from './FunderFilter';
import SearchTerm from 'models/SearchTerm';

import FilterSet from 'components/Explorer/models/filter_set';

class PublicationSearchFields extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    initialSearchTerm: PropTypes.instanceOf(SearchTerm)
  };

  render() {
    return window.current_user.librarian ? this.librarianContent : this.allContent;
  }

  get allContent() {
    return (
      <div className="Explorer-AdvancedSearch-field-group PublicationSearchFields">
        <h3 className="Explorer-AdvancedSearch-field-group-title">
          {I18n.t('Explorer.AdvancedSearch.groups.publication')}
        </h3>
        <PublisherFilter
          filterSet={this.props.filterSet}
          initialSearch={this.props.initialSearchTerm.termForPublisher()}
        />
        <DoiPrefixFilter
          filterSet={this.props.filterSet}
          initialSearch={this.props.initialSearchTerm.termForDoiPrefix()}
        />
        <JournalFilter filterSet={this.props.filterSet} initialSearch={this.props.initialSearchTerm.termForJournal()} />
        <FunderFilter filterSet={this.props.filterSet} initialSearch={this.props.initialSearchTerm.termForFunder()} />
        <HandlePrefixFilter filterSet={this.props.filterSet} />
      </div>
    );
  }

  get librarianContent() {
    return (
      <div className="Explorer-AdvancedSearch-field-group PublicationSearchFields">
        <h3 className="Explorer-AdvancedSearch-field-group-title">
          {I18n.t('Explorer.AdvancedSearch.groups.publication_librarian')}
        </h3>
        <JournalFilter filterSet={this.props.filterSet} initialSearch={this.props.initialSearchTerm.termForJournal()} />
      </div>
    );
  }
}

export default PublicationSearchFields;
