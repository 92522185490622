import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';

import AutocompleteField from '../../AutocompleteField';
import HelpLink from '../HelpLink';

class SustainableDevelopmentGoalsFilter extends React.Component {
  // Setup
  ////////////////////////////////////////////////////////////////////////////

  query = null;
  ANALYTICS_FIELD_NAME = 'Sustainable Development Goals';

  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    initialSearch: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sustainable_development_goals: []
    };
    this.query = new Query(this.props.history);
  }

  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////

  componentDidMount() {
    if (this.sdgCodes.length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  ////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field SustainableDevelopmentGoalsFilter">
        {this.label}
        {this.state.loading ? this.loadingMessage : this.field}
      </div>
    );
  }

  get loadingMessage() {
    return <div>{I18n.t('Explorer.AdvancedSearch.fields.sustainable_development_goals.loading')}</div>;
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.sustainable_development_goals.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.sustainable_development_goals.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get field() {
    return (
      <AutocompleteField
        name="sustainable_development_goals"
        id="sustainable_development_goals"
        type="sustainable_development_goals"
        initialValue={this.props.initialSearch}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.sustainable_development_goals.placeholder')}
        onChangeItems={this.updateSelectedGoals}
        initiallySelected={this.state.sustainable_development_goals}
        ref={this.getFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  // Data
  ////////////////////////////////////////////////////////////////////////////

  updateSelectedGoals = (sustainable_development_goals) => {
    this.props.filterSet.set(
      'sustainable_development_goals[]',
      sustainable_development_goals.map((a) => a.id)
    );
  };

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.sustainable_development_goals();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({
          loading: false,
          sustainable_development_goals: response.body
        });
      } else {
        this.setState({ loading: false, sustainable_development_goals: [] });
      }
    });
  };

  get sdgCodes() {
    return this.props.filterSet.get('sustainable_development_goals[]').map((code) => ({ id: code }));
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };

  componentDidUpdate = () => {
    if (!this.fieldRef) return;
    if (!this.sdgCodes.length) this.fieldRef.clearAll();
  };
}

export default withRouter(SustainableDevelopmentGoalsFilter);
