import React from 'react';
import PropTypes from 'prop-types';

const ResultView = (props) => {
  return (
    <div className="Explorer-QuickSearch-ResultView">
      {props.resultSets.map((s) => (
        <ResultSet key={s.type} selectedResult={props.selectedResult} onSelectResult={props.onSelectResult} {...s} />
      ))}
      {props.loading ? <div className="loading-message">{I18n.t('Explorer.QuickSearch.loading')}</div> : null}
    </div>
  );
};

const ResultSet = (props) => {
  let title = I18n.t(`Explorer.QuickSearch.types.${props.type}.title`, {
    institutionName: window.current_user.institution_name
  });

  return (
    <div className="Explorer-QuickSearch-ResultSet">
      <div className="Explorer-QuickSearch-ResultSet-title">{title}</div>
      <div className="Explorer-QuickSearch-ResultSet-content">
        {props.results.map((r, idx) => {
          return (
            <Result
              key={`${r.id}-${idx}`}
              selected={r === props.selectedResult}
              onSelectResult={() => props.onSelectResult(r)}
              {...r}
            />
          );
        })}
      </div>
    </div>
  );
};

const Result = (props) => {
  const classes = ['Explorer-QuickSearch-Result'];
  if (props.selected) classes.push('selected');
  if (props.isLinkToMore) classes.push('link-to-more');
  classes.push(props.type);

  return (
    // eslint-disable-next-line -- jsx-a11y [TODO]
    <div className={classes.join(' ')} onMouseDown={props.onSelectResult}>
      <span className="title">{props.text}</span>
      <span className="meta">{props.meta}</span>
    </div>
  );
};

ResultView.propTypes = {
  resultSets: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedResult: PropTypes.object,
  onSelectResult: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

ResultSet.propTypes = {
  type: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedResult: PropTypes.object,
  onSelectResult: PropTypes.func.isRequired
};

Result.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  isLinkToMore: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  meta: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onSelectResult: PropTypes.func.isRequired
};

export default ResultView;
