import $ from 'jquery';
import { delay, find, min } from 'lodash-es';

import ResultType from './awesome_bar_results';
import Ajax from 'util/ajax';

const DEBOUNCE_TIME = 300; // milliseconds
const RESULT_LIMIT = 4;
const REMOTE_TYPES = [
  'authors',
  'departments',
  'publishers',
  'journals',
  'identifier',
  'doi_prefixes',
  'affiliations',
  'fields_of_research',
  'funders',
  'sustainable_development_goals'
];
const OUTPUT_TYPE_KEYWORDS = ['journal', 'article', 'book', 'chapter', 'clinical', 'data', 'news'];

class AwesomeBarSearchManager {
  constructor(text, { updateCallback, completeCallback }) {
    this.text = text;
    this.updateCallback = updateCallback;
    this.completeCallback = completeCallback;
    this.inFlightAjaxRequests = [];
    this.results = [];
    this.timer = null;
  }

  startLoading() {
    this.buildLocalResults();
    this.fetchRemoteResults();
  }

  buildLocalResults() {
    this.results.push({
      type: 'keyword',
      results: [new ResultType.Keyword(this.text)]
    });
    this.results.push({
      type: 'title',
      results: [new ResultType.Title(this.text)]
    });
    this.addIdentifierResultsIfRequired();
    this.addOutputTypeResultsIfRequired();
    this.addOrcidResultsIfRequired();
    this.notifyComponent();
  }

  addOutputTypeResultsIfRequired() {
    const textMatchesKeyword = (keyword) => {
      const len = min([keyword.length, this.text.length]);
      return this.text.slice(0, len) === keyword.slice(0, len);
    };

    OUTPUT_TYPE_KEYWORDS.forEach((keyword) => {
      if (!textMatchesKeyword(keyword)) return;

      switch (keyword) {
        case 'journal':
        case 'article':
          this.results.push({
            type: 'output_type',
            results: [new ResultType.OutputType('article')]
          });
          break;
        case 'book':
          if (!window.current_user.books) return;
          this.results.push({
            type: 'output_type',
            results: [new ResultType.OutputType('book'), new ResultType.OutputType('chapter')]
          });
          break;
        case 'chapter':
          if (!window.current_user.books) return;
          this.results.push({
            type: 'output_type',
            results: [new ResultType.OutputType('chapter')]
          });
          break;
        case 'clinical':
          this.results.push({
            type: 'output_type',
            results: [new ResultType.OutputType('clinical_trial_study_record')]
          });
          break;
        case 'data':
          this.results.push({
            type: 'output_type',
            results: [new ResultType.OutputType('dataset')]
          });
          break;
        case 'news':
          this.results.push({
            type: 'output_type',
            results: [new ResultType.OutputType('news')]
          });
          break;
      }
    });
  }

  addIdentifierResultsIfRequired() {
    const identifierResult = ResultType.Identifier.fromString(this.text);

    if (identifierResult) this.results.push({ type: 'identifier', results: [identifierResult] });
  }

  addOrcidResultsIfRequired() {
    const orcidResult = ResultType.Orcid.fromString(this.text);

    if (orcidResult) return this.results.push({ type: 'orcid', results: [orcidResult] });
  }

  fetchRemoteResults() {
    this.timer = delay(() => this.launchRemoteRequests(), DEBOUNCE_TIME);
  }

  terminate() {
    if (this.timer) clearTimeout(this.timer);

    this.inFlightAjaxRequests.forEach((req) => req.abort());
    this.inFlightAjaxRequests = [];
  }

  notifyComponent() {
    this.updateCallback(this.results);
  }

  launchRemoteRequests() {
    REMOTE_TYPES.forEach((type) => {
      const req = Ajax.doAwesomeSearch(type, this.text).done((results) => {
        if (results.length) {
          return this.updateWithRemoteResults(type, results);
        }
      });

      this.inFlightAjaxRequests.push(req);
    });

    if (this.inFlightAjaxRequests.length) {
      $.when(...this.inFlightAjaxRequests).always(this.completeCallback);
    }
  }

  updateWithRemoteResults(type, results) {
    const newResultSet = this.buildRemoteResultSet(type, results);
    this.appendOrReplaceResultSet(newResultSet);
    this.notifyComponent();
  }

  appendOrReplaceResultSet(resultSet) {
    const existingResultSet = find(this.results, (entry) => entry.type === resultSet.type);

    if (existingResultSet) {
      existingResultSet.results = resultSet.results;
    } else {
      this.results.push(resultSet);
    }
  }

  buildRemoteResultSet(type, results) {
    let resultClass;

    switch (type) {
      case 'authors':
        resultClass = ResultType.Author;
        break;
      case 'departments':
        resultClass = ResultType.Department;
        break;
      case 'publishers':
        resultClass = ResultType.Publisher;
        break;
      case 'journals':
        resultClass = ResultType.Journal;
        break;
      case 'identifier':
        resultClass = ResultType.Identifier;
        break;
      case 'doi_prefixes':
        resultClass = ResultType.DoiPrefix;
        break;
      case 'affiliations':
        resultClass = ResultType.Affiliation;
        break;
      case 'fields_of_research':
        resultClass = ResultType.FieldOfResearch;
        break;
      case 'funders':
        resultClass = ResultType.Funder;
        break;
      case 'sustainable_development_goals':
        resultClass = ResultType.SustainableDevelopmentGoal;
        break;
    }

    const resultModels = [];
    let total = 0;

    results.forEach((result) => {
      total = result.total; // We don't need to set this on every interaction, but it's tidier
      resultModels.push(new resultClass(result));
    });

    // In the case where there is only 1 additional result, there is no point
    // in displaying a 'see more results' link, because that just means an
    // extra click. Instead, display the actual result unless there are ≥ 2 more
    // results in the response.
    if (total === resultModels.length + 1) {
      resultModels.push(new resultClass(results[RESULT_LIMIT]));
    } else if (total > resultModels.length) {
      resultModels.push(new ResultType.More(type, total - RESULT_LIMIT));
    }

    return { type, results: resultModels };
  }
}

export default AwesomeBarSearchManager;
