import React from 'react';

class Badge extends React.Component {
  render() {
    return (
      <div className="badge-wrapper">
        <img
          className="badge"
          src={this.props.output.donut}
          alt={I18n.t('badge.score', { score: this.props.output.score })}
        />
        {this.verifiedTick()}
      </div>
    );
  }

  verifiedTick() {
    if (!this.props.output.verified) return null;

    return (
      <div className="verified-badge">
        <div
          className="icon"
          title={I18n.t('badge.verified', {
            name: window.current_user.institution_name
          })}
        ></div>
      </div>
    );
  }
}

export { Badge };
