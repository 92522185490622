import React from 'react';
import PropTypes from 'prop-types';

class ClearableTextField extends React.Component {
  static propTypes = {
    initialValue: PropTypes.string,
    fieldID: PropTypes.string,
    fieldName: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    ariaLabel: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = { query: this.props.initialValue || '' };
  }

  render() {
    return (
      <div className="clearable-field">
        <div className="clearable-field-inner">
          <div className="clearable-field-inner-content">
            <input
              id={this.props.fieldID}
              name={this.props.fieldName}
              autoComplete="off"
              type="text"
              value={this.state.query}
              onChange={this.didUpdateQuery}
              placeholder={this.props.placeholder}
              aria-label={this.props.ariaLabel}
            />
            {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
            <div className={`reset-button ${this.state.query.length > 0 ? '' : 'hidden'}`} onClick={this.clearSearch}>
              {I18n.t('clearable.clear')}
            </div>
          </div>
        </div>
      </div>
    );
  }

  didUpdateQuery = (e) => {
    const text = e.target.value;

    if (text.trim() === '') {
      this.clearSearch();
    } else {
      this.setState({ query: text });
      if (this.props.onChange) this.props.onChange(text);
    }
  };

  clearSearch = () => {
    this.setState({ query: '' });

    if (this.props.onChange) this.props.onChange('');
  };
}

export default ClearableTextField;
