import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';
import AutocompleteField from 'components/Explorer/AutocompleteField';
import JournalList from './JournalList';
import HelpLink from '../HelpLink';

class JournalFilter extends React.Component {
  ANALYTICS_FIELD_NAME = 'Journal';

  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    initialSearch: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      journals: []
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    if (this.journalIds.length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  componentDidUpdate() {
    if (!this.autocompleteFieldRef) return;
    if (!this.journalIds.length) this.autocompleteFieldRef.clearAll();
  }

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field-container JournalFilter">
        {this.label}
        <fieldset>{this.content}</fieldset>
      </div>
    );
  }

  get content() {
    if (this.state.loading) return this.loadingMessage;

    return (
      <div>
        {!this.props.filterSet.get('journal_list_id') ? this.autocompleteField : ''}
        {this.issnListEditor}
      </div>
    );
  }

  get issnListEditor() {
    if (window.current_user.librarian) return;

    return (
      <JournalList
        filterSet={this.props.filterSet}
        onChange={this.onJournalListChange}
        analyticsDialogueName="Journal ISSNs"
      />
    );
  }

  onJournalListChange = () => {
    this.forceUpdate();
  };

  get loadingMessage() {
    return <div>{I18n.t('Explorer.AdvancedSearch.fields.journal.loading')}</div>;
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.journal.name')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.journal.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get autocompleteField() {
    return (
      <AutocompleteField
        name="journal_id"
        id="journal_id"
        type="journals"
        initialValue={this.props.initialSearch}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.journal.placeholder')}
        onChangeItems={this.updateSelectedJournals}
        initiallySelected={this.state.journals}
        ref={this.getAutocompleteFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  getAutocompleteFieldRef = (elm) => {
    this.autocompleteFieldRef = elm;
  };

  updateSelectedJournals = (journals) => {
    this.props.filterSet.set(
      'journal_id[]',
      journals.map((journal) => journal.id)
    );
  };

  fetchData = async () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.journalNames();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, journals: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  get journalIds() {
    return this.props.filterSet.get('journal_id[]');
  }
}

export default withRouter(JournalFilter);
