import { delay } from 'lodash-es';
import Ajax from 'util/ajax';
import $ from 'jquery';

const REPORT_POPOVER_WIDTH = 1200;
const REPORT_POPOVER_HEIGHT = 800;

const setup = () => {
  bindUnsupportedInfo();
  bindDeletes();
  bindEmailTest();
  bindEmailSubscription();
  bindRename();
  bindReportPopover();
  handleReportUpdates();
  bindDefaultButton();
  handleSearchTitleClicked();
  handleShareableReportIconClicked();
};

const bindUnsupportedInfo = () =>
  $(document).on('click', '.unsupported-saved-searches-content .unsupported-header', function (e) {
    e.preventDefault();

    Analytics.trackEvent('showUnsupportedSavedSearches');
    $(this).hide();
    return $('.unsupported-saved-searches-inner-content').show();
  });
const bindDeletes = () => {
  $(document)
    .on('click', '.saved-search-list .delete', (_e) => {
      trackEvent('Icon Clicked', 'Delete Report');
    })
    .on('click', '.saved-search-list .restore', (_e) => {
      trackEvent('Icon Clicked', 'Restore Report');
    });
};

const bindDefaultButton = () => {
  $(document)
    .on('click', '.saved-search-list .default-toggle.not_selected', (_e) => {
      trackEvent('Field Item Selected', 'Set Default');
    })
    .on('click', '.saved-search-list .default-toggle.selected', (_e) => {
      trackEvent('Field Item Deselected', 'Set Default');
    });
};

const bindEmailTest = () => {
  $(document).on('click', '.saved-search-list input.send-test', function (_e) {
    const $button = $(this);
    $button.addClass('loading');

    trackEvent('Icon Clicked', 'Send Example Report');

    delay(() => $button.removeClass('loading'), 1000);
  });
};

const bindEmailSubscription = () => {
  $(document).on('click', '.saved-search-list .report button', trackEmailSubscription);
};

const trackEmailSubscription = function (_e) {
  const $button = $(this);
  const wasSubscribed = $button.hasClass('subscribed');

  const capitalize = (str) => str && str[0].toUpperCase() + str.slice(1);
  const analyticsFieldName = `${capitalize($(this).data('frequency'))} Email`;

  if (wasSubscribed) {
    $button.addClass('unsubscribing');
    trackEvent('Field Item Deselected', analyticsFieldName);
  } else {
    $button.addClass('subscribing');
    trackEvent('Field Item Selected', analyticsFieldName);
  }
};

const bindRename = () => {
  $(document).on('submit', '.saved-search-list .rename form', function (e) {
    e.preventDefault();

    const url = $(this).attr('action');
    const newTitle = $(this).find('textarea').val();

    Ajax.updateSavedSearchTitle(url, newTitle).done((response) => {
      const $titleLink = $(this).closest('tr').find('a.title');
      $titleLink.text(response.title);
      $(this).closest('tr').removeClass('renaming');
      trackEvent('Section Saved', 'Rename Report');
    });
  });

  $(document).on('click', '.saved-search-list .rename-action', function (e) {
    e.preventDefault();

    trackEvent('Icon Clicked', 'Rename Report');

    const $currentForm = $(this).closest('tr');
    $currentForm.toggleClass('renaming');
    const $field = $currentForm.find('.rename textarea');
    $field.focus()[0].setSelectionRange(0, $field.val().length);

    const $otherForms = $('.saved-search-list > table > tbody > tr.renaming').not($currentForm);
    $otherForms.each((index, form) => {
      $(form).removeClass('renaming');
      restoreOriginalText($(form));
    });
  });

  $(document).on('click', '.saved-search-list .rename .cancel', function (e) {
    e.preventDefault();

    trackEvent('Section Edit Cancelled', 'Rename Report');

    $(this).closest('tr').removeClass('renaming');
    restoreOriginalText($(this));
  });

  $(document).on('keydown', '.saved-search-list .rename textarea', function (e) {
    if (e.keyCode === 13 && !e.ctrlKey) {
      e.preventDefault();
      $(this).closest('form').submit();
    }

    if (e.keyCode === 27) {
      $(this).closest('tr').removeClass('renaming');
      restoreOriginalText($(this));
    }
  });
};

const bindReportPopover = () => {
  $(document).on('click', '.saved-search-list .report.custom a', function (e) {
    e.preventDefault();
    const url = $(e.target).attr('href');
    const left = window.screen.width / 2 - REPORT_POPOVER_WIDTH / 2;
    const top = window.screen.height / 2 - REPORT_POPOVER_HEIGHT / 2;
    window.open(
      url,
      'reportPopover',
      `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, copyhistory=no, width=${REPORT_POPOVER_WIDTH}, height=${REPORT_POPOVER_HEIGHT}, top=${top}, left=${left}`
    );
  });
};

const updateSavedSearchList = () => {
  const $wrapper = $('.saved-search-list');
  if (!$wrapper.length) return;

  Ajax.updateSavedSearchList().done((html) => $wrapper.html(html));
};

const handleReportUpdates = () => {
  window.updateSavedSearches = updateSavedSearchList;
};

const handleSearchTitleClicked = () => {
  $(document).on('click', '.main .title', () => {
    trackEvent('Link Clicked', 'Search Title');
  });
};

const handleShareableReportIconClicked = () => {
  $(document).on('click', '.report.custom .share', () => {
    trackEvent('Icon Clicked', 'Create/Edit Report');
  });
};

const restoreOriginalText = function (elem) {
  const originalText = elem
    .closest('tr')
    .find('a.title')
    .text()
    .replace(/^Search for\s/, '');
  const $field = elem.closest('tr').find('.rename textarea');
  $field.val(originalText);
};

const trackEvent = (eventName, propertyName) => {
  Analytics.trackEvent(`Saved Search: ${eventName}`, { name: propertyName });
};

export { setup };
