import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Export from 'components/Explorer/Export';
import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';
import SummaryBlock from 'components/Explorer/ContentPanel/SummaryBlock';

import SaveSearch from 'components/Explorer/SaveSearch';

import helpFor from 'util/help.js';

class Summary extends React.Component {
  query = null;

  static propTypes = {
    history: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      forbidden: false,
      total: 0,
      totalMentions: 0
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  render() {
    return (
      <SummaryBlock hasWarning={this.state.mentions === 0}>
        {this.state.loading ? (
          I18n.t('Explorer.MentionSources.Summary.loading')
        ) : (
          <>
            <div>{this.summarySentence}</div>
            <Export endpoint="mention_sources" help={helpFor('mention_sources')} />
            <SaveSearch endpoint="mention_sources" analyticsName="Mention Sources" />
          </>
        )}
      </SummaryBlock>
    );
  }

  get summarySentence() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div>
        {I18n.htmlSafe('Explorer.MentionSources.Summary.mention_sources_html', {
          count: this.state.total,
          formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.total)
        })}{' '}
        {I18n.htmlSafe('Explorer.MentionSources.Summary.mentions_html', {
          count: this.state.totalMentions,
          formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.totalMentions)
        })}
      </div>
    );
  }

  get errorMessage() {
    return <div>{this.state.forbidden ? '' : I18n.t('Explorer.loading_error')}</div>;
  }

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false, forbidden: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.mentionSourcesSummary();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let total = 0;
      let totalMentions = 0;
      const forbidden = response?.status === 403;
      const loadingFailed = err || typeof response?.body === 'undefined';

      if (!loadingFailed) {
        const responseBody = response.body;
        total = responseBody.total;
        totalMentions = responseBody.total_mentions;
      }

      this.setState({
        loading: false,
        loadingFailed: loadingFailed,
        forbidden: forbidden,
        total: total,
        totalMentions: totalMentions
      });
    });
  };
}

export default withRouter(Summary);
