import React from 'react';
import PropTypes from 'prop-types';
import { concat, filter, flow, includes, sumBy, uniq } from 'lodash-es';

import NumberHelper from 'util/number_helper';

class TimelineChartLegend extends React.Component {
  static propTypes = {
    entries: PropTypes.array.isRequired,
    selectedPostTypes: PropTypes.array.isRequired,
    updateSelectedPostTypes: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="TimelineChartLegend">
        {this.renderShowAll()}
        {this.props.entries.map((entry) => this.renderEntry(entry))}
      </div>
    );
  }

  renderEntry(entry) {
    if (entry.mention_count === 0) return;

    const classes = [entry.tab_id, this.currentlySelected(entry.post_type) ? 'active' : 'inactive'].join(' ');

    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <div onClick={(event) => this.didClickEntry(entry, event)} key={entry.tab_id} className="entry">
        <div className={`content ${classes}`}>
          <div className="active-content">{this.renderInnerContent(entry)}</div>
          <div className="inactive-content">{this.renderInnerContent(entry)}</div>
        </div>
      </div>
    );
  }

  renderShowAll() {
    const total = sumBy(this.props.entries, 'mention_count');

    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <div className="entry" onClick={this.hideAllPostTypes} key="show-all">
        <div className={`content show-all ${this.props.selectedPostTypes.length === 0 ? 'active' : 'inactive'}`}>
          <div className="active-content">
            <div className="inner">
              <div className="name">{I18n.t('Explorer.Timeline.chart.all_types')}</div>
              <div className="mentions">{NumberHelper.formatNumberWithDelimiter(total)}</div>
            </div>
          </div>
          <div className="inactive-content">
            <div className="inner">
              <div className="name">{I18n.t('Explorer.Timeline.chart.all_types')}</div>
              <div className="mentions">{NumberHelper.formatNumberWithDelimiter(total)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInnerContent(entry) {
    return (
      <div className="inner">
        <div className="name">{I18n.t(entry.post_type, { scope: 'Explorer.Timeline.chart.types' })}</div>
        <div className="mentions">{NumberHelper.formatNumberWithDelimiter(entry.mention_count)}</div>
      </div>
    );
  }

  currentlySelected(type) {
    return includes(this.props.selectedPostTypes, type);
  }

  didClickEntry = (entry, event) => {
    if (event.metaKey || event.ctrlKey) {
      this.appendOrToggleEntry(entry);
    } else {
      if (this.currentlySelected(entry.post_type) && this.props.selectedPostTypes.length === 1) {
        this.hideAllPostTypes();
      } else {
        this.showSinglePostType(entry.post_type);
      }
    }
  };

  appendOrToggleEntry = (entry) => {
    if (this.currentlySelected(entry.post_type)) {
      this.hidePostType(entry.post_type);
    } else {
      this.showPostType(entry.post_type);
    }
  };

  hideAllPostTypes = () => {
    this.setTypes([]);
  };

  hidePostType = (type) => {
    this.setTypes(flow((p) => filter(p, (f) => type !== f))(this.props.selectedPostTypes));
  };

  showPostType = (type) => {
    this.setTypes(flow((p) => concat(p, type), uniq)(this.props.selectedPostTypes));
  };

  showSinglePostType = (type) => {
    this.setTypes([type]);
  };

  setTypes = (types) => {
    Analytics.trackEvent('timeline-set-post-types', { types });
    this.props.updateSelectedPostTypes(types);
  };
}

export default TimelineChartLegend;
