import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';

import Export from 'components/Explorer/Export';
import SaveSearch from 'components/Explorer/SaveSearch';
import SummaryBlock from 'components/Explorer/ContentPanel/SummaryBlock';

import helpFor from 'util/help.js';

class Summary extends React.Component {
  query = null;

  static propTypes = {
    history: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      totalOutputs: 0,
      mentionedOutputs: 0
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  render() {
    return (
      <SummaryBlock>
        {this.state.loading ? (
          I18n.t('Explorer.Outputs.Summary.loading')
        ) : (
          <>
            <div>{this.content}</div>
            <Export
              endpoint="research_outputs"
              resultsCount={this.state.totalOutputs}
              help={helpFor('research_outputs')}
            />
            <SaveSearch endpoint="outputs" analyticsName="Research Outputs" />
          </>
        )}
      </SummaryBlock>
    );
  }

  get content() {
    if (this.state.loadingFailed) return I18n.t('Explorer.Outputs.error');
    if (this.state.totalOutputs === 0) return I18n.t('Explorer.Outputs.Summary.no_results');

    return (
      <div>
        {I18n.htmlSafe('Explorer.Outputs.Summary.research_outputs_html', {
          count: this.state.totalOutputs,
          formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.totalOutputs)
        })}{' '}
        {I18n.htmlSafe('Explorer.Outputs.Summary.mentions_html', {
          count: this.state.mentionedOutputs,
          formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.mentionedOutputs)
        })}
      </div>
    );
  }

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.researchOutputsSummary();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        const responseBody = response.body;

        this.setState({
          loading: false,
          totalOutputs: responseBody.total_outputs,
          mentionedOutputs: responseBody.mentioned_outputs
        });
      } else {
        this.setState({ loading: false, loadingFailed: true });
      }
    });
  };
}

export default withRouter(Summary);
