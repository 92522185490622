import { filter, includes, map, sortBy, sumBy } from 'lodash-es';
import Source from './Source';

class Category {
  static COLORS = {
    social: '#42A5F5',
    academic: '#757575',
    news: '#e53935',
    grey: '#673AB7',
    other: '#FFB300'
  };

  static SOURCE_CATEGORIES = {
    social: ['tweet', 'fbwall', 'gplus', 'linkedin', 'weibo', 'rdt', 'pinterest'],
    academic: ['f1000', 'peer_review'],
    news: ['msm', 'blog'],
    grey: ['policy', 'patent'],
    other: ['wikipedia', 'qna', 'video']
  };

  static buildFromData = (data) => {
    const sources = data.map((d) => new Source(d.post_type, d.mention_count));

    const categories = map(Category.SOURCE_CATEGORIES, (sourceNames, categoryName) => {
      const categorySources = filter(sources, (source) => includes(sourceNames, source.name));

      return new Category(
        categoryName,
        sortBy(categorySources, (s) => -s.mentionCount)
      );
    });

    return sortBy(categories, (c) => -c.mentionCount);
  };

  constructor(name, sources) {
    this.name = name;
    this.sources = sources;
  }

  get mentionCount() {
    return sumBy(this.sources, 'mentionCount');
  }

  get scaledMentionCount() {
    return Math.sqrt(sumBy(this.sources, 'mentionCount'));
  }

  get color() {
    return Category.COLORS[this.name];
  }
}

export default Category;
