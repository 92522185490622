import { flow, flatten, compact, pickBy } from 'lodash-es';

class FilterSet {
  filters = null;

  constructor(filters) {
    this.filters = filters;
  }

  get populatedFilters() {
    return pickBy(this.filters, (value) => value);
  }

  set(field, value) {
    const isArray = field.slice(-2) === '[]';
    const fieldNames = isArray ? [field, field.slice(0, -2)] : [field];

    if (typeof value === 'string' && value.trim() === '') {
      fieldNames.forEach((n) => this.clear(n));
    } else {
      fieldNames.forEach((n) => this.clear(n));
      this.filters[field] = value;
    }
  }

  get(field) {
    const isArray = field.slice(-2) === '[]';

    if (isArray) {
      return flow(flatten, compact)([this.filters[field.slice(0, -2)], this.filters[field]]);
    } else {
      return this.filters[field];
    }
  }

  clear(field) {
    delete this.filters[field];
  }

  reset() {
    this.filters = {};
  }
}

export default FilterSet;
