import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';

class SortBy extends React.Component {
  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  render() {
    return (
      <div className="Explorer-MentionSources-SortBy">
        <label className="label" htmlFor="sortby-select">
          {I18n.t('Explorer.MentionSources.SortBy.label')}
        </label>
        <select id="sortby-select" onChange={this.onSortByChange} value={this.query.mentionSourceOrder}>
          <option value="mentions_count_desc">
            {I18n.t('Explorer.MentionSources.SortBy.sort_options.mentions_count_desc')}
          </option>
          <option value="followers_desc">{I18n.t('Explorer.MentionSources.SortBy.sort_options.followers_desc')}</option>
        </select>
      </div>
    );
  }

  onSortByChange = (event) => {
    if (event.target.value === 'followers_desc') {
      Analytics.trackEvent('sort-mention-author-followers');
    }

    this.props.history.push(this.query.withMentionSourceOrder(event.target.value).location);
  };
}

export default withRouter(SortBy);
