import $ from 'jquery';
import { map } from 'lodash-es';
import { getCSRF, getCSRFParmName } from './rails';

const setup = () => {
  $.ajaxPrefilter((options, _originalOptions, xhr) => {
    if (!options.crossDomain) {
      const token = getCSRF();
      if (token) xhr.setRequestHeader('X-CSRF-Token', token);
    }
  });
};

const doAwesomeSearch = (type, query) => {
  return ajaxWithErrorHandler({
    url: `/explorer/awesome/${type}`,
    data: {
      q: query
    },
    dataType: 'json'
  });
};

const doAutocompleteSearch = (type, query) => {
  return ajaxWithErrorHandler({
    url: `/explorer/autocomplete/${type}`,
    data: {
      q: query
    },
    dataType: 'json'
  });
};

const saveSearch = (url) => {
  return ajaxWithErrorHandler({
    method: 'post',
    url: url,
    dataType: 'json'
  });
};

const loadResults = (url) => {
  return ajaxWithErrorHandler({
    method: 'get',
    url: url,
    dataType: 'html'
  });
};

const loadMentionTimeline = (url) => {
  return ajaxWithErrorHandler({
    url: url
  });
};

const loadJournalsAnalysis = (url) => {
  return ajaxWithErrorHandler({
    url: url,
    dataType: 'json'
  });
};

const loadAnalysisTab = (url) => {
  return ajaxWithErrorHandler({
    method: 'get',
    url: url
  });
};

const updateSavedSearchTitle = (url, title) => {
  return ajaxWithErrorHandler({
    method: 'patch',
    url: url,
    data: {
      title: title
    }
  });
};

const updateSavedSearchList = () => {
  return ajaxWithErrorHandler({
    method: 'get',
    url: '/explorer/saved_searches'
  });
};

const createIdentifierList = (content) => {
  return ajaxWithErrorHandler({
    method: 'post',
    url: '/explorer/identifier_lists',
    data: {
      identifier_list: {
        identifiers: content
      }
    }
  });
};

const createPubmedSearch = (query) => {
  return ajaxWithErrorHandler({
    method: 'post',
    url: '/explorer/pubmed_searches',
    data: {
      pubmed_search: {
        query: query
      }
    }
  });
};

const createJournalList = (content) => {
  return ajaxWithErrorHandler({
    method: 'post',
    url: '/explorer/journal_lists',
    data: {
      journal_list: {
        issns: content
      }
    }
  });
};

const getCSVIntegrationPanel = () => {
  return ajaxWithErrorHandler({
    method: 'get',
    url: '/explorer/admin/csv_integration'
  });
};

const saveCSVUpload = (data) => {
  return ajaxWithErrorHandler({
    method: 'post',
    url: '/explorer/admin/save_csv_upload',
    dataType: 'json',
    data: data
  });
};

const publishExistingDataset = (id) => {
  return ajaxWithErrorHandler({
    method: 'post',
    url: '/explorer/admin/publish_dataset',
    dataType: 'json',
    data: {
      dataset_id: id
    }
  });
};

const checkPublishingProgress = (jobID) => {
  return ajaxWithOptions({
    method: 'get',
    url: `/explorer/admin/publishing_progress/${jobID}`
  });
};

const ajaxWithOptions = (options) => {
  switch (options.method && options.method.toLowerCase()) {
    case 'put':
    case 'post':
    case 'delete':
      options.data || (options.data = {});
      options.data[getCSRFParmName()] = getCSRF();
  }
  return $.ajax(options);
};

const ajaxWithErrorHandler = (options) => {
  return ajaxWithOptions(options).fail((xhr) => {
    let body, str;
    if (xhr.readyState === 0 || xhr.status === 0) {
      // No error
    } else if (xhr.status === 401) {
      return window.location.reload();
    } else if (xhr.status === 400) {
      body = xhr.responseJSON;
      if (body != null ? body.errors : void 0) {
        str = displayErrors(body.errors);
        return window.alert(str);
      } else {
        return window.alert(I18n.t('frontend.error'));
      }
    } else {
      return window.alert(I18n.t('frontend.error'));
    }
  });
};

const displayErrors = (errors) => {
  return map(errors, (messages, field) => {
    return map(messages, (message) => `${field} ${message}`).join('\n');
  }).join('\n');
};

export default {
  setup,
  doAwesomeSearch,
  doAutocompleteSearch,
  saveSearch,
  loadResults,
  loadMentionTimeline,
  loadJournalsAnalysis,
  loadAnalysisTab,
  updateSavedSearchTitle,
  updateSavedSearchList,
  createIdentifierList,
  createPubmedSearch,
  createJournalList,
  publishExistingDataset,
  saveCSVUpload,
  checkPublishingProgress,
  getCSVIntegrationPanel
};
