import React from 'react';

import { ProgressBar } from './ProgressBar';
import { IntroPanel } from './IntroPanel';
import { UploadHierarchyPanel } from './UploadHierarchyPanel';
import { VerifyHierarchyPanel } from './VerifyHierarchyPanel';
import { OutputsUploadPanel } from './OutputsUploadPanel';
import { OutputsConfigurationPanel } from './OutputsConfigurationPanel';
import { OutputsCheckPanel } from './OutputsCheckPanel';
import { DatasetPublishProgressPanel } from '../DatasetPublishProgressPanel';
import { LoadingScreen } from './LoadingScreen';

import { delay } from 'lodash-es';

const STAGES = {
  INTRO: 0,
  UPLOAD_HIERARCHY: 1,
  VERIFY_HIERARCHY: 2,
  UPLOAD_OUTPUTS: 3,
  IDENTIFY_COLUMNS: 4,
  CHECK_RESULTS: 5,
  PUBLISH: 6
};

class DatasetUploadPopover extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////
  constructor() {
    super();

    this.state = {
      stage: 0,
      dataset: null,
      hierarchy: null,
      extraSaveMessage: null,
      extraSaveMessages: [],
      messageIndex: 0,
      saveCompleted: false,
      loading: false,
      showCloseButton: false
    };
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="DatasetUploadPopover">
        <div className="DatasetUploadPopover-overlay" />
        <div className="DatasetUploadPopover-popover">
          {this.renderSidebar()}
          <div className="DatasetUploadPopover-main">
            {this.state.loading && <LoadingScreen>Processing research outputs…</LoadingScreen>}
            <div className="DatasetUploadPopover-content" ref={this.storeScrollablePanel}>
              {this.renderContent()}
            </div>
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

  renderSidebar() {
    if (this.isSaving) return;

    return (
      <div className="DatasetUploadPopover-sidebar">
        <ProgressBar currentStage={this.state.stage} onSelectStage={this.selectStage} />;
        <div className="DatasetUploadPopover-cancel-button">
          <button className="cancel" onClick={this.close}>
            {I18n.t('dataset_upload.cancel')}
          </button>
        </div>
      </div>
    );
  }

  renderContent() {
    switch (this.state.stage) {
      case STAGES.INTRO:
        return <IntroPanel />;
      case STAGES.UPLOAD_HIERARCHY:
        return (
          <UploadHierarchyPanel
            onUpload={this.updateHierarchy}
            onSkip={this.skipHierarchy}
            setLoadingState={this.setLoading}
          />
        );
      case STAGES.VERIFY_HIERARCHY:
        return (
          <VerifyHierarchyPanel
            hierarchy={this.state.hierarchy}
            onReturn={this.revertStage}
            onAdvance={this.advanceStage}
          />
        );
      case STAGES.UPLOAD_OUTPUTS:
        return (
          <OutputsUploadPanel
            hierarchy={this.state.hierarchy}
            onNewDataset={this.updateDataset}
            setLoadingState={this.setLoading}
          />
        );
      case STAGES.IDENTIFY_COLUMNS:
        return <OutputsConfigurationPanel dataset={this.state.dataset} />;
      case STAGES.CHECK_RESULTS:
        return (
          <OutputsCheckPanel
            dataset={this.state.dataset}
            onSave={this.saveDataset}
            onReUpload={this.returnToOutputsUpload}
          />
        );
      case STAGES.PUBLISH:
        return <DatasetPublishProgressPanel showCloseButton={this.state.showCloseButton} />;
    }
  }

  renderFooter() {
    if (this.isSaving) return;

    return (
      <div className="DatasetUploadPopover-footer">
        <div className="DatasetUploadPopover-buttons left buttons">
          <button disabled={!this.canRevert} className="back" onClick={this.revertStage}>
            {I18n.t('dataset_upload.back')}
          </button>
        </div>
        <div className="DatasetUploadPopover-buttons right buttons">
          <button disabled={!this.canAdvance} className="next" onClick={this.advanceStage}>
            {I18n.t('dataset_upload.next')}
          </button>
        </div>
      </div>
    );
  }

  // State
  //////////////////////////////////////////////////////////////////////////////

  get canAdvance() {
    switch (this.state.stage) {
      case STAGES.INTRO:
      case STAGES.IDENTIFY_COLUMNS:
        return true;
      case STAGES.VERIFY_HIERARCHY:
        return this.state.hierarchy && this.state.hierarchy.valid;
      default:
        return false;
    }
  }

  get canRevert() {
    return this.state.stage > STAGES.INTRO;
  }

  get isSaving() {
    return this.state.stage === STAGES.PUBLISH;
  }

  // Event handlers
  //////////////////////////////////////////////////////////////////////////////

  updateDataset = (dataset) => {
    this.setState({ dataset }, () => this.advanceStage());
  };

  updateHierarchy = (hierarchy) => {
    this.setState({
      hierarchy: hierarchy,
      stage: STAGES.VERIFY_HIERARCHY
    });
  };

  skipHierarchy = () => {
    this.setState({
      hierarchy: null,
      stage: STAGES.UPLOAD_OUTPUTS
    });
  };

  returnToOutputsUpload = () => {
    this.setState({
      dataset: null,
      stage: STAGES.UPLOAD_OUTPUTS
    });
  };

  selectStage = (stage) => this.setState({ stage });

  revertStage = () => {
    let stage = this.state.stage - 1;

    // If we stepped back to hierarchy verification, then we will need to step
    // back an additional stage if there has been no hierarchy uploaded so far.
    if (stage === STAGES.VERIFY_HIERARCHY && !this.state.hierarchy) {
      stage = STAGES.UPLOAD_HIERARCHY;
    }

    this.setState({ stage });
  };

  advanceStage = () => {
    let nextStage = this.state.stage + 1;

    if (nextStage === STAGES.CHECK_RESULTS) {
      this.state.dataset.buildResearchOutputs();
    }

    this.setState({ stage: nextStage });
  };

  saveDataset = () => {
    let handler = () => {
      this.advanceStage();

      const extraSaveMessages = I18n.t('dataset_upload.saving.extra_messages');
      this.setState({ extraSaveMessages });

      this.state.dataset.save({
        hierarchy: this.state.hierarchy,
        onSubmitted: () => {
          this.updateExtraSaveMessage();
          this.setState({ showCloseButton: true });
        },
        onProgress: () => {
          this.updateExtraSaveMessage();
        },
        onComplete: () => {
          this.setState({ completed: true });
          this.refreshAndClose();
        },
        onError: (_error) => {
          alert(I18n.t('dataset_upload.saving.error'));
          this.setState({ extraSaveMessage: null });
          this.revertStage();
        }
      });
    };

    this.setState({ saving: true }, handler);
  };

  close() {
    require('../../modules/admin_panel').hidePublishingPopovers();
  }

  updateExtraSaveMessage = () => {
    const { extraSaveMessages, messageIndex } = this.state;
    const nextIndex = (messageIndex + 1) % extraSaveMessages.length;
    const extraSaveMessage = extraSaveMessages[nextIndex];
    this.setState({ extraSaveMessage, messageIndex: nextIndex });
  };

  refreshAndClose = () => {
    require('../../modules/admin_panel').refreshCSVPanel();

    delay(() => {
      this.close();
    }, 2000);
  };

  setLoading = (loading) => {
    this.setState({ loading });
  };

  // Internal state
  //////////////////////////////////////////////////////////////////////////////

  storeScrollablePanel = (elm) => {
    this.scrollablePanel = elm;
  };

  UNSAFE_componentWillUpdate(_, newState) {
    if (this.state.stage !== newState.stage) {
      if (this.scrollablePanel) {
        this.scrollablePanel.scrollTop = 0;
      }
    }
  }
}

export { DatasetUploadPopover };
