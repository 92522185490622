import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';
import { each } from 'lodash-es';

import AutocompleteFieldConfig from 'components/Explorer/models/autocomplete_field_config';
import { CountriesField, DateField } from 'components/Explorer/Form';
import config from 'util/site_config.js.erb';
import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';
import MentionSourcesFilter from '../MentionSourcesFilter';
import { timeframeMinimumDate } from '../../util/timeframe_minimum_date';

class FilterPanel extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static propTypes = {
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      maximumDate: '9999-12-31',
      minimumDate: '0001-01-01'
    };
  }

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.setupFilterSet, Query.EVENTS.didChangeFilters);
    this.setupFilterSet();
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-Form Explorer-Mentions-FilterPanel">
        <div className="Explorer-Mentions-FilterPanel-row">
          <MentionSourcesFilter
            sourcesTypes={this.typeOptions}
            filterSet={this.state.filterSet}
            queryFilter="mentionSourcesForFilter"
            assignRef={this.storeMentionSourcesField}
            disabled={this.state.highlights}
            dropdownSourcesType="post_type"
            autocompleteFieldConfig={this.autocompleteFieldConfig}
          />
          <button
            className={`Explorer-Mentions-Highlights-toggle ${this.state.highlights ? 'active' : 'not-active'}`}
            onClick={this.toggleHighlights}
          >
            {I18n.t('Explorer.Mentions.FilterPanel.buttons.highlights')}
          </button>
        </div>
        <div className="Explorer-Mentions-FilterPanel-row">
          <div className="Explorer-Mentions-FilterPanel-date-range">
            {this.mentionedAfter}
            {this.mentionedBefore}
          </div>
          <CountriesField
            filterSet={this.state.filterSet}
            label={I18n.t('Explorer.Mentions.FilterPanel.fields.countries.title')}
            placeholder={I18n.t('Explorer.Form.CountriesField.placeholder')}
            onChangeCallback={this.trackCountriesSearch}
            ref={this.storeCountriesField}
          />
          {this.buttons}
        </div>
      </div>
    );
  }

  get typeOptions() {
    const options = {};
    each(
      config.mentionsTabPostTypes,
      (type) =>
        (options[type] = I18n.t(type, {
          scope: 'Explorer.Mentions.FilterPanel.select_box_mention_source_post_types',
          default: type
        }))
    );

    return options;
  }

  onMentionedAfterChange = (dateText) => {
    this.setState({
      minimumDate: dateText
    });
  };

  get mentionedAfter() {
    return (
      <div className="Explorer-FilterPanel-field">
        <DateField
          fieldID="mentioned_after"
          fieldName="mentioned_after"
          label={I18n.t('Explorer.Mentions.FilterPanel.fields.mentioned_after.title')}
          filterSet={this.state.filterSet}
          placeholder={I18n.t('Explorer.Mentions.FilterPanel.fields.mentioned_after.placeholder')}
          onChangeCallback={this.onMentionedAfterChange}
          minimumSelectableDate={timeframeMinimumDate(this.state.filterSet.get('timeframe'))}
          maximumDate={this.state.maximumDate}
          ref={this.storeMentionedAfterField}
        />
      </div>
    );
  }

  onMentionedBeforeChange = (dateText) => {
    this.setState({
      maximumDate: dateText
    });
  };

  get mentionedBefore() {
    return (
      <div className="Explorer-FilterPanel-field">
        <DateField
          fieldID="mentioned_before"
          fieldName="mentioned_before"
          label={I18n.t('Explorer.Mentions.FilterPanel.fields.mentioned_before.title')}
          filterSet={this.state.filterSet}
          placeholder={I18n.t('Explorer.Mentions.FilterPanel.fields.mentioned_before.placeholder')}
          onChangeCallback={this.onMentionedBeforeChange}
          minimumDate={this.state.minimumDate}
          ref={this.storeMentionedBeforeField}
        />
      </div>
    );
  }

  get buttons() {
    return (
      <div className="Explorer-FilterPanel-buttons">
        <button className="apply-button" onClick={this.applySearch}>
          {I18n.t('Explorer.Mentions.FilterPanel.buttons.apply')}
        </button>
        <button className="clear-button" onClick={this.resetSearch}>
          {I18n.t('Explorer.Mentions.FilterPanel.buttons.reset')}
        </button>
      </div>
    );
  }

  get autocompleteFieldConfig() {
    let config = new AutocompleteFieldConfig();
    config.id = 'mention_sources';
    config.name = 'mention_sources[]';
    config.type = 'mention_sources';
    config.placeholder = I18n.t('Explorer.Mentions.FilterPanel.fields.mention_sources.placeholder');

    return config;
  }

  // Actions
  //////////////////////////////////////////////////////////////////////////////

  storeMentionedBeforeField = (field) => {
    this.mentionedBeforeField = field;
  };

  storeMentionedAfterField = (field) => {
    this.mentionedAfterField = field;
  };

  storeCountriesField = (field) => {
    this.countriesField = field;
  };

  storeMentionSourcesField = (field) => {
    this.mentionSourcesField = field;
  };

  setupFilterSet = () => {
    this.setState(
      {
        filterSet: new FilterSet(this.query.filters),
        highlights: this.query.filters.highlights === 'true'
      },
      this.clearAutocompleteFilters
    );
  };

  clearSearch = () => {
    if (this.mentionedBeforeField) this.mentionedBeforeField.clear();
    if (this.mentionedAfterField) this.mentionedAfterField.clear();
    if (this.countriesField) this.countriesField.clear();
    if (this.mentionSourcesField) this.mentionSourcesField.clear();
  };

  resetSearch = () => {
    this.clearSearch();
    this.applySearch();
  };

  applySearch = () => {
    Analytics.trackEvent('mentions-apply-filter');

    const newLocation = this.query.withFilters(this.state.filterSet.populatedFilters).location;

    if (newLocation.search !== window.location.search.replace('?', '')) {
      this.props.history.push(newLocation);
    }
  };

  toggleHighlights = () => {
    Analytics.trackEvent('mentions-set-highlights', {
      enabled: !this.state.highlights
    });

    this.setState({ highlights: !this.state.highlights }, this.highlightsSearch);
  };

  highlightsSearch() {
    if (this.state.highlights) {
      if (this.mentionSourcesField) {
        this.mentionSourcesField.clear();
      }

      this.state.filterSet.set('highlights', 'true');
    } else {
      this.state.filterSet.clear('highlights');
    }

    this.query = this.query.withFilters(this.state.filterSet.populatedFilters);
    this.props.history.push(this.query.location);
  }

  trackCountriesSearch() {
    Analytics.trackEvent('mentions-update-countries');
  }

  clearAutocompleteFilters = () => {
    if (this.countriesField && this.state.filterSet.get('countries[]').length == 0) {
      this.countriesField.clearSelected();
    }
    if (this.mentionSourcesField && this.state.filterSet.get('mention_sources[]').length == 0) {
      this.mentionSourcesField.clearSelected();
    }
  };
}

export default withRouter(FilterPanel);
