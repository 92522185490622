import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import withRouter from 'components/withRouter';

import NumberHelper from 'util/number_helper';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';
import Editor from './Editor';
import HelpLink from '../../HelpLink';

class IdentifierFilter extends React.Component {
  ANALYTICS_DIALOGUE_NAME = 'Scholarly Identifiers';

  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static propTypes = {
    history: PropTypes.object,
    filterSet: PropTypes.instanceOf(FilterSet).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showEditor: false,
      identifierList: null,
      loading: true
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    if (this.hasList()) {
      this.loadIdentifierList();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  componentDidUpdate = () => {
    if (this.state.identifierList && !this.hasList()) this.setState({ identifierList: null });
  };

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <div className="Explorer-AdvancedSearch-field-container IdentifierFilter" onKeyDown={(e) => e.stopPropagation()}>
        {this.label}
        <fieldset>
          <legend>{I18n.t('Explorer.AdvancedSearch.fields.identifiers.title')}</legend>
          {this.state.loading ? this.loadingMessage : this.content}
        </fieldset>
        {this.editor}
      </div>
    );
  }

  get loadingMessage() {
    return (
      <div className="IdentifierFilter-loading-message">
        {I18n.t('Explorer.AdvancedSearch.fields.identifiers.loading')}
      </div>
    );
  }

  get content() {
    return this.state.identifierList ? this.editExistingListContent : this.createNewListContent;
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.identifiers.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.identifiers.description')}
          analyticsFieldName="Scholarly Identifiers"
        />
      </label>
    );
  }

  get createNewListContent() {
    return (
      <div className="IdentifierFilter-content">
        <div className="IdentifierFilter-identifier-create-buttons">
          <button
            type="button"
            className="IdentifierFilter-add-button"
            onClick={this.onClickCreate}
            ref={(ref) => (this.newButton = ref)}
          >
            {I18n.t('Explorer.AdvancedSearch.fields.identifiers.add')}
          </button>
        </div>
      </div>
    );
  }

  get editExistingListContent() {
    return (
      <div className="IdentifierFilter-content">
        <div className="IdentifierFilter-identifier-description">{this.descriptionString}</div>
        <div className="IdentifierFilter-identifier-edit-buttons">
          <button
            type="button"
            className="IdentifierFilter-edit-button"
            onClick={this.onClickEdit}
            ref={(ref) => (this.editButton = ref)}
          >
            {I18n.t('Explorer.AdvancedSearch.fields.identifiers.edit')}
          </button>
          <button type="button" className="IdentifierFilter-clear-button" onClick={this.onClickClear}>
            {I18n.t('Explorer.AdvancedSearch.fields.identifiers.clear')}
          </button>
        </div>
      </div>
    );
  }

  get editor() {
    if (!this.state.showEditor) return;

    return (
      <Editor
        identifierList={this.state.identifierList}
        onCancel={this.onCancelEdit}
        onSave={this.onSaveEdit}
        analyticsDialogueName={this.ANALYTICS_DIALOGUE_NAME}
      />
    );
  }

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  get descriptionString() {
    const descriptions = this.descriptions;

    if (descriptions.length > 0) {
      return descriptions.join(', ');
    } else {
      return I18n.t('Explorer.AdvancedSearch.fields.identifiers.empty');
    }
  }

  get descriptions() {
    return map(this.state.identifierList.counts, (count, type) => {
      return (
        count > 0 &&
        I18n.t(type, {
          scope: 'Explorer.AdvancedSearch.fields.identifiers.count',
          formattedCount: NumberHelper.formatNumberWithDelimiter(count),
          count: count
        })
      );
    }).filter((d) => d);
  }

  // Events
  //////////////////////////////////////////////////////////////////////////////

  onClickCreate = () => {
    this.setState({ showEditor: true });
  };

  onClickEdit = () => {
    this.setState({ showEditor: true });
  };

  onClickClear = () => {
    this.props.filterSet.clear('identifier_list_id');
    this.props.filterSet.clear('identifier');
    this.setState({ identifierList: null }, () => this.refocus());

    Analytics.trackEvent('Advanced Search: Modal Dialogue Cleared', {
      dialogue_name: this.ANALYTICS_DIALOGUE_NAME
    });
  };

  onCancelEdit = () => {
    this.setState({ showEditor: false });

    this.refocus();
  };

  onSaveEdit = (identifierList) => {
    this.props.filterSet.set('identifier_list_id', identifierList.id);
    this.props.filterSet.clear('identifier');

    this.setState(
      {
        identifierList: identifierList,
        showEditor: false
      },
      () => this.refocus()
    );
  };

  refocus = () => {
    this.state.identifierList ? this.editButton.focus() : this.newButton.focus();
  };

  // Data handling
  //////////////////////////////////////////////////////////////////////////////

  loadIdentifierList = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.identifierList();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, identifierList: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  hasList() {
    return this.props.filterSet.get('identifier_list_id') || this.props.filterSet.get('identifier');
  }
}

export default withRouter(IdentifierFilter);
