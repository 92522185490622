import React from 'react';

const EmptyMessage = () => {
  return (
    <div className="Journals-Table-Message-Container Journals-Table-Message-Container--empty">
      {I18n.t('Explorer.Outputs.empty')}
    </div>
  );
};

export default EmptyMessage;
