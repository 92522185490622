const attachFocusLimiter = (container) => container.addEventListener('keydown', limitFocus);
const detachFocusLimiter = (container) => container.removeEventListener('keydown', limitFocus);

function limitFocus(e) {
  const focusableEls = this.querySelectorAll(
    'input:not([disabled]), button:not([disabled]), textarea:not([disabled]), a[href]'
  );
  const firstEl = focusableEls[0];
  const lastEl = focusableEls[focusableEls.length - 1];
  const isTabPressed = e.key === 'Tab';

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) {
    // shift + tab
    if (document.activeElement === firstEl) {
      lastEl.focus();
      e.preventDefault();
    }
  } else {
    // tab
    if (document.activeElement === lastEl) {
      firstEl.focus();
      e.preventDefault();
    }
  }
}

export { attachFocusLimiter, detachFocusLimiter };
