import './styles';

import React from 'react';

import Summary from './Summary';
import JournalsAnalysisTable from './JournalsAnalysisTable';
import PageTitle from 'components/Explorer/PageTitle';

const Journals = ({ history }) => {
  return (
    <div className="Explorer-Journals">
      <PageTitle metaTitle={I18n.t('Explorer.TabBar.journals')} />
      <Summary />
      <JournalsAnalysisTable history={history} />
    </div>
  );
};

export default Journals;
