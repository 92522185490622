import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';

import Export from 'components/Explorer/Export';
import SaveSearch from 'components/Explorer/SaveSearch';
import SummaryBlock from 'components/Explorer/ContentPanel/SummaryBlock';

import helpFor from 'util/help.js';

class Summary extends React.Component {
  query = null;

  static propTypes = {
    history: PropTypes.object,
    latestChartDate: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      totalOutputs: null
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  render() {
    return (
      <SummaryBlock>
        {this.state.loading ? (
          I18n.t('Explorer.Timeline.Summary.loading')
        ) : (
          <>
            <div>{this.content}</div>
            <Export endpoint="attention" resultsCount={this.state.totalOutputs} help={helpFor('attention')} />
            <SaveSearch endpoint="timeline" analyticsName="Timeline" />
          </>
        )}
      </SummaryBlock>
    );
  }

  get content() {
    if (this.state.loadingFailed) {
      return this.errorMessage;
    } else if (this.state.totalOutputs === 0) {
      return this.emptyMessage;
    } else {
      return this.summaryDescription;
    }
  }

  get errorMessage() {
    return I18n.t('Explorer.Timeline.Summary.error');
  }

  get emptyMessage() {
    return I18n.t('Explorer.Timeline.Summary.no_results');
  }

  get summaryDescription() {
    return (
      <>
        <p>
          {I18n.htmlSafe('Explorer.Timeline.Summary.research_outputs', {
            count: this.state.totalOutputs,
            formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.totalOutputs)
          })}
        </p>
        <p>
          {I18n.htmlSafe('Explorer.Timeline.Summary.last_refreshed', {
            lastRefreshed: this.props.latestChartDate.calendar()
          })}
        </p>
      </>
    );
  }

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.timelineSummary();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let totalOutputs = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        totalOutputs = response.body.total_outputs;
      }

      this.setState({
        loading: false,
        loadingFailed: loadingFailed,
        totalOutputs: totalOutputs
      });
    });
  };
}

export default withRouter(Summary);
