import './styles';

import React from 'react';
import withRouter from 'components/withRouter';
import PropTypes from 'prop-types';

import PageTitle from 'components/Explorer/PageTitle';
import Query from 'components/Explorer/models/query';
import Chart from './Chart';
import Summary from './Summary';
import Legend from './TimelineChartLegend';
import dayjs from 'util/date';

class Timeline extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  query = null;

  state = {
    loading: true,
    loadingFailed: false,
    chartData: [],
    legendData: [],
    selectedPostTypes: [],
    latestTimelineDate: dayjs().endOf('day').subtract(1, 'day')
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-Timeline">
        <PageTitle metaTitle={I18n.t('Explorer.TabBar.timeline')} />
        {this.help}
        <Summary latestChartDate={this.state.latestTimelineDate} />

        <div className="Explorer-Timeline-Container">{this.state.loading ? this.loadingMessage : this.content}</div>
      </div>
    );
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;
    if (!this.state.chartData.length) return this.emptyMessage;

    return (
      <div>
        <Legend
          entries={this.state.legendData}
          selectedPostTypes={this.state.selectedPostTypes}
          updateSelectedPostTypes={this.updateSelectedPostTypes}
        />
        <Chart
          data={this.state.chartData}
          selectedPostTypes={this.state.selectedPostTypes}
          latestChartDate={this.state.latestTimelineDate}
        />
      </div>
    );
  }

  get errorMessage() {
    return <div className="Explorer-Timeline-error-message">{I18n.t('Explorer.Timeline.error')}</div>;
  }

  get emptyMessage() {
    return <div className="Explorer-Timeline-empty-message">{I18n.t('Explorer.Timeline.empty')}</div>;
  }

  get loadingMessage() {
    return <div className="Explorer-Timeline-loading-message">{I18n.t('Explorer.Timeline.loading')}</div>;
  }

  get help() {
    return (
      <a
        href={I18n.t('Explorer.help.timeline.uri')}
        target="_blank"
        rel="noreferrer"
        title={I18n.t('Explorer.help.timeline.content')}
        className="help"
      >
        {I18n.t('Explorer.help.label_tab')}
      </a>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.timeline();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let newState = { chartData: null, legendData: null };
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        newState = {
          chartData: response.body.chart,
          legendData: response.body.legend
        };
      }

      this.setState({ loading: false, loadingFailed, ...newState });
    });
  };

  updateSelectedPostTypes = (types) => {
    this.setState({ selectedPostTypes: types });
  };
}

export default withRouter(Timeline);
