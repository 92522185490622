import React, { useState, useRef } from 'react';

const DropdownMenu = ({ title, disabled = false, children }) => {
  const [expanded, setExpanded] = useState(false);
  const navElRef = useRef(null);

  return (
    <nav
      ref={navElRef}
      className="DropdownMenu"
      onMouseEnter={() => !disabled && setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}
      onBlur={(event) => {
        if (!navElRef.current?.contains(event.relatedTarget)) {
          setExpanded(false);
        }
      }}
    >
      <button
        className="DropdownMenu-Toggle-Button"
        aria-expanded={expanded}
        aria-haspopup="menu"
        disabled={disabled}
        onClick={() => !disabled && setExpanded(!expanded)}
      >
        {title}
      </button>
      <div role="menu" hidden={!expanded} className="DropdownMenu-Content-Wrapper">
        <div className="DropdownMenu-Content">{children}</div>
      </div>
    </nav>
  );
};

export default DropdownMenu;
