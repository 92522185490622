import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import NewsHighlight from './NewsHighlight';
import Query from 'components/Explorer/models/query';

class NewsHighlights extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  state = {
    loading: true,
    loadingFailed: false,
    data: {}
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-NewsHighlights Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.NewsHighlights.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition classNames="Content" timeout={250}>
            {this.state.loading ? this.loadingMessage : this.content}
          </Transition>
          {this.jumpLink}
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div key="loadingMessage" className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div key="content" className="Highlights-content Highlights-NewsHighlights-content">
        {this.hasResults ? this.contentTable : this.emptyMessage}
      </div>
    );
  }

  get errorMessage() {
    return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get contentTable() {
    return (
      <table className="content-table">
        <tbody>
          {this.state.data.map((highlight) => (
            <NewsHighlight key={`${highlight.id}-highlight`} highlight={highlight} />
          ))}
        </tbody>
      </table>
    );
  }

  get emptyMessage() {
    return <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.NewsHighlights.empty')}</div>;
  }

  get jumpLink() {
    if (!this.hasResults) return;

    return (
      <Link onClick={this.trackJumpLink} to={this.jumpLocation} className="Highlights-jump-link">
        {I18n.t('Explorer.Highlights.NewsHighlights.more')}
      </Link>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsNewsHighlights();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body;
      }

      this.setState({ loading: false, loadingFailed, data });
    });
  };

  get hasResults() {
    return this.state.data && this.state.data.length > 0;
  }

  get jumpLocation() {
    const newFilters = this.query.filters;
    newFilters.highlights = true;

    return this.query.withFilters(newFilters).locationWithPathname('/mentions');
  }

  trackJumpLink = () => {
    Analytics.trackEvent('highlights-click-jump-link', {
      jumpedTo: 'news-highlights'
    });
  };
}

export default withRouter(NewsHighlights);
