import React from 'react';

import DropdownMenu from 'components/DropdownMenu';

const AddSourceDropdown = ({ sourcesTypes, addSelectedSource, disabled = false }) => {
  return (
    <DropdownMenu title="Add source" disabled={disabled}>
      <div>
        {Object.entries(sourcesTypes).map(([type, label]) => (
          <button key={type} className={`${type} type`} onClick={() => addSelectedSource(type)}>
            {label}
          </button>
        ))}
      </div>
    </DropdownMenu>
  );
};

export default AddSourceDropdown;
