import $ from 'jquery';
import { defer, maxBy, min } from 'lodash-es';

const SPACING = 8; // pixels between tooltip and highlighted item

const setup = () => {
  $(document).on('mouseenter', '[title]', (e) => {
    const $element = $(e.currentTarget);
    const content = $(e.currentTarget).attr('title');
    if (e.currentTarget.getAttribute('aria-expanded') === 'true') return;

    new ToolTip(content, $element).show();
  });
};

class ToolTip {
  constructor(content, target) {
    this.content = content;
    this.target = target;
    this.element = $('<div class="hovering-tool-tip"/>').html(this.content);
    this.target.data('tooltip-element', this.element);
  }

  show() {
    $('body').append(this.element);
    // Remove the title attribute from the target - it will be restored later,
    // but if it is present during hover then we will see a native tooltip.
    $(this.target).attr('title', '');
    $(this.target).on('mouseleave click', this.hide.bind(this));
    $(window).on('scroll', this.hideIfRequired.bind(this));

    defer(() => {
      this.reposition();
      this.makeVisible();
    });
  }

  hideIfRequired() {
    if (!this.target.is(':hover')) this.hide();
  }

  hide() {
    this.element.remove();
    $(this.target).attr('title', this.content);
    this.target.off('mouseleave click', this.hide);
    $(window).off('scroll', this.hideIfRequired);
  }

  reposition() {
    const potentialPositions = this.getPotentialPositions();
    const selectedPosition = maxBy(potentialPositions, 'score');
    this.setElementPosition(selectedPosition);
  }

  makeVisible() {
    this.element.addClass('visible');
  }

  setElementPosition(position) {
    this.element.addClass(position.arrow).css({
      top: position.top,
      left: position.left
    });
  }

  getPotentialPositions() {
    return ['top', 'right', 'bottom', 'left'].map(
      (direction) => new TooltipPosition(direction, this.element, this.target)
    );
  }
}

class TooltipPosition {
  constructor(direction, element, target) {
    this.direction = direction;
    this.element = element;
    this.target = target;

    this.calculatePosition();
    this.calculateScore();
  }

  calculatePosition() {
    const o = this.target.offset();

    const targetTop = o.top;
    const targetRight = o.left + this.target.outerWidth();
    const targetBottom = o.top + this.target.outerHeight();
    const targetLeft = o.left;
    const targetCenterX = (targetLeft + targetRight) / 2;
    const targetCenterY = (targetTop + targetBottom) / 2;
    const tooltipWidth = this.element.outerWidth();
    const tooltipHeight = this.element.outerHeight();

    switch (this.direction) {
      case 'top':
        this.top = targetTop - SPACING - tooltipHeight;
        this.left = targetCenterX - tooltipWidth / 2;
        this.arrow = 'bottom';
        break;
      case 'right':
        this.top = targetCenterY - tooltipHeight / 2;
        this.left = targetRight + SPACING;
        this.arrow = 'left';
        break;
      case 'bottom':
        this.top = targetBottom + SPACING;
        this.left = targetCenterX - tooltipWidth / 2;
        this.arrow = 'top';
        break;
      case 'left':
        this.top = targetCenterY - tooltipHeight / 2;
        this.left = targetLeft - SPACING - tooltipWidth;
        this.arrow = 'right';
        break;
    }
  }

  calculateScore() {
    const scrollTop = $(window).scrollTop();

    const topSpace = this.top - scrollTop;
    const rightSpace = $(window).width() - (this.left + this.element.width());
    const bottomSpace = $(window).height() - (topSpace + this.element.height());
    const leftSpace = this.left;

    this.score = min([topSpace, bottomSpace, leftSpace, rightSpace]);
  }
}

export { setup };
