import dayjs from 'util/date';

class ResearchOutput {
  constructor(data) {
    Object.assign(this, data);
  }

  get meta() {
    const metaScope = { scope: 'research_outputs.results.meta' };

    if (this.journal && this.publicationDate) {
      return I18n.htmlSafe('journal_and_date_html', {
        ...metaScope,
        type: this.type,
        journal: this.journal,
        date: this.publicationDate
      });
    } else if (this.journal) {
      return I18n.htmlSafe('journal_only_html', {
        ...metaScope,
        type: this.type,
        journal: this.journal
      });
    } else if (this.publicationDate) {
      return I18n.htmlSafe('date_only_html', {
        ...metaScope,
        type: this.type,
        date: this.publicationDate
      });
    } else {
      return this.type;
    }
  }

  get publicationDate() {
    try {
      if (this.pubdate) {
        const date = dayjs(this.pubdate).utc();
        return date.format('MMMM YYYY');
      }
      // Sometimes this.pubdate stores invalid dates. When Date() cannot parse
      // them it throws Error, that we catch here. Sadly we can't catch anything
      // more specific that Error.
      return;
    } catch (Error) {
      return undefined;
    }
  }

  get type() {
    return I18n.t(`research_outputs.results.types.${this.outputType}`);
  }
}

export default ResearchOutput;
