import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import NumberHelper from 'util/number_helper';
import FilterSet from 'components/Explorer/models/filter_set';
import Query from 'components/Explorer/models/query';
import Editor from './Editor';

class JournalList extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    onChange: PropTypes.func,
    analyticsDialogueName: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showEditor: false,
      journalList: null
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    if (this.props.filterSet.get('journal_list_id')) {
      this.fetchEditorData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  render() {
    if (this.state.loading) {
      return <div>{I18n.t('Explorer.AdvancedSearch.fields.journal.loading')}</div>;
    }

    if (this.props.filterSet.get('journal_list_id')) return this.editorControls;

    return (
      <div>
        {this.editorButton}
        {this.editor}
      </div>
    );
  }

  get editorControls() {
    return (
      <div className="Explorer-AdvancedSearch-JournalListDescription">
        <div className="description">
          {I18n.t('Explorer.AdvancedSearch.fields.journal.editor.issn_count', {
            count: this.state.journalList.count,
            formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.journalList.count)
          })}
        </div>

        <div className="edit-buttons">
          <button type="button" className="edit" onClick={this.openEditor} ref={(ref) => (this.editButton = ref)}>
            {I18n.t('Explorer.AdvancedSearch.fields.journal.editor.edit')}
          </button>

          <button type="button" className="clear" onClick={this.clearEditor}>
            {I18n.t('Explorer.AdvancedSearch.fields.journal.editor.clear')}
          </button>
        </div>
        {this.editor}
      </div>
    );
  }

  get editorButton() {
    return (
      <div className="JournalFilter-journalList-content">
        <div className="JournalFilter-journalList-create-buttons">
          or &nbsp;
          <button
            type="button"
            className="JournalFilter-journalList-add-button"
            onClick={this.openEditor}
            ref={(ref) => (this.newButton = ref)}
          >
            {I18n.t('Explorer.AdvancedSearch.fields.journal.editor.open')}
          </button>
        </div>
      </div>
    );
  }

  get editor() {
    if (!this.state.showEditor) return;

    return (
      <Editor
        journalList={this.state.journalList}
        onCancel={this.cancelEditor}
        onSave={this.saveEditor}
        analyticsDialogueName={this.props.analyticsDialogueName}
      />
    );
  }

  openEditor = () => {
    this.setState({ showEditor: true });
  };

  saveEditor = (journalList) => {
    this.props.filterSet.set('journal_list_id', journalList.id);
    this.props.filterSet.clear('journal_id[]');

    this.setState(
      {
        journalList: journalList,
        showEditor: false
      },
      () => {
        if (this.props.onChange) this.props.onChange();
        this.refocus();
      }
    );
  };

  clearEditor = () => {
    this.props.filterSet.clear('journal_list_id');

    this.setState(
      {
        journalList: null,
        showEditor: false
      },
      () => {
        if (this.props.onChange) this.props.onChange();
        this.refocus();
      }
    );

    Analytics.trackEvent('Advanced Search: Modal Dialogue Cleared', {
      dialogue_name: this.props.analyticsDialogueName
    });
  };

  cancelEditor = () => {
    this.setState({ showEditor: false }, () => this.refocus());
  };

  refocus = () => {
    this.state.journalList ? this.editButton.focus() : this.newButton.focus();
  };

  fetchEditorData = async () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.journalList();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, journalList: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };
}

export default withRouter(JournalList);
