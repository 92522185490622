import React, { useMemo } from 'react';

const ColumnHeader = ({ column }) => {
  const sortOrder = useMemo(() => {
    if (column.isSortedDesc === true) return 'desc';
    if (column.isSortedDesc === false) return 'asc';
    return 'none';
  });

  const columnProps = { ...column.getSortByToggleProps() };
  delete columnProps.title; // prevent tooltips

  return (
    <th {...column.getHeaderProps(columnProps)}>
      <div>
        <span className={`sort-indicator sorted-${sortOrder}`}></span>
        <button>{column.render('Header')}</button>
      </div>
    </th>
  );
};

export default ColumnHeader;
