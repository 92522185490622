import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import { Tooltip as ReactTooltip } from 'react-tooltip';

import DropdownMenu from 'components/DropdownMenu';

import Query from 'components/Explorer/models/query';

class Export extends React.Component {
  // Setup
  ////////////////////////////////////////////////////////////////////////////

  query = null;

  state = {
    loading: true,
    csvUrl: null,
    csvUtf8Url: null,
    apiUrl: null
  };

  static propTypes = {
    history: PropTypes.object,
    endpoint: PropTypes.string,
    resultsCount: PropTypes.number,
    help: PropTypes.object
  };

  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters, Query.EVENTS.didChangeSortOrder);

    if (this.props.resultsCount !== 0) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Export" data-testid="export">
        {this.content}
      </div>
    );
  }

  get content() {
    if (this.loading || (!this.state.csvUrl && !this.state.csvUtf8Url && !this.state.apiUrl)) {
      return;
    }

    if (this.props.resultsCount === 0) {
      return;
    }

    return (
      <div id="CSV-Export-Dropdown">
        <DropdownMenu title={I18n.t('Explorer.Export.header')}>
          <div className="CSV-Export-Dropdown-Items-Wrapper"></div>
          <div className="CSV-Export-Dropdown-Items">
            <Row
              labelText={I18n.t('Explorer.Export.download_csv.link')}
              href={this.state.csvUrl}
              onClick={this.trackCSVEvent}
              supportUrl={this.props.help.csv}
              tooltipText={I18n.t('Explorer.Export.download_csv.tip')}
            />
            <Row
              labelText={I18n.t('Explorer.Export.download_csv_utf8.link')}
              href={this.state.csvUtf8Url}
              onClick={this.trackCSVUTF8Event}
              supportUrl={this.props.help.csv}
              tooltipText={I18n.t('Explorer.Export.download_csv_utf8.tip')}
            />
            <Row
              labelText={I18n.t('Explorer.Export.download_json.link')}
              href={this.state.apiUrl}
              onClick={this.trackAPIEvent}
              supportUrl={this.props.help.api}
              tooltipText={I18n.t('Explorer.Export.download_json.tip')}
            />
          </div>
        </DropdownMenu>
      </div>
    );
  }

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.exportUrls(this.props.endpoint);
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({
          loading: false,
          csvUrl: response.body.csv,
          csvUtf8Url: response.body.csv_utf8,
          apiUrl: response.body.api
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  trackCSVEvent = () => {
    Analytics.trackEvent('export-csv', { endpoint: this.props.endpoint });
  };

  trackCSVUTF8Event = () => {
    Analytics.trackEvent('export-csv-utf8', { endpoint: this.props.endpoint });
  };

  trackAPIEvent = () => {
    Analytics.trackEvent('export-api', { endpoint: this.props.endpoint });
  };
}

// Private component that encapsulates a row in the dropdown
const Row = ({ labelText, href, onClick, supportUrl, tooltipText }) => {
  if (href === null) {
    return null;
  }

  return (
    <div className="row">
      <a
        className="info"
        href={supportUrl}
        target="_blank"
        rel="noreferrer"
        data-tooltip-id="Export-tooltip"
        data-tooltip-content={tooltipText}
        data-event="focus mouseenter"
        data-event-off="blur mouseleave"
      >
        &#xe911;
      </a>
      <a onClick={onClick} className="action" target="_blank" rel="noreferrer" href={href}>
        {labelText}
      </a>
      <ReactTooltip id="Export-tooltip" effect="solid" place="left" />
    </div>
  );
};

export default withRouter(Export);
