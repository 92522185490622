import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash-es';

import ResearchOutput from 'components/Explorer/models/research_output';
import SegmentList from './segment_list';

const SEGMENT_COUNT = 8;

class BarVisualisation extends React.Component {
  static propTypes = {
    output: PropTypes.instanceOf(ResearchOutput).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      segmentList: new SegmentList(props.output.counts, SEGMENT_COUNT)
    };
  }

  render() {
    return (
      <div className="badge-with-verified-checkbox">
        {this.verifiedTick}
        <div className="Explorer-BarVisualisation">
          <svg viewBox="0 0 100 20">{this.segments}</svg>
        </div>
      </div>
    );
  }

  get segments() {
    const segmentWidth = 100 / SEGMENT_COUNT;
    let index = 0;

    return map(this.state.segmentList.segments, (segment) => {
      const xPosition = index * segmentWidth;
      const width = segment.width * segmentWidth;

      index += segment.width;

      return (
        <g transform={`translate(${xPosition}, 0)`} key={segment.type} data-testid="bar-visualisation-segment">
          <rect width={width} height="20" fill={segment.lightColor} />
          <rect width={width} height="4" y="16" fill={segment.darkColor} />
        </g>
      );
    });
  }

  get verifiedTick() {
    if (!this.props.output.verified) return;

    return (
      <div className="verified-badge">
        <div
          className="icon"
          title={I18n.t('Explorer.Outputs.ResearchOutputItem.verified', {
            name: window.current_user.institution_name
          })}
        />
      </div>
    );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      segmentList: new SegmentList(newProps.output.counts, SEGMENT_COUNT)
    });
  }
}

export default BarVisualisation;
