import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import FilterSet from 'components/Explorer/models/filter_set';
import Query from 'components/Explorer/models/query';

import AutocompleteField from '../../AutocompleteField';
import HelpLink from '../HelpLink';

class FieldOfResearchFilter extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    initialSearch: PropTypes.string,
    history: PropTypes.object
  };

  query = null;
  ANALYTICS_FIELD_NAME = 'Field Of Research';

  state = {
    loading: true,
    codes: []
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    if (this.codes.length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  componentDidUpdate = () => {
    if (!this.fieldRef) return;
    if (!this.codes.length) this.fieldRef.clearAll();
  };

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field FieldOfResearchFilter">
        {this.label}
        {this.state.loading ? this.loading : this.field}
        {this.unknownCodes() && !this.state.loading ? this.forWarning : null}
      </div>
    );
  }

  unknownCodes = () => {
    return this.codes.length > this.state.codes.length;
  };

  get forWarning() {
    return (
      <div className="unknown-for-warning">
        <h6>{I18n.t('Explorer.AdvancedSearch.fields.field_of_research.unknown_for_warning.title')}</h6>
        <p>{I18n.t('Explorer.AdvancedSearch.fields.field_of_research.unknown_for_warning.description')}</p>
        <a
          href="https://help.altmetric.com/support/solutions/articles/6000262102-update-to-the-fields-of-research-for-classification"
          target="_blank"
          rel="noreferrer"
        >
          {I18n.t('Explorer.AdvancedSearch.fields.field_of_research.unknown_for_warning.link_text')}
        </a>
      </div>
    );
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.field_of_research.name')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.field_of_research.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get loadingMessage() {
    return <div>{I18n.t('Explorer.AdvancedSearch.fields.field_of_research.loading')}</div>;
  }

  get field() {
    return (
      <AutocompleteField
        name="field_of_research"
        id="field_of_research"
        type="field_of_research_codes"
        initialValue={this.props.initialSearch}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.field_of_research.placeholder')}
        onChangeItems={this.updateSelectedCodes}
        initiallySelected={this.state.codes}
        ref={this.getFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  updateSelectedCodes = (codes) => {
    this.props.filterSet.set(
      'field_of_research_codes[]',
      codes.map((code) => code.id)
    );
  };

  get codes() {
    return this.props.filterSet.get('field_of_research_codes[]').map((code) => ({ id: code }));
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.fieldsOfResearch();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;
      if (!err) {
        this.setState({ loading: false, codes: response.body });
      } else {
        this.setState({ loading: false, codes: [] });
      }
    });
  };
}

export default withRouter(FieldOfResearchFilter);
