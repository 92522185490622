const setup = function () {
  window.addEventListener('load', () => {
    document.querySelector('.main-sidebar .sign-in')?.addEventListener('click', () => {
      Analytics.trackEvent('Sidebar: Sign In Clicked');
    });

    const modulesButton = document.getElementById('ModulesMenu-button');
    if (!modulesButton) return;

    const popover = document.getElementById(modulesButton.getAttribute('popovertarget'));

    const toggleExpanded = () => {
      const isOpen = popover.classList.toggle('is-open');
      modulesButton.setAttribute('aria-expanded', isOpen.toString());
    };

    popover?.addEventListener('toggle', () => toggleExpanded());

    modulesButton?.addEventListener('click', (event) => {
      const button = event.currentTarget;
      const { top, right } = button.getBoundingClientRect();
      const supportsPopoverAPI = 'showPopover' in popover;

      if (!supportsPopoverAPI) {
        toggleExpanded();
      }

      Object.assign(popover.style, {
        top: `${top}px`,
        left: `${right}px`
      });
    });
  });
};

export { setup };
