import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import Gauge from 'components/Explorer/Gauge';
import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';

class TopAffiliations extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static COLORS = ['#F418FF', '#B712BF', '#7A0C7F'];

  state = {
    loading: true,
    loadingFailed: false,
    data: {}
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-TopAffiliations Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.TopAffiliations.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition classNames="Content" timeout={250}>
            {this.state.loading ? this.loadingMessage : this.content}
          </Transition>
          {this.jumpLink}
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div key="loadingMessage" className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div key="content" className="Highlights-content Highlights-TopAffiliations-content">
        {this.hasResults ? this.contentTable : this.emptyMessage}
      </div>
    );
  }

  get errorMessage() {
    return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get contentTable() {
    return (
      <table className="content-table">
        <tbody>{this.state.data.map((affiliation, index) => this.affiliationRank(affiliation, index))}</tbody>
      </table>
    );
  }

  get emptyMessage() {
    return (
      <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.TopAffiliations.empty')}</div>
    );
  }

  get jumpLink() {
    if (!this.hasResults) return;

    return (
      <Link
        onClick={this.trackJumpLink}
        to={this.query.locationWithPathname('/mentions')}
        className="Highlights-jump-link"
      >
        {I18n.t('Explorer.Highlights.TopAffiliations.more')}
      </Link>
    );
  }

  affiliationRank(affiliation, index) {
    return (
      <tr key={affiliation.id}>
        <td className="percent">
          <Gauge percentage={affiliation.ratio} color={TopAffiliations.COLORS[index % TopAffiliations.COLORS.length]} />
          <span className="number">
            {NumberHelper.formatPercentage(affiliation.ratio)}
            <sup>%</sup>
          </span>
        </td>
        <td className="description">
          <div className="title">
            <Link to={this.linkForAffiliation(affiliation)}>{affiliation.name}</Link>
          </div>
          <div className="meta">
            {I18n.htmlSafe('Explorer.Highlights.TopAffiliations.meta.outputs', {
              count: affiliation.outputs,
              formattedCount: NumberHelper.formatStatistic(affiliation.outputs)
            })}
            <span>&nbsp;</span>
            {I18n.htmlSafe('Explorer.Highlights.TopAffiliations.meta.mentions', {
              count: affiliation.mentions,
              formattedCount: NumberHelper.formatStatistic(affiliation.mentions)
            })}
          </div>
        </td>
      </tr>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsTopAffiliations();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body;
      }

      this.setState({ loading: false, loadingFailed, data });
    });
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  linkForAffiliation(affiliation) {
    const filters = this.query.cloneParams();
    filters['affiliations[]'] = [affiliation.id];

    return this.query.withFilters(filters).locationWithPathname('/mentions');
  }

  get hasResults() {
    return this.state.data && this.state.data.length > 0;
  }

  trackJumpLink = () => {
    Analytics.trackEvent('highlights-click-jump-link', {
      jumpedTo: 'top-affiliations'
    });
  };
}

export default withRouter(TopAffiliations);
