import React from 'react';
import PropTypes from 'prop-types';

import fetch from 'util/fetch';

import NumberHelper from 'util/number_helper';
import { attachFocusLimiter } from 'components/Explorer/util/focus_limiter';

class Editor extends React.Component {
  static propTypes = {
    pubmedSearch: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    analyticsDialogueName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.trackLinkClick = this.trackLinkClick.bind(this);

    this.state = {
      saving: false,
      content: ''
    };

    this.textEntered = false;
  }

  componentDidMount() {
    this.trackEvent('Opened', this.props.pubmedSearch ? 'Edit' : 'Create');

    if (this.props.pubmedSearch) {
      this.setState(
        {
          content: this.props.pubmedSearch.content
        },
        this.updateTokens
      );
    }

    attachFocusLimiter(this.containerElement);
  }

  render() {
    return (
      <div className="PubmedFilter-editor" ref={(ref) => (this.containerElement = ref)}>
        <div className="PubmedFilter-editor-content">
          {this.title}
          {this.content}
          {this.footer}
          {this.state.saving ? this.savingIndicator : null}
        </div>
      </div>
    );
  }

  get savingIndicator() {
    return <div className="PubmedFilter-editor-saving-message" />;
  }

  get title() {
    return (
      <div className="PubmedFilter-editor-title">{I18n.t('Explorer.AdvancedSearch.fields.pubmed.editor.header')}</div>
    );
  }

  get content() {
    return (
      <div className="PubmedFilter-editor-inner">
        <div className="PubmedFilter-editor-note">
          {I18n.htmlSafe('Explorer.AdvancedSearch.fields.pubmed.editor.notes', {
            max: NumberHelper.formatNumberWithDelimiter(this.maxResults)
          })}
          &nbsp;
          <a
            onClick={this.trackLinkClick}
            href="https://help.altmetric.com/support/solutions/articles/6000240861-advanced-search-search-by-pubmed-query"
            data-link-id="how-to"
            target="_blank"
            rel="noreferrer"
          >
            {I18n.t('Explorer.AdvancedSearch.fields.pubmed.editor.how_to_link_text')}
          </a>
        </div>
        <div className="PubmedFilter-editor-form">
          <textarea
            value={this.state.content}
            placeholder={I18n.t('Explorer.AdvancedSearch.fields.pubmed.editor.placeholder')}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            ref={this.fieldRef}
          />
        </div>
      </div>
    );
  }

  get footer() {
    return (
      <div className="PubmedFilter-editor-footer">
        <div className="PubmedFilter-editor-meta">
          {this.canSave
            ? I18n.t('Explorer.AdvancedSearch.fields.pubmed.editor.meta')
            : I18n.t('Explorer.AdvancedSearch.fields.pubmed.editor.empty')}
        </div>
        <div className="PubmedFilter-editor-buttons">
          <button type="button" className="cancel" onClick={this.onCancel}>
            {I18n.t('Explorer.AdvancedSearch.fields.pubmed.editor.cancel')}
          </button>
          <button
            type="button"
            disabled={!this.canSave}
            className={`submit ${this.canSave ? 'enabled' : 'disabled'}`}
            onClick={this.onSave}
          >
            {I18n.t('Explorer.AdvancedSearch.fields.pubmed.editor.save')}
          </button>
        </div>
      </div>
    );
  }

  get canSave() {
    return this.state.content.length > 0;
  }

  get maxResults() {
    return window.current_user.maxPubmedResults;
  }

  fieldRef = (f) => {
    this.field = f;

    if (f) f.focus();
  };

  onChange = (e) => {
    if (!this.textEntered && e.target.value.length > this.state.content.length) {
      this.textEntered = true;
      this.trackEvent('Filled');
    }

    this.setState(
      {
        content: e.target.value
      },
      this.updateTokens
    );
  };

  onKeyDown = (e) => {
    if (e.key === 'Escape') this.onCancel();
  };

  onFocus = () => {
    if (this.field) this.field.select();
  };

  onSave = async () => {
    this.setState({ saving: true });

    const body = {
      pubmed_search: {
        query: this.state.content
      }
    };

    try {
      const response = await fetch('/explorer/pubmed_searches', {
        method: 'post',
        body: JSON.stringify(body)
      });

      const pubmedSearch = await response.json();
      this.trackEvent('Data Updated', null, pubmedSearch.content);
      this.props.onSave(pubmedSearch);
    } catch (_error) {
      window.alert(I18n.t('Explorer.AdvancedSearch.fields.pubmed.editor.error'));
      this.setState({ saving: false });
    }
  };

  onCancel = () => {
    this.trackEvent('Closed');
    this.props.onCancel();
  };

  trackLinkClick = (e) => {
    this.trackEvent('Link Clicked', null, null, e.target.dataset.linkId);
  };

  trackEvent(eventName, action = null, query = null, linkId = null) {
    Analytics.trackEvent(`Advanced Search: Modal Dialogue ${eventName}`, {
      dialogue_name: this.props.analyticsDialogueName,
      ...(action !== null && { action: action }),
      ...(query !== null && { pubmed_query: query }),
      ...(linkId !== null && { link: linkId })
    });
  }
}

export default Editor;
