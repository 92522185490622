import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';
import AutocompleteField from '../AutocompleteField';
import HelpLink from './HelpLink';

class PublisherFilter extends React.Component {
  query = null;
  ANALYTICS_FIELD_NAME = 'Publisher Name';

  state = {
    loading: true,
    publishers: []
  };

  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    initialSearch: PropTypes.string
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    if (this.publisherIDs.length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field PublisherFilter">
        {this.label}
        {this.state.loading ? this.loadingMessage : this.field}
      </div>
    );
  }

  get loadingMessage() {
    return <div>{I18n.t('Explorer.AdvancedSearch.fields.publishers.loading')}</div>;
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.publishers.name')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.publishers.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get field() {
    return (
      <AutocompleteField
        name="publisher_id"
        id="filter_publisher_id"
        type="publishers"
        initialValue={this.props.initialSearch}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.publishers.placeholder')}
        onChangeItems={this.updateSelectedPublishers}
        initiallySelected={this.state.publishers}
        ref={this.getFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  updateSelectedPublishers = (publishers) => {
    this.props.filterSet.set(
      'publisher_id[]',
      publishers.map((publisher) => publisher.id)
    );
  };

  get publisherIDs() {
    return this.props.filterSet.get('publisher_id[]');
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };

  componentDidUpdate = () => {
    if (!this.fieldRef) return;
    if (!this.publisherIDs.length) this.fieldRef.clearAll();
  };

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.publisherNames();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, publishers: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };
}

export default withRouter(PublisherFilter);
