import React from 'react';

import NumberHelper from '../../util/number_helper';

class VerifyHierarchyPanel extends React.Component {
  render() {
    return (
      <div className="DatasetUploadPopover-VerifyHierarchyPanel DatasetUploadPopover-ContentPanel">
        <div className="DatasetUploadPopover-documentation-panel">
          <div className="header-icon">
            <h2>{I18n.t('dataset_upload.verify_hierarchy.title')}</h2>
            <a
              className="help"
              href={I18n.t('dataset_upload.verify_hierarchy.help_uri')}
              title={I18n.t('dataset_upload.help_title')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Help
            </a>
          </div>
        </div>
        <div className="preview-panel">{this.renderHierarchy()}</div>
      </div>
    );
  }

  renderHierarchy() {
    return this.props.hierarchy.valid ? this.renderValidHierarchy() : this.renderInvalidHierarchy();
  }

  renderValidHierarchy() {
    return (
      <div>
        <div className="panel-abstract good">
          <p>
            {I18n.htmlSafe('dataset_upload.verify_hierarchy.abstract_total_html', {
              count: this.props.hierarchy.totalCount,
              formattedCount: NumberHelper.formatNumberWithDelimiter(this.props.hierarchy.totalCount)
            })}
            {I18n.htmlSafe('dataset_upload.verify_hierarchy.abstract_extracted_html', {
              count: this.props.hierarchy.extractedCount,
              formattedCount: NumberHelper.formatNumberWithDelimiter(this.props.hierarchy.extractedCount)
            })}
          </p>
          <p>{I18n.htmlSafe('dataset_upload.verify_hierarchy.abstract_next_html')}</p>
        </div>
        {this.renderCTA()}
        {this.renderHierarchyTree()}
      </div>
    );
  }

  renderInvalidHierarchy() {
    return (
      <div>
        <div className="panel-abstract error">{this.renderHierarchyError()}</div>
        {this.renderCTA()}
        {this.renderHierarchyTree()}
      </div>
    );
  }

  renderCTA() {
    return (
      <div className="DatasetUploadPopover-hierarchy-cta-panel">
        {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
        <a className="return-to-upload" onClick={this.props.onReturn}>
          {I18n.t('dataset_upload.verify_hierarchy.return_to_upload')}
        </a>
        {this.props.hierarchy.valid && (
          // eslint-disable-next-line -- jsx-a11y [TODO]
          <a className="continue-to-outputs" onClick={this.props.onAdvance}>
            {I18n.t('dataset_upload.verify_hierarchy.continue_to_outputs')}
          </a>
        )}
      </div>
    );
  }

  renderHierarchyError() {
    if (!this.props.hierarchy.hasValidHeader) {
      return I18n.htmlSafe('dataset_upload.verify_hierarchy.abstract_header_error_html');
    } else if (this.props.hierarchy.hasErroredNodes) {
      return I18n.htmlSafe('dataset_upload.verify_hierarchy.abstract_error_html');
    }
  }

  renderHierarchyTree() {
    if (!this.props.hierarchy.hasValidHeader) return;

    return (
      <div className="hierarchy-tree">
        <ol>
          {this.props.hierarchy.root.children.map((node, i) => {
            return <Node key={i} node={node} />;
          })}
        </ol>
      </div>
    );
  }

  renderEmptyMessage() {
    return (
      <div>
        <div className="panel-abstract">{I18n.htmlSafe('dataset_upload.verify_hierarchy.empty_html')}</div>
      </div>
    );
  }
}

class Node extends React.Component {
  render() {
    return (
      <li>
        <div className={this.getClassName()}>
          <div className="title">
            {this.props.node.name}
            {this.renderErrors()}
          </div>
        </div>
        {this.renderChildren()}
      </li>
    );
  }

  renderChildren() {
    if (!this.hasChildren()) return;

    return (
      <ol>
        {this.props.node.children.map((node, i) => {
          return <Node key={i} node={node} />;
        })}
      </ol>
    );
  }

  renderErrors() {
    if (this.props.node.valid) return;

    return (
      <div className="errors">
        {this.props.node.errors.map((error, index) => {
          return (
            <span key={index} className="error">
              {error}
            </span>
          );
        })}
      </div>
    );
  }

  hasChildren = () => this.props.node.children.length > 0;

  getClassName() {
    const classes = ['department'];

    if (this.hasChildren()) {
      classes.push('branch');
    } else {
      classes.push('leaf');
    }

    if (!this.props.node.valid) {
      classes.push('error');
    }

    return classes.join(' ');
  }
}

export { VerifyHierarchyPanel };
