import React from 'react';
import PropTypes from 'prop-types';

class InstitutionalToggle extends React.Component {
  static propTypes = {
    scope: PropTypes.string,
    changeScope: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="Explorer-InstitutionalToggle">
        <div className="toggle-buttons">
          <button
            type="button"
            onClick={this.setScopeAll}
            className={`full ${this.props.scope == 'all' ? 'active' : 'not-active'}`}
          >
            {I18n.t('Explorer.AdvancedSearch.institution_toggle.full')}
          </button>

          <button
            type="button"
            onClick={this.setScopeInstitution}
            className={`institution ${this.props.scope == 'institution' ? 'active' : 'not-active'}`}
          >
            {I18n.t('Explorer.AdvancedSearch.institution_toggle.institution')}
          </button>
        </div>

        <p className="Explorer-InstitutionalToggle-message">
          {I18n.t('Explorer.AdvancedSearch.institution_toggle.message')}
          <a href="/explorer/settings"> {I18n.t('Explorer.AdvancedSearch.institution_toggle.account_settings')}</a>
        </p>
      </div>
    );
  }

  setScopeAll = (e) => {
    e.preventDefault();
    this.props.changeScope('all');
  };

  setScopeInstitution = (e) => {
    e.preventDefault();
    this.props.changeScope('institution');
  };
}

export default InstitutionalToggle;
