class SearchTerm {
  static TYPES = {
    authors: 'authors',
    departments: 'departments',
    journals: 'journals',
    publishers: 'publishers',
    doi_prefixes: 'doi_prefixes',
    affiliations: 'affiliations',
    fields_of_research: 'fields_of_research',
    funders: 'funders',
    sustainable_development_goals: 'sustainable_development_goals'
  };

  static empty() {
    return new SearchTerm(null, null);
  }

  constructor(type, term) {
    this.type = type;
    this.term = term;
  }

  termFor(type) {
    if (this.type == type) return this.term;
  }

  termForDoiPrefix() {
    return this.termFor(SearchTerm.TYPES.doi_prefixes);
  }

  termForJournal() {
    return this.termFor(SearchTerm.TYPES.journals);
  }

  termForAuthor() {
    return this.termFor(SearchTerm.TYPES.authors);
  }

  termForDepartment() {
    return this.termFor(SearchTerm.TYPES.departments);
  }

  termForPublisher() {
    return this.termFor(SearchTerm.TYPES.publishers);
  }

  termForAffiliation() {
    return this.termFor(SearchTerm.TYPES.affiliations);
  }

  termForFieldOfResearch() {
    return this.termFor(SearchTerm.TYPES.fields_of_research);
  }

  termForFunder() {
    return this.termFor(SearchTerm.TYPES.funders);
  }

  termForSustainableDevelopmentGoals() {
    return this.termFor(SearchTerm.TYPES.sustainable_development_goals);
  }
}

export default SearchTerm;
