import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Transition = ({ children, ...props }) => {
  return (
    <TransitionGroup appear={true}>
      {children && (
        <CSSTransition key={children.key} appear={true} {...props}>
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};
export default Transition;
