import React from 'react';

import NumberHelper from 'util/number_helper';

const RowHeader = ({ attributes, value, mentioned_research_outputs, onClick }) => {
  const rowDescription =
    mentioned_research_outputs === 1
      ? I18n.htmlSafe('Explorer.Journals.description.one')
      : I18n.htmlSafe('Explorer.Journals.description.other', {
          formattedCount: NumberHelper.formatNumberWithDelimiter(mentioned_research_outputs)
        });

  return (
    <th scope="row" {...attributes}>
      <button onClick={onClick}>{value}</button>
      <p>{rowDescription}</p>
    </th>
  );
};

export default RowHeader;
