import Papa from 'papaparse';
import { delay, filter } from 'lodash-es';

import Ajax from '../../util/ajax';

import Identifiers from '../../util/identifiers';

import { ResearchOutputsBuilder } from './research_outputs';
import { ColumnBuilder } from './columns';

const POLL_DELAY = 5000;

const IDENTIFIER_FIELD_TYPES = Identifiers.TYPES;

const INSTITUTIONAL_FIELD_TYPES = ['authors', 'departments'];

// The Dataset class is the general wrapper for a data set supplied by the user.
class Dataset {
  constructor(file, departments) {
    this.file = file;
    this.departments = departments;
    this.results = [];
    this.columns = [];
    this.onLoad = null;
  }

  load() {
    Papa.parse(this.file, {
      header: true,
      skipEmptyLines: true,
      chunk: (results) => {
        this.results = this.results.concat(results.data);
      },
      complete: () => {
        this.columns = new ColumnBuilder(this.results).columns;
        if (this.onLoad) {
          this.onLoad();
        }
      }
    });
  }

  buildResearchOutputs() {
    // Before building research outputs, we need to re-normalize the columns –
    // because the column types may have been changed by the user since they
    // were last normalized.
    this.columns.forEach((c) => c.normalizeContent());

    let builder = new ResearchOutputsBuilder(this.columns);
    this.researchOutputs = builder.researchOutputs;
    this.affiliations = builder.affiliationMap;
  }

  get totalRows() {
    return this.results.length;
  }

  get totalResearchOutputs() {
    return this.researchOutputs.length;
  }

  get totalValidResearchOutputs() {
    return this.validResearchOutputs.length;
  }

  get totalInvalidResearchOutputs() {
    return this.totalResearchOutputs - this.totalValidResearchOutputs;
  }

  get valid() {
    return this.totalValidResearchOutputs > 0;
  }

  get perfect() {
    return this.totalInvalidResearchOutputs === 0;
  }

  get validResearchOutputs() {
    return filter(this.researchOutputs, 'valid');
  }

  save(opts) {
    let outputsJSON = JSON.stringify(this.validResearchOutputs.map((r) => r.asJSON()));
    let hierarchyJSON = JSON.stringify(opts.hierarchy ? opts.hierarchy.toJSON() : {});
    let affiliationJSON = JSON.stringify(this.affiliations);

    let data = {
      outputs: outputsJSON,
      hierarchy: hierarchyJSON,
      affiliation: affiliationJSON,
      totalCount: this.totalResearchOutputs
    };

    Ajax.saveCSVUpload(data)
      .done((data) => {
        this.jobID = data.job_id;
        if (opts.onSubmitted) opts.onSubmitted();
        this.deferProgressCheck(opts.onComplete, opts.onError, opts.onProgress);
      })
      .fail(opts.onError);
  }

  deferProgressCheck(onComplete, onError, onProgress) {
    delay(() => {
      this.progressCheck(onComplete, onError, onProgress);
    }, POLL_DELAY);
  }

  progressCheck(onComplete, onError, onProgress) {
    Ajax.checkPublishingProgress(this.jobID)
      .done((_data, _status, xhr) => {
        if (xhr.status === 202) {
          if (onProgress) {
            onProgress();
          }
          this.deferProgressCheck(onComplete, onError, onProgress);
        } else {
          if (onComplete) {
            onComplete(xhr.status);
          }
        }
      })
      .fail(onError);
  }

  get validationReport() {
    let rows = this.researchOutputs.map((output) => {
      let record = {};

      IDENTIFIER_FIELD_TYPES.forEach((type) => {
        record[type] = output.data[type];
      });

      INSTITUTIONAL_FIELD_TYPES.forEach((type) => {
        record[type] = (output.data[type] || []).join('; ');
      });

      record.row_sources = output.rowIndexes.map((r) => r + 2).join('; ');
      record.valid = output.valid ? 'Valid' : 'Invalid';
      record.errors = output.errors.join(' ');

      return record;
    });

    return Papa.unparse(rows);
  }
}

export { Dataset, IDENTIFIER_FIELD_TYPES, INSTITUTIONAL_FIELD_TYPES };
