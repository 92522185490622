import './styles';

import { cloneDeep, each } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import config from 'util/site_config.js.erb';
import NumberHelper from 'util/number_helper';
import MentionSourceProfileIcon from 'components/Explorer/ContentPanel/MentionSourceProfileIcon';
import RecentMentions from './RecentMentions';
import Query from 'components/Explorer/models/query';

class MentionSource extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  query = null;

  static propTypes = {
    history: PropTypes.object,
    mentionSource: PropTypes.object.isRequired
  };

  state = {
    recentMentionsVisible: false
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <article key={this.props.mentionSource.id} className="Explorer-MentionSources-MentionSource">
        <div className="row">
          <div className="title">
            <MentionSourceProfileIcon profileType={this.props.mentionSource.profileType} />
            <h3>{this.mentionSourceUrlOrName()}</h3>
            <p>
              {I18n.t(
                `Explorer.Highlights.TopMentionSources.mention_source_types.${this.props.mentionSource.profileType}`
              )}
              {this.country()}
            </p>
          </div>
          <div className="counts">
            <h3>{NumberHelper.formatNumberWithDelimiter(this.props.mentionSource.mentions)}</h3>
            {I18n.t('Explorer.MentionSources.mentions_count', {
              count: this.props.mentionSource.mentions
            })}
          </div>
          <div className="counts">{this.followers()}</div>
          <aside>
            <Link className="mentions-link-button" to={this.linkForMentionSource()}>
              {I18n.t('Explorer.MentionSources.mentions_button', {
                count: this.props.mentionSource.mentions
              })}
            </Link>
            {this.showRecentMentionsButton()}
          </aside>
        </div>
        <RecentMentions
          visible={this.state.recentMentionsVisible}
          key={this.props.mentionSource.id}
          mentionSource={this.props.mentionSource}
          mentionsLink={this.linkForMentionSource()}
          filtersForMentionSource={this.filtersForMentionSource()}
        />
      </article>
    );
  }

  mentionSourceUrlOrName() {
    if (this.props.mentionSource.url) {
      return (
        <a href={this.props.mentionSource.url} target="_blank" rel="noreferrer" data-testid="url">
          {this.props.mentionSource.name}
        </a>
      );
    } else {
      return this.props.mentionSource.name;
    }
  }

  country() {
    if (!this.props.mentionSource.country) {
      return;
    }
    return (
      <span className="country" data-testid="country">
        {this.props.mentionSource.country}
      </span>
    );
  }

  followers() {
    if (this.props.mentionSource.profileType !== 'tw') {
      return;
    }
    return (
      <div className="followers" data-testid="followers">
        <h3>{NumberHelper.formatStatistic(this.props.mentionSource.followers)}</h3>
        {I18n.t('Explorer.MentionSources.followers', {
          count: this.props.mentionSource.followers
        })}
      </div>
    );
  }

  filtersForMentionSource() {
    let filters = cloneDeep(this.query.filters);
    // Remove any filter being applied to mentions, otherwise we might get different results
    each(config.mentionsFilterParams, (key) => {
      delete filters[key];
      // filters that accept arrays end in []. Since we don't know which ones are, just remove everything
      delete filters[key + '[]'];
    });
    // We want to apply the time filters when displaying mentions of an author. For this reason the filters
    // are being copied to the corresponding mentions tab filters.
    if (filters.mention_sources_mentioned_after) filters.mentioned_after = filters.mention_sources_mentioned_after;
    if (filters.mention_sources_mentioned_before) filters.mentioned_before = filters.mention_sources_mentioned_before;
    filters['mention_sources[]'] = this.props.mentionSource.id;

    return filters;
  }

  linkForMentionSource() {
    return this.query.withFilters(this.filtersForMentionSource()).locationWithPathname('/mentions');
  }

  showRecentMentionsButton() {
    let text, className;
    if (this.state.recentMentionsVisible) {
      text = I18n.t('Explorer.MentionSources.MentionSource.hide_recent_mentions');
      className = 'recent-mentions-button hide';
    } else {
      text = I18n.t('Explorer.MentionSources.MentionSource.show_recent_mentions');
      className = 'recent-mentions-button show';
    }

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events -- [TODO]
      <a onClick={this.toggleMentionsVisibility} className={className} role="button" tabIndex="0">
        {text}
      </a>
    );
  }

  toggleMentionsVisibility = () => {
    this.setState({ recentMentionsVisible: !this.state.recentMentionsVisible });
  };
}

export default withRouter(MentionSource);
