import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';
import { truncate } from 'lodash-es';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import Query from 'components/Explorer/models/query';
import ResearchOutput from 'components/Explorer/models/research_output';
import BarVisualisation from 'components/Explorer/BarVisualisation';
import NumberHelper from 'util/number_helper';

class TopOutputs extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  state = {
    loading: true,
    loadingFailed: false,
    data: []
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-TopOutputs Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.TopOutputs.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition classNames="Content" timeout={250}>
            {this.state.loading ? this.loadingMessage : this.content}
          </Transition>
          {this.hasResults && (
            <Link
              onClick={this.trackJumpLink}
              to={this.query.locationWithPathname('/outputs')}
              className="Highlights-jump-link"
            >
              {I18n.t('Explorer.Highlights.TopOutputs.more')}
            </Link>
          )}
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) {
      return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
    }

    if (!this.hasResults) {
      return <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.TopOutputs.empty')}</div>;
    }

    return <div className="Highlights-content Highlights-TopOutputs-content">{this.contentTable}</div>;
  }

  get contentTable() {
    return (
      <table className="content-table">
        <tbody>{this.state.data.map((output) => this.row(output))}</tbody>
      </table>
    );
  }

  row(output) {
    return (
      <tr key={output.id}>
        <td className="badge-image">
          <BarVisualisation output={output} />
        </td>
        <td className="score">
          <span className="number">{NumberHelper.formatNumberWithDelimiter(output.score)}</span>
        </td>
        <td className="output-data">
          <button onClick={() => this.openDetailsPage(output)}>
            <span className="title">{truncate(output.title, { length: 80, separator: /\W/ })}</span>
            <span className="meta">{output.meta}</span>
          </button>
        </td>
      </tr>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsTopOutputs();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body.map((r) => new ResearchOutput(r));
      }

      this.setState({ loading: false, loadingFailed, data });
    });
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  openDetailsPage = (output) => {
    Analytics.trackEvent('highlights-open-details-page', {
      outputId: output.id
    });
    this.props.history.push(this.query.withShowDetails(output.id).location);
  };

  get hasResults() {
    return this.state.data && this.state.data.length > 0;
  }

  trackJumpLink = () => {
    Analytics.trackEvent('highlights-click-jump-link', {
      jumpedTo: 'top-outputs'
    });
  };
}

export default withRouter(TopOutputs);
