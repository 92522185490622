import React from 'react';
import NumberHelper from '../util/number_helper';

export default class DepartmentHierarchy extends React.PureComponent {
  render() {
    return (
      <div className="department-hierarchy-wrapper">
        <ol>
          {this.props.roots.map((root) => {
            return <DepartmentHierarchyNode isRoot={true} key={root.id} node={root} baseURL={this.props.baseURL} />;
          })}
        </ol>
      </div>
    );
  }
}

class DepartmentHierarchyNode extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      jumpURL: props.baseURL + '?department_id=' + props.node.id
    };
  }

  render() {
    return (
      <li>
        <div className={this.className}>
          {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
          <div className="title" onClick={this.hasChildren ? this.toggleExpand : undefined}>
            <span className="name">{this.props.node.name}</span>
            <span className="count">
              {I18n.htmlSafe('my_institution.mentioned_count_html', {
                count: NumberHelper.formatNumberWithDelimiter(this.props.node.mentioned_count)
              })}
            </span>
          </div>
          <a className="jump-link" href={this.state.jumpURL}>
            <span className="cta">{I18n.t('my_institution.cta')}</span>
          </a>
        </div>
        {this.renderChildren}
      </li>
    );
  }

  get renderChildren() {
    if (!this.hasChildren) return;

    return (
      <ol>
        {this.props.node.children.map((node) => (
          <DepartmentHierarchyNode key={node.id} node={node} baseURL={this.props.baseURL} />
        ))}
      </ol>
    );
  }

  toggleExpand = () => {
    Analytics.trackEvent('toggleDepartment', {
      departmentID: this.props.node.id
    });

    this.setState({
      expanded: !this.state.expanded
    });
  };

  get hasChildren() {
    return this.props.node.children.length > 0;
  }

  get className() {
    const classes = ['department'];

    if (this.props.isRoot) classes.push('root');
    if (this.state.expanded) classes.push('expanded');

    classes.push(this.hasChildren ? 'branch' : 'leaf');

    return classes.join(' ');
  }
}
