import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';

import AutocompleteField from '../AutocompleteField';
import HelpLink from './HelpLink';

class DoiPrefixFilter extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    initialSearch: PropTypes.string
  };

  ANALYTICS_FIELD_NAME = 'DOI Prefix';

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field DoiPrefixFilter">
        {this.label}
        {this.field}
      </div>
    );
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.doi_prefix.name')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.doi_prefix.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get field() {
    return (
      <AutocompleteField
        name="doi_prefix"
        id="doi_prefix"
        type="doi_prefixes"
        initialValue={this.props.initialSearch}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.doi_prefix.placeholder')}
        onChangeItems={this.updateSelectedPrefixes}
        initiallySelected={this.prefixes}
        ref={this.getFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  updateSelectedPrefixes = (prefixes) => {
    this.props.filterSet.set(
      'doi_prefix[]',
      prefixes.map((prefix) => prefix.id)
    );
  };

  get prefixes() {
    return this.props.filterSet.get('doi_prefix[]').map((prefix) => ({ id: prefix }));
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };

  componentDidUpdate = () => {
    if (!this.fieldRef) return;
    if (!this.prefixes.length) this.fieldRef.clearAll();
  };
}

export default DoiPrefixFilter;
