import { useMemo, useState, useEffect } from 'react';

import DataProvider from '../DataProvider';

const useJournalAnalysis = (query, { onFetchStarted, onFetchSuccess, onFetchError }) => {
  const EMPTY_DATASET = { dataset: [] };
  const [dataProvider, setDataProvider] = useState(EMPTY_DATASET);

  const clear = () => setDataProvider(EMPTY_DATASET);

  const refresh = useMemo(() => {
    return () => {
      clear();
      if (typeof onFetchStarted == 'function') onFetchStarted();
      query
        .journalAnalysis()
        .then((response) => {
          setDataProvider(new DataProvider(response.body));
          return response;
        })
        .then((response) => {
          if (typeof onFetchSuccess == 'function') onFetchSuccess(response);
        })
        .catch((error) => {
          if (typeof onFetchError == 'function') onFetchError(error);
        });
    };
  }, [query, setDataProvider, onFetchStarted, onFetchSuccess, onFetchError]);

  useEffect(refresh, [query]);

  return { rows: dataProvider.dataset, clear, refresh };
};

export { useJournalAnalysis };
