import React from 'react';
import ReactDOM from 'react-dom';
import { debounce, find, map } from 'lodash-es';
import $ from 'jquery';

import Ajax from '../util/ajax';

import AutocompleteResult from 'models/autocomplete_result';
import AutocompleteFieldResults from './AutocompleteFieldResults';
import SearchLoadingIcon from 'components/Explorer/SearchLoadingIcon';

export default class AutocompleteSingleField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayValue: props.initiallySelected || '',
      loading: false,
      showingResults: false,
      results: [],
      selectedResultIndex: null,
      focused: false
    };
  }

  render() {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events -- [TODO]
      <div className={`AutocompleteField ${this.focusedClass}`} onClick={this.makeFocused}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events -- [TODO] */}
        <div
          className={`AutocompleteField-reset-button ${this.state.displayValue === '' ? 'hidden' : undefined}`}
          onClick={this.clearSearch}
        >
          {I18n.t('clearable.clear')}
        </div>
        <SearchLoadingIcon loading={this.state.loading} />
        <div className="AutocompleteField-item-list">
          {this.placeholder}
          <input
            id={this.props.id}
            name={this.props.name}
            type="text"
            className="AutocompleteField-text-input"
            ref={this.storeInputField}
            onInput={this.didUpdateQuery}
            onBlur={this.handleBlur}
            onFocus={this.makeFocused}
            onKeyDown={this.handleNavigationKeys}
            value={this.state.displayValue}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
          />
        </div>
        {this.results}
      </div>
    );
  }

  get results() {
    if (!this.state.showingResults) return;

    return (
      <AutocompleteFieldResults
        results={this.state.results}
        type={this.props.type}
        onSelectResult={this.selectResult}
        currentlySelected={this.state.results[this.state.selectedResultIndex]}
        ref={(ref) => (this.resultComponent = ref)}
      />
    );
  }

  get placeholder() {
    if (this.state.displayValue !== '') return;

    return <div className="AutocompleteField-placeholder">{this.props.placeholder}</div>;
  }

  get focusedClass() {
    return this.state.focused ? 'focused' : 'blurred';
  }

  handleNavigationKeys = (e) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        this.clearSearch();
        break;
      case 'Enter':
        e.preventDefault();
        if (this.state.selectedResultIndex != null && this.state.results[this.state.selectedResultIndex]) {
          this.selectResult(this.state.results[this.state.selectedResultIndex]);
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.moveSelectionUp();
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.moveSelectionDown();
        break;
    }
  };

  moveSelectionUp = () => {
    const next = this.state.selectedResultIndex ? this.state.selectedResultIndex - 1 : this.state.results.length - 1;

    this.setState({ selectedResultIndex: next }, this.updateScrollPosition);
  };

  moveSelectionDown = () => {
    const next =
      this.state.selectedResultIndex == null || this.state.selectedResultIndex === this.state.results.length - 1
        ? 0
        : this.state.selectedResultIndex + 1;

    this.setState({ selectedResultIndex: next }, this.updateScrollPosition);
  };

  updateScrollPosition = () => {
    const node = ReactDOM.findDOMNode(this.resultComponent); // eslint-disable-line react/no-find-dom-node
    const $selectedRow = $(node).find('.AutocompleteFieldResults-row.selected');
    const $wrapper = $(node).find('.AutocompleteFieldResults-content');

    if (!$selectedRow.length) return;

    const rowTop = $selectedRow.position().top;
    const rowHeight = $selectedRow.height();
    const currentScroll = $wrapper.scrollTop();
    const wrapperHeight = $wrapper.height();
    const wrapperPadding = 11;

    if (rowTop + rowHeight > wrapperHeight) {
      const scrollAmount = rowTop + rowHeight + wrapperPadding - wrapperHeight;
      $wrapper.scrollTop(currentScroll + scrollAmount);
    } else if (rowTop < 0) {
      $wrapper.scrollTop(currentScroll + rowTop);
    }
  };

  didUpdateQuery = (e) => {
    const text = $(e.target).val();

    this.setState({
      displayValue: text,
      showingResults: this.state.results.length !== 0
    });

    if (text.trim() === '') {
      this.clearSearch();
    } else {
      if (this.ajaxRequest) this.ajaxRequest.abort();

      this.doSearch(text);

      this.setState({
        loading: true
      });
    }
  };

  clearSearch = () => {
    if (this.ajaxRequest) this.ajaxRequest.abort();

    return this.setState({
      displayValue: '',
      loading: false,
      showingResults: false,
      results: [],
      selectedResultIndex: null
    });
  };

  doSearch = debounce((text) => {
    if (this.state.displayValue.trim() !== text) return;

    this.ajaxRequest = Ajax.doAutocompleteSearch(this.props.type, text)
      .done((searchResults) => {
        if (!find(searchResults, (r) => r.name === text)) {
          searchResults.unshift({ id: 1, name: text });
        }

        this.setState({
          results: map(searchResults, this.modelizeResult),
          selectedResultIndex: 0,
          showingResults: true
        });
      })
      .always(() => this.setState({ loading: false }));
  }, 200);

  modelizeResult = (result) => {
    return new AutocompleteResult.UberResearchInstitution(result);
  };

  selectResult = (match) => {
    this.clearSearch();

    this.setState({
      displayValue: match.name
    });
  };

  storeInputField = (field) => {
    this.field = $(field);
  };

  makeFocused = () => {
    this.setState({ focused: true });
    this.field.focus();
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  startAutocompleteWith = (text) => {
    $(this.field).val(text);
    const event = document.createEvent('HTMLEvents');
    event.initEvent('input', true, true);

    $(this.field).focus()[0].dispatchEvent(event);
  };
}
