import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Query from 'components/Explorer/models/query';
import ResearchOutput from 'components/Explorer/models/research_output';
import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

class Output extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  query = null;

  static propTypes = {
    output: PropTypes.instanceOf(ResearchOutput).isRequired,
    history: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <Link to={this.query.withShowDetails(this.props.output.id).location} className="Mentions-Output">
        <div className="badge">
          <img
            src={this.props.output.badgeUrl}
            loading="lazy"
            alt={I18n.t('Explorer.Mentions.badge.alt', {
              score: this.props.output.score
            })}
          />
          {this.verifiedTick}
        </div>

        <div className="title">
          <span className="main">{this.props.output.title}</span>
          <span className="source">{this.props.output.meta}</span>
        </div>
      </Link>
    );
  }

  get verifiedTick() {
    if (!this.props.output.verified) return;

    return (
      <div className="verified-badge" data-testid="verified-badge">
        <div
          className="icon"
          title={I18n.t('Explorer.Outputs.ResearchOutputItem.verified', {
            name: window.current_user.institution_name
          })}
        />
      </div>
    );
  }
}

export default withRouter(Output);
