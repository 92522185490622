import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const HelpLink = (props) => {
  const timerRef = useRef(null);

  const trackEvent = (eventName) => {
    Analytics.trackEvent(`Advanced Search: Help-Link ${eventName}`, {
      field_name: props.analyticsFieldName
    });
  };

  const onMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      trackEvent('Hovered');
    }, 1000);
  };

  const onMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  return (
    <a
      className="help"
      href={props.href}
      data-tooltip-content={props.tip}
      target="_blank"
      rel="noreferrer"
      onClick={() => trackEvent('Clicked')}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {I18n.t('Explorer.AdvancedSearch.help')}
    </a>
  );
};

HelpLink.propTypes = {
  href: PropTypes.string.isRequired,
  tip: PropTypes.string.isRequired,
  analyticsFieldName: PropTypes.string.isRequired
};

export default HelpLink;
