import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';
import HelpLink from './HelpLink';

class DimensionsSearchFilter extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static propTypes = {
    history: PropTypes.object,
    filterSet: PropTypes.instanceOf(FilterSet).isRequired
  };

  state = {
    dimensionsSearch: null,
    loading: true
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    if (this.searchID) {
      this.loadDimensionsSearch();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  componentDidUpdate = () => {
    if (this.state.dimensionsSearch && !this.searchID) this.setState({ dimensionsSearch: null });
  };

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <div
        className="Explorer-AdvancedSearch-field-container DimensionsSearchFilter"
        onKeyDown={(e) => e.stopPropagation()}
      >
        {this.label}
        <fieldset>{this.state.loading ? this.loadingMessage : this.content}</fieldset>
      </div>
    );
  }

  get loadingMessage() {
    return (
      <div className="DimensionsSearchFilter-loading-message">
        {I18n.t('Explorer.AdvancedSearch.fields.dimensions_search.loading')}
      </div>
    );
  }

  get content() {
    return this.state.dimensionsSearch ? this.editSearchContent : this.createSearchContent;
  }

  get label() {
    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <label>
        &nbsp;
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.fields.dimensions_search.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.dimensions_search.description')}
          analyticsFieldName="Dimensions Integration"
        />
      </label>
    );
  }

  get createSearchContent() {
    return (
      <div className="DimensionsSearchFilter-content">
        <header>
          <img className="integrations-logo" src="/explorer/dimensions-logo.svg" alt="Dimensions" />
        </header>
        <div className="Explorer-AdvancedSearch-field-container-content-main" data-text-id="no_query">
          {I18n.htmlSafe('Explorer.AdvancedSearch.fields.dimensions_search.no_query_html')}
        </div>
      </div>
    );
  }

  get editSearchContent() {
    return (
      <div className="DimensionsSearchFilter-content">
        <header>
          <img className="integrations-logo" src="/explorer/dimensions-logo.svg" alt="Dimensions" />
        </header>
        <div className="Explorer-AdvancedSearch-field-container-content-main" data-text-id="query_info">
          {I18n.htmlSafe('Explorer.AdvancedSearch.fields.dimensions_search.query_info_html', {
            query_title: this.state.dimensionsSearch.description
          })}
        </div>
        <div className="Explorer-AdvancedSearch-field-container-buttons">
          {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
          <a className="generic-button" onClick={this.onClickSet} data-btn-id="edit">
            {I18n.t('Explorer.AdvancedSearch.fields.dimensions_search.edit')}
          </a>
          {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
          <a className="generic-button" onClick={this.onClickClear} data-btn-id="clear">
            {I18n.t('Explorer.AdvancedSearch.fields.dimensions_search.clear')}
          </a>
        </div>
      </div>
    );
  }

  // Events
  //////////////////////////////////////////////////////////////////////////////

  onClickSet = () => {
    window.open(window.site_config.dimensionsBaseURL, '_blank');
  };

  onClickClear = () => {
    this.props.filterSet.clear('dimensions_search_id');
    this.setState({ dimensionsSearch: null });
  };

  // Data handling
  //////////////////////////////////////////////////////////////////////////////

  loadDimensionsSearch = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.dimensionsSearch();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, dimensionsSearch: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  get searchID() {
    return this.props.filterSet.get('dimensions_search_id');
  }
}

export default withRouter(DimensionsSearchFilter);
