import './styles';

import { map, uniq } from 'lodash-es';

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';
import withRouter from 'components/withRouter';

import PageTitle from 'components/Explorer/PageTitle';
import Pagination from 'components/Explorer/Pagination';
import Query from 'components/Explorer/models/query';
import Spinner from 'components/Explorer/Spinner';
import Post from './Post';
import Summary from './Summary';
import FilterPanel from './FilterPanel';

class Mentions extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  query = null;

  state = {
    loading: true,
    loadingFailed: false,
    data: []
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters, Query.EVENTS.didChangePage);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-Mentions">
        <PageTitle metaTitle={I18n.t('Explorer.TabBar.mentions')} />
        <FilterPanel />
        {this.help}
        <Summary />
        <Transition classNames="Content" timeout={750}>
          {this.state.loading ? this.loadingMessage : this.content}
        </Transition>
      </div>
    );
  }

  get loadingMessage() {
    return <Spinner key="spinner" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;
    if (this.state.data.length === 0) return;

    return (
      <div key="content" className="Explorer-Mentions-Container">
        <Pagination lastPage={this.state.lastPage} onChangePage={this.trackPageChange} />
        {this.mentions}
        <Pagination lastPage={this.state.lastPage} onChangePage={this.trackPageChange} />
      </div>
    );
  }

  get errorMessage() {
    return (
      <div key="content" className="Explorer-Mentions-Container">
        {I18n.t('Explorer.loading_error')}
      </div>
    );
  }

  get mentions() {
    return (
      <div className="mention-timeline">
        {this.state.data.map(([date, posts]) => {
          const uniqPosts = uniq(posts);

          return (
            <div className="mention-date-boundary" key={`mentions-${date}`}>
              {map(uniqPosts, (post, index) => (
                <Post first={index === 0} post={post} key={post.id} />
              ))}
            </div>
          );
        })}
      </div>
    );
  }

  get help() {
    return (
      <a
        href={I18n.t('Explorer.help.mentions.uri')}
        target="_blank"
        rel="noreferrer"
        title={I18n.t('Explorer.help.mentions.content')}
        className="help"
      >
        {I18n.t('Explorer.help.label_tab')}
      </a>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.mentions();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let newState = { data: null, lastPage: null };
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        newState = {
          data: response.body.data,
          lastPage: response.body.lastPage
        };
      }

      this.setState({ loading: false, loadingFailed, ...newState }, this.scrollToTop);
    });
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  scrollToTop = () => {
    const root = ReactDOM.findDOMNode(this); // eslint-disable-line react/no-find-dom-node
    const wrapper = root.closest('.Explorer-ContentPanel');
    if (wrapper) wrapper.scrollTop = 0;
  };

  trackPageChange = (page) => {
    Analytics.trackEvent('mentions-paginate', { page });
  };
}

export default withRouter(Mentions);
