import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';

class SaveSearch extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    endpoint: PropTypes.string.isRequired,
    analyticsName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      saved: false,
      saving: false,
      failed: false
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    this.query.registerCallback(() => {
      this.setState({
        saved: false,
        saving: false,
        failed: false
      });
    }, Query.EVENTS.didChangeFilters);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  render() {
    if (window.current_user.librarian || window.current_user.ip_user) return null;

    if (this.state.failed) {
      return (
        <button onClick={this.saveSearch} disabled className="SaveSearch failed">
          {I18n.t('Explorer.SaveSearch.failed')}
        </button>
      );
    }

    if (this.state.saved) {
      return (
        <button onClick={this.saveSearch} disabled className="SaveSearch saved">
          {I18n.t('Explorer.SaveSearch.complete')}
        </button>
      );
    }

    if (this.state.saving) {
      return (
        <button onClick={this.saveSearch} disabled className="SaveSearch saving">
          {I18n.t('Explorer.SaveSearch.progress')}
        </button>
      );
    }

    return (
      <button onClick={this.saveSearch} className="SaveSearch save">
        {I18n.t('Explorer.SaveSearch.action')}
      </button>
    );
  }

  saveSearch = () => {
    Analytics.trackEvent('Search Saved', {
      location: this.props.analyticsName
    });
    this.setState({ saving: true });

    this.query
      .saveSearch(this.props.endpoint)
      .then(() => {
        this.setState({ saved: true, saving: false });
      })
      .catch(() => {
        this.setState({ failed: true });
      });
  };
}

export default withRouter(SaveSearch);
