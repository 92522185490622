import { throttle } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';

import fetch from 'util/fetch';
import NumberHelper from 'util/number_helper';
import { attachFocusLimiter } from 'components/Explorer/util/focus_limiter';

class Editor extends React.Component {
  static propTypes = {
    journalList: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    analyticsDialogueName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      content: '',
      tokens: []
    };

    this.textEntered = false;
  }

  componentDidMount() {
    this.trackEvent('Opened', this.props.journalList ? 'Edit' : 'Create');

    if (this.props.journalList) {
      this.setState(
        {
          content: this.props.journalList.content
        },
        this.updateTokens
      );
    }

    attachFocusLimiter(this.containerElement);
  }

  render() {
    return (
      <div className="JournalFilter-journalList-editor" ref={(ref) => (this.containerElement = ref)}>
        <div className="content">
          <div className="title">{I18n.t('Explorer.AdvancedSearch.fields.journal.editor.title')}</div>

          <div className="inner">
            <div className="note">
              {I18n.t('Explorer.AdvancedSearch.fields.journal.editor.issn_limit', {
                limit: this.formattedMaxTokens
              })}
            </div>
            <div className="form">
              <textarea
                value={this.state.content}
                placeholder={I18n.t('Explorer.AdvancedSearch.fields.journal.editor.issn_example')}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                onFocus={this.onFocus}
                ref={this.fieldRef}
              />
            </div>
          </div>

          <div className="footer">
            <div className={`meta ${this.tooManyIdentifiers ? 'error' : 'no-error'}`}>
              {I18n.htmlSafe('Explorer.AdvancedSearch.fields.journal.editor.status', {
                limit: this.formattedMaxTokens,
                current: this.formattedTokenCount
              })}
            </div>

            <div className="buttons">
              <button type="button" className="cancel" onClick={this.onCancel}>
                {I18n.t('Explorer.AdvancedSearch.fields.journal.editor.cancel')}
              </button>
              <button
                type="button"
                disabled={!this.canSave}
                className={`submit ${this.canSave ? 'enabled' : 'disabled'}`}
                onClick={this.onSave}
              >
                {I18n.t('Explorer.AdvancedSearch.fields.journal.editor.save')}
              </button>
            </div>
          </div>

          {this.state.saving ? this.savingIndicator : null}
        </div>
      </div>
    );
  }

  get savingIndicator() {
    return <div className="saving-message" />;
  }

  updateTokens = throttle(() => {
    this.setState({
      tokens: this.state.content.split(/\s+/).filter((t) => t.length)
    });
  }, 250);

  get maxTokens() {
    return window.current_user.maxIssnTokens;
  }

  get canSave() {
    return this.state.tokens.length > 0 && !this.tooManyIdentifiers;
  }

  get tooManyIdentifiers() {
    return this.state.tokens.length > this.maxTokens;
  }

  fieldRef = (f) => {
    this.field = f;

    if (f) f.focus();
  };

  onChange = (e) => {
    if (!this.textEntered && e.target.value.length > this.state.content.length) {
      this.textEntered = true;
      this.trackEvent('Filled');
    }

    this.setState(
      {
        content: e.target.value
      },
      this.updateTokens
    );
  };

  onCancel = () => {
    this.trackEvent('Closed');
    this.props.onCancel();
  };

  onSave = async () => {
    this.setState({ saving: true });

    const body = {
      journal_list: {
        issns: this.state.content
      }
    };

    try {
      const response = await fetch('/explorer/journal_lists', {
        method: 'post',
        body: JSON.stringify(body)
      });

      const journalList = await response.json();
      this.trackEvent('Data Updated', null, this.state.tokens.length);
      this.props.onSave(journalList);
    } catch (_error) {
      window.alert(I18n.t('Explorer.AdvancedSearch.fields.journal.editor.error'));
      this.setState({ saving: false });
    }
  };

  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      e.stopPropagation();
      this.onCancel();
    }
    if (e.key === 'Enter') e.stopPropagation();
  };

  onFocus = () => {
    if (this.field) this.field.select();
  };

  get formattedMaxTokens() {
    return NumberHelper.formatNumberWithDelimiter(this.maxTokens);
  }

  get formattedTokenCount() {
    return NumberHelper.formatNumberWithDelimiter(this.state.tokens.length);
  }

  trackEvent(eventName, action = null, itemsCount = null) {
    Analytics.trackEvent(`Advanced Search: Modal Dialogue ${eventName}`, {
      dialogue_name: this.props.analyticsDialogueName,
      ...(action !== null && { action: action }),
      ...(itemsCount !== null && { items_count: itemsCount })
    });
  }
}

export default Editor;
