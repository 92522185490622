import React from 'react';
import PropTypes from 'prop-types';

import DimensionsSearchFilter from './DimensionsSearchFilter';

import FilterSet from 'components/Explorer/models/filter_set';

class IntegrationsSearchFields extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired
  };

  render() {
    const all_fields = this.allIntegrationsSearchFields;
    if (!this.hasIntegrationsSearchFields(all_fields)) return null;

    return (
      <div className="Explorer-AdvancedSearch-field-group IntegrationsSearchFields">
        <h3 className="Explorer-AdvancedSearch-field-group-title">
          {I18n.t('Explorer.AdvancedSearch.groups.integrations')}
        </h3>
        {all_fields}
      </div>
    );
  }

  hasIntegrationsSearchFields(all_fields) {
    return React.Children.count(all_fields.props.children) > 0;
  }

  get allIntegrationsSearchFields() {
    return <div>{this.dimensionsSearchFilter}</div>;
  }

  get dimensionsSearchFilter() {
    if (window.current_user.librarian) return;

    return <DimensionsSearchFilter filterSet={this.props.filterSet} />;
  }
}

export default IntegrationsSearchFields;
