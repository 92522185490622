import React, { useState, useMemo } from 'react';

import { useAsyncDebounce } from 'react-table';

const GlobalFilter = ({ placeholder, globalFilter, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => setGlobalFilter(value || undefined), 200);

  const clearFilters = useMemo(() => {
    return () => {
      setValue(null);
      onChange(null);
    };
  });

  return (
    <div className="Explorer-Journals-Filters-Container">
      <label htmlFor="search">Search Journals and Collections:</label>
      <div className="Journals-Table-Filters-Controls">
        <input
          name="search"
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={placeholder}
        />
        <button onClick={clearFilters}>Clear</button>
      </div>
    </div>
  );
};

export default GlobalFilter;
