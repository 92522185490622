import React from 'react';

const ErrorMessage = () => {
  return (
    <div className="Journals-Table-Message-Container Journals-Table-Message-Container--error">
      {I18n.t('Explorer.Journals.loading_failed')}
    </div>
  );
};

export default ErrorMessage;
