import './styles';

import React from 'react';

import PageTitle from 'components/Explorer/PageTitle';
import Breakdown from 'components/Highlights/Breakdown';
import Summary from 'components/Highlights/Summary';
import TopOutputs from 'components/Highlights/TopOutputs';
import TopOutputsWithMentions from 'components/Highlights/TopOutputsWithMentions';
import TopMentionSources from 'components/Highlights/TopMentionSources';
import NewsHighlights from 'components/Highlights/NewsHighlights';
import TopJournals from 'components/Highlights/TopJournals';
import Distribution from 'components/Highlights/Distribution';
import Demographics from 'components/Highlights/Demographics';
import LatestMentions from 'components/Highlights/LatestMentions';
import TopAffiliations from 'components/Highlights/TopAffiliations';
import TopSubjectAreas from 'components/Highlights/TopSubjectAreas';
import withFeatureFlag from 'components/FeatureFlipper';

const Outputs = withFeatureFlag(TopOutputsWithMentions, 'timeframe_filtered_mentions', TopOutputs);

const Nothing = () => <></>;
const DistributionOrNothing = withFeatureFlag(Nothing, 'timeframe_filtered_mentions', Distribution);

class Highlights extends React.Component {
  render() {
    return (
      <div className="Explorer-Highlights">
        <PageTitle metaTitle={I18n.t('Explorer.TabBar.highlights')} />
        <div className="Explorer-Highlights-Summary-row">
          <Summary />
        </div>
        <div className="Explorer-Highlights-Breakdown-row">
          <Breakdown />
        </div>
        <div className="Explorer-Highlights-blocks">
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-TopOutputs">
            <Outputs />
          </div>
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-TopMentionSources">
            <TopMentionSources />
          </div>
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-TopJournals">
            <TopJournals />
          </div>
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-TopAffiliations">
            <TopAffiliations />
          </div>
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-TopSubjectAreas">
            <TopSubjectAreas />
          </div>
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-NewsHighlights">
            <NewsHighlights />
          </div>
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-LatestMentions">
            <LatestMentions />
          </div>
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-Demographics">
            <Demographics />
          </div>
          <div className="Explorer-Highlights-block-wrapper Explorer-Highlights-block-wrapper-Distribution">
            <DistributionOrNothing />
          </div>
        </div>
      </div>
    );
  }
}

export default Highlights;
