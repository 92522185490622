import React from 'react';
import { filter, includes } from 'lodash-es';

import { IDENTIFIER_FIELD_TYPES, INSTITUTIONAL_FIELD_TYPES } from '../../models/dataset';
import NumberHelper from '../../util/number_helper';

class OutputsConfigurationPanel extends React.Component {
  constructor() {
    super();

    this.state = {
      previewingRow: 0
    };
  }

  render() {
    return (
      <div className="DatasetUploadPopover-OutputsConfigurationPanel DatasetUploadPopover-ContentPanel">
        {this.documentation}

        {this.renderFieldSelector()}
      </div>
    );
  }

  get documentation() {
    return (
      <div className="DatasetUploadPopover-documentation-panel">
        <div className="header-icon">
          <h2>{I18n.t('dataset_upload.outputs_configuration.title')}</h2>
          <a
            className="help"
            href={I18n.t('dataset_upload.outputs_configuration.help_uri')}
            title={I18n.t('dataset_upload.help_title')}
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </div>
        <p>
          We have used the headers and content of your CSV file to guess what type of data is in each column. Please
          check the results below and adjust as required, before clicking the <strong>Next</strong> button to continue.
        </p>
        <p>
          For more details, examples, or help to select the correct columns,{' '}
          <a href={I18n.t('dataset_upload.outputs_configuration.help_uri')} target="_blank" rel="noopener noreferrer">
            click here to read our support documentation.
          </a>
          .
        </p>
      </div>
    );
  }

  renderFieldSelector() {
    return (
      <div className="panel-field-selector">
        <table className="table-field-selector">
          <thead>
            <tr>
              <th className="column">{I18n.t('dataset_upload.outputs_configuration.headers.column')}</th>
              <th className="type">{I18n.t('dataset_upload.outputs_configuration.headers.type')}</th>
              <th className="preview">
                {I18n.t('dataset_upload.outputs_configuration.headers.preview')}
                <PreviewRowSelector
                  currentRow={this.state.previewingRow}
                  totalRows={this.props.dataset.totalRows}
                  onSelectPreviewRow={(row) => this.setState({ previewingRow: row })}
                  onRevert={this.revertPreviewRow}
                />
              </th>
              <th className="warning"></th>
            </tr>
          </thead>
          <tbody>{this.renderFieldSelectorRows()}</tbody>
        </table>
      </div>
    );
  }

  renderFieldSelectorRows() {
    return this.props.dataset.columns.map((column) => {
      return (
        <tr key={column.id}>
          <td className="column">{column.header}</td>
          <td className="type">
            <TypeSelector
              value={column.fieldType}
              onChange={(newType) => this.remapColumnFieldTypes(column, newType)}
            />
          </td>
          <td className="preview">{column.preview(this.state.previewingRow)}</td>
          <td className="warning">{this.renderWarning(column)}</td>
        </tr>
      );
    });
  }

  remapColumnFieldTypes = (changedColumn, newType) => {
    let isNewTypeAnIdentifier = includes(IDENTIFIER_FIELD_TYPES, newType);
    if (isNewTypeAnIdentifier) {
      let existingColumnsWithThisType = filter(this.props.dataset.columns, (c) => c.fieldType === newType);
      existingColumnsWithThisType.forEach((c) => (c.fieldType = null));
    }

    changedColumn.fieldType = newType;
    this.forceUpdate();
  };

  renderWarning(column) {
    if (!column.fieldType) {
      return;
    } else if (!column.warning) {
      return <span className="icon ok" title={I18n.t('dataset_upload.outputs_configuration.warnings.none')} />;
    } else {
      return <span className="icon warning" title={column.warningMessage} />;
    }
  }
}

class TypeSelector extends React.Component {
  render() {
    return (
      <select
        value={this.value}
        onChange={this.onChange}
        className={`TypeSelector ${this.value === '' ? 'deselected' : 'selected'}`}
      >
        <option value={''}>{I18n.t('dataset_upload.outputs_configuration.types.ignored')}</option>
        <optgroup label={I18n.t('dataset_upload.outputs_configuration.types.institutional')}>
          {this.renderOptions(INSTITUTIONAL_FIELD_TYPES)}
        </optgroup>
        <optgroup label={I18n.t('dataset_upload.outputs_configuration.types.identifiers')}>
          {this.renderOptions(IDENTIFIER_FIELD_TYPES)}
        </optgroup>
      </select>
    );
  }

  renderOptions(list) {
    return list.map((type) => {
      return (
        <option key={type} value={type}>
          {I18n.t(type, {
            scope: 'dataset_upload.outputs_configuration.types'
          })}
        </option>
      );
    });
  }

  get value() {
    if (this.props.value) {
      return this.props.value;
    } else {
      return '';
    }
  }

  onChange = (e) => {
    let newValue = e.target.value;

    this.props.onChange(newValue);
  };
}

class PreviewRowSelector extends React.Component {
  render() {
    return (
      <div className="PreviewRowSelector">
        <span className="PreviewRowSelector-count">
          {I18n.t('dataset_upload.outputs_configuration.preview.count', {
            current: NumberHelper.formatNumberWithDelimiter(this.props.currentRow + 1),
            total: NumberHelper.formatNumberWithDelimiter(this.props.totalRows)
          })}
        </span>
        {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
        <a className="PreviewRowSelector-advance" onClick={this.advanceRow}>
          {I18n.t('dataset_upload.outputs_configuration.preview.advance')}
        </a>
        {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
        <a className="PreviewRowSelector-revert" onClick={this.revertRow}>
          {I18n.t('dataset_upload.outputs_configuration.preview.revert')}
        </a>
      </div>
    );
  }

  advanceRow = () => {
    if (this.props.currentRow < this.props.totalRows - 1) {
      this.props.onSelectPreviewRow(this.props.currentRow + 1);
    } else {
      this.props.onSelectPreviewRow(0);
    }
  };

  revertRow = () => {
    if (this.props.currentRow > 0) {
      this.props.onSelectPreviewRow(this.props.currentRow - 1);
    } else {
      this.props.onSelectPreviewRow(this.props.totalRows - 1);
    }
  };
}

export { OutputsConfigurationPanel };
