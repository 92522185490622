const formatNumberWithDelimiter = (number, delimiter = ',') => {
  if (!number) {
    return '0';
  }

  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${delimiter}`);
};

const formatStatistic = (number) => {
  if (!number || number <= 1000000) {
    return formatNumberWithDelimiter(number);
  }

  let hugeNumber = Number.MAX_SAFE_INTEGER || 9007199254740991,
    units = [
      { unit: ' million', limit: 1000000000, number: 1000000 },
      { unit: ' billion', limit: hugeNumber, number: 1000000000 }
    ],
    unit = units.find((unit) => number < unit.limit),
    singlePrecision = Math.trunc(number / (unit.number / 10)) / 10;

  return singlePrecision + unit.unit;
};

const formatPercentage = (number) => {
  if (!number) {
    return '0';
  } else if (number < 0.01) {
    return '<1';
  }

  return `${Math.floor(number * 100)}`;
};

export default {
  formatNumberWithDelimiter,
  formatStatistic,
  formatPercentage
};
