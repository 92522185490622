import { includes } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';
import Countries from 'country-list';

import FilterSet from 'components/Explorer/models/filter_set';
import AutocompleteField from 'components/Explorer/AutocompleteField';

class CountriesField extends React.Component {
  state = {
    loading: true,
    countries: []
  };

  static defaultProps = {
    fieldID: 'countries',
    fieldName: 'countries[]',
    autocompleteType: 'countries',
    filterSetParam: 'countries[]'
  };

  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    filterSetParam: PropTypes.string,
    fieldID: PropTypes.string,
    fieldName: PropTypes.string,
    autocompleteType: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChangeCallback: PropTypes.func
  };

  UNSAFE_componentWillMount() {
    if (this.props.filterSet.get(this.props.filterSetParam).length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-FilterPanel-field Explorer-CountriesField">
        {this.label}
        {this.state.loading ? this.loadingMessage : this.field}
      </div>
    );
  }

  get loadingMessage() {
    return <div>{I18n.t('Explorer.Form.CountriesField.loading')}</div>;
  }

  get label() {
    if (!this.props.label) return;

    return <span className="label">{this.props.label}</span>;
  }

  get field() {
    return (
      <AutocompleteField
        id={this.props.fieldID}
        name={this.props.fieldName}
        type={this.props.autocompleteType}
        placeholder={this.props.placeholder}
        onChangeItems={this.updateSelectedCountries}
        initiallySelected={this.state.countries}
        searchHandler={this.searchHandler}
        ref={this.storeRef}
      />
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = async () => {
    this.setState({ loading: true });

    const selectedCountries = this.props.filterSet.get(this.props.filterSetParam);
    const countries = Countries.getData().filter((country) => includes(selectedCountries, country.code));

    this.setState({ loading: false, countries: countries });
  };

  // Actions
  //////////////////////////////////////////////////////////////////////////////

  storeRef = (field) => {
    this.fieldRef = field;
  };

  searchHandler = (_type, text) => {
    const countries = Countries.getData().filter((country) => {
      const match = new RegExp(text, 'i');

      return country.name.match(match) || country.code.match(match);
    });

    const response = { body: countries };
    return {
      abort: () => {},
      end: function (callback) {
        callback(null, response);
        return this;
      }
    };
  };

  updateSelectedCountries = (countries) => {
    if (this.props.onChangeCallback) this.props.onChangeCallback();

    this.props.filterSet.set(
      this.props.filterSetParam,
      countries.map((country) => country.id)
    );
  };

  clear() {
    this.clearSelected();

    this.updateSelectedCountries([]);
  }

  // Removes selected countries, keeping filterSet untouched
  clearSelected() {
    if (this.fieldRef) this.fieldRef.clearAll();
  }
}

export default CountriesField;
