import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';

class SearchField extends React.Component {
  static propTypes = {
    fieldID: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    placeholder: PropTypes.string,
    analyticsEventPrefix: PropTypes.string,
    analyticsFieldName: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.fieldSelected = false;
    this.previousValue = this.value;
  }

  render() {
    return (
      <div className="Explorer-SearchField">
        <div className="inner">
          <div className="content">
            <input
              id={this.props.fieldID}
              name={this.props.fieldName}
              autoComplete="off"
              type="text"
              value={this.value}
              onChange={this.onChange}
              placeholder={this.props.placeholder}
              onClick={this.handleClick}
              onBlur={this.handleBlur}
            />
          </div>
          {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
          <div className={`reset-button ${this.value == '' ? 'hidden' : 'not-hidden'}`} onClick={this.clear}>
            {I18n.t('Explorer.SearchField.clear')}
          </div>
        </div>
      </div>
    );
  }

  clear = () => {
    this.props.filterSet.clear(this.props.fieldName);
    this.trackEvent('Cleared');
    this.previousValue = '';
    this.forceUpdate();
  };

  onChange = (e) => {
    this.props.filterSet.set(this.props.fieldName, e.target.value);
    this.forceUpdate();
  };

  handleClick = () => {
    if (this.fieldSelected) {
      return;
    }

    this.trackEvent('Selected');
    this.fieldSelected = true;
  };

  handleBlur = () => {
    this.fieldSelected = false;

    if (this.valueChanged) {
      this.trackEvent('Filled', this.value);
      this.previousValue = this.value;
      return;
    }

    if (this.manuallyCleared) {
      this.trackEvent('Manually Cleared');
      this.previousValue = '';
      return;
    }
  };

  trackEvent(eventName, value = null) {
    if (!this.trackingEnabled) {
      return;
    }

    Analytics.trackEvent(`${this.props.analyticsEventPrefix} ${eventName}`, {
      field_name: this.props.analyticsFieldName,
      ...(value && { value: value })
    });
  }

  get valueChanged() {
    return this.value && this.value !== this.previousValue;
  }

  get manuallyCleared() {
    return this.value === '' && this.value !== this.previousValue;
  }

  get trackingEnabled() {
    return !!(this.props.analyticsEventPrefix && this.props.analyticsFieldName);
  }

  get value() {
    return this.props.filterSet.get(this.props.fieldName) || '';
  }
}

export default SearchField;
