import React from 'react';
import PropTypes from 'prop-types';

import Explorer from 'components/Explorer';

class ApplicationLoader extends React.Component {
  static propTypes = {
    application: PropTypes.string.isRequired
  };

  render() {
    switch (this.props.application) {
      case 'explorer':
        return React.createElement(Explorer, this.props);
      default:
        return null;
    }
  }
}

export default ApplicationLoader;
