import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';

const tabs = ['highlights', 'outputs', 'timeline', 'demographics', 'mentions', 'mention_sources', 'journals'];

class TabBar extends React.Component {
  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  render() {
    return (
      <nav className="Explorer-TabBar" aria-label={I18n.t('Explorer.accessibility.TabBar')}>
        {this.tabLinks}
      </nav>
    );
  }

  get tabLinks() {
    return tabs
      .filter((tab) => tab != 'mention_sources' || this.mention_sources_access_granted)
      .map((tab) => {
        return (
          <NavLink key={tab} to={this.query.locationWithPathname(`/${tab}`)} activeClassName="active">
            {I18n.t(tab, { scope: 'Explorer.TabBar' })}
          </NavLink>
        );
      });
  }

  get mention_sources_access_granted() {
    return window.current_user.mention_sources_access;
  }
}

export default withRouter(TabBar);
