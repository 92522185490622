import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';

import AutocompleteField from '../AutocompleteField';
import HelpLink from './HelpLink';

class HandlePrefixFilter extends React.Component {
  // Setup
  ////////////////////////////////////////////////////////////////////////////

  query = null;
  ANALYTICS_FIELD_NAME = 'Handle Prefix';

  state = {
    handlePrefixes: []
  };

  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object
  };

  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.setState({
      handlePrefixes: this.handlePrefixes.map((p) => ({ id: p }))
    });
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  // Rendering
  ////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field HandlePrefixFilter">
        {this.label}
        {this.field}
      </div>
    );
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.handle_prefix.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.handle_prefix.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get field() {
    return (
      <AutocompleteField
        name="handle_prefix"
        id="filter_handle_prefix"
        type="handle_prefixes"
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.handle_prefix.placeholder')}
        onChangeItems={this.updateSelection}
        initiallySelected={this.state.handlePrefixes}
        ref={this.getFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };

  // Data
  ////////////////////////////////////////////////////////////////////////////

  updateSelection = (handlePrefixes) => {
    this.props.filterSet.set(
      'handle_prefix[]',
      handlePrefixes.map((a) => a.id)
    );
  };

  get handlePrefixes() {
    return this.props.filterSet.get('handle_prefix[]');
  }

  componentDidUpdate() {
    if (!this.fieldRef) return;
    if (!this.handlePrefixes.length) this.fieldRef.clearAll();
  }
}

export default withRouter(HandlePrefixFilter);
