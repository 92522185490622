import React from 'react';
import { map } from 'lodash-es';

class ProgressBar extends React.Component {
  render() {
    return (
      <div className="DatasetUploadPopover-ProgressBar">{map(I18n.t('dataset_upload.stages'), this.renderStage)}</div>
    );
  }

  renderStage = (label, stage) => {
    const classes = ['stage', `stage-${stage}`];
    let stageIndex = parseInt(stage);

    if (stageIndex === this.props.currentStage) {
      classes.push('active');
    }
    if (stageIndex < this.props.currentStage) {
      classes.push('previous');
    }
    if (stageIndex > this.props.currentStage) {
      classes.push('future');
    }

    let selectableStage = (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <a className="stage-inner" onClick={() => this.selectStage(stageIndex)}>
        <span className="status" />
        <span className="label">{label}</span>
      </a>
    );

    let futureStage = (
      <span className="stage-inner">
        <span className="status" />
        <span className="label">{label}</span>
      </span>
    );

    return (
      <div className={classes.join(' ')} key={stage}>
        {stageIndex < this.props.currentStage ? selectableStage : futureStage}
      </div>
    );
  };

  selectStage = (stage) => this.props.onSelectStage(stage);
}

export { ProgressBar };
