import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';
import MentionSourceProfileIcon from 'components/Explorer/ContentPanel/MentionSourceProfileIcon';

class TopMentionSources extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  state = {
    loading: true,
    loadingFailed: false,
    data: []
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-TopMentionSources Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.TopMentionSources.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition classNames="Content" timeout={250}>
            {this.state.loading ? this.loadingMessage : this.content}
          </Transition>
          {this.jumpLink}
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div key="loadingMessage" className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div key="content" className="Highlights-content Highlights-TopMentionSources-content">
        {this.hasResults ? this.contentTable : this.emptyMessage}
      </div>
    );
  }

  get errorMessage() {
    return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get contentTable() {
    return (
      <table className="content-table">
        <tbody>{this.state.data.map((mentionSource) => this.row(mentionSource))}</tbody>
      </table>
    );
  }

  get emptyMessage() {
    return (
      <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.TopMentionSources.empty')}</div>
    );
  }

  get jumpLink() {
    if (!this.hasResults) return;
    if (!window.current_user.mention_sources_access) return;

    return (
      <Link
        onClick={this.trackJumpLink}
        to={this.query.locationWithPathname('/mention_sources')}
        className="Highlights-jump-link"
      >
        {I18n.t('Explorer.Highlights.TopMentionSources.more')}
      </Link>
    );
  }

  row(mentionSource) {
    const typeStr = I18n.t(`Explorer.Highlights.TopMentionSources.mention_source_types.${mentionSource.profileType}`);

    return (
      <tr key={mentionSource.id} onClick={() => this.props.history.push(this.linkForMentionSource(mentionSource))}>
        <td className="mention-source-logo">
          <MentionSourceProfileIcon profileType={mentionSource.profileType} />
        </td>
        <td className="mention-source-data">
          <span className="name">
            <Link to={this.linkForMentionSource(mentionSource)}>{mentionSource.name}</Link>
          </span>
          <span className="meta">
            {I18n.htmlSafe('Explorer.Highlights.TopMentionSources.meta', {
              count: mentionSource.mentions,
              formattedCount: NumberHelper.formatNumberWithDelimiter(mentionSource.mentions),
              source: typeStr
            })}
          </span>
        </td>
      </tr>
    );
  }

  linkForMentionSource(mentionSource) {
    const filters = this.query.cloneParams();
    filters['mention_sources[]'] = [mentionSource.id];

    return this.query.withFilters(filters).locationWithPathname('/mentions');
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsTopMentionSources();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body;
      }

      this.setState({ loading: false, loadingFailed, data });
    });
  };

  get hasResults() {
    return this.state.data && this.state.data.length > 0;
  }

  trackJumpLink = () => {
    Analytics.trackEvent('highlights-click-jump-link', {
      jumpedTo: 'top-mentioners'
    });
  };
}

export default withRouter(TopMentionSources);
