import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';

import AutocompleteField from '../AutocompleteField';
import HelpLink from './HelpLink';

class FunderFilter extends React.Component {
  // Setup
  ////////////////////////////////////////////////////////////////////////////

  query = null;
  ANALYTICS_FIELD_NAME = 'Funder Name';

  state = {
    loading: true,
    funders: []
  };

  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    initialSearch: PropTypes.string
  };

  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    if (this.gridIDs.length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  ////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field FunderFilter">
        {this.label}
        {this.state.loading ? this.loadingMessage : this.field}
      </div>
    );
  }

  get loadingMessage() {
    return <div>{I18n.t('Explorer.AdvancedSearch.fields.funders.loading')}</div>;
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.funders.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.funders.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get field() {
    return (
      <AutocompleteField
        name="funders"
        id="funders"
        type="funders"
        initialValue={this.props.initialSearch}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.funders.placeholder')}
        onChangeItems={this.updateSelectedFunders}
        initiallySelected={this.state.funders}
        ref={this.getFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  // Data
  ////////////////////////////////////////////////////////////////////////////

  updateSelectedFunders = (funders) => {
    this.props.filterSet.set(
      'funders[]',
      funders.map((a) => a.id)
    );
  };

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.funders();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, funders: response.body });
      } else {
        this.setState({ loading: false, funders: [] });
      }
    });
  };

  get gridIDs() {
    return this.props.filterSet.get('funders[]').map((grid_id) => ({ id: grid_id }));
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };

  componentDidUpdate = () => {
    if (!this.fieldRef) return;
    if (!this.gridIDs.length) this.fieldRef.clearAll();
  };
}

export default withRouter(FunderFilter);
