import { each } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';
import { DateField, SelectField } from 'components/Explorer/Form';
import config from 'util/site_config.js.erb';
import HelpLink from './HelpLink';

class DateSearchFields extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      maximumDate: '9999-12-31',
      minimumDate: '0001-01-01'
    };
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field-group">
        <h3 className="Explorer-AdvancedSearch-field-group-title">{I18n.t('Explorer.AdvancedSearch.groups.dates')}</h3>
        {this.publicationDateFilter}
        {this.timeframeFilter}
      </div>
    );
  }

  get publicationDateFilter() {
    if (window.current_user.librarian) return;

    return (
      <div className="Explorer-AdvancedSearch-field Explorer-AdvancedSearch-PublicationDate-Filter">
        <label htmlFor="AdvancedSearch-PublicationDate-Filter">
          {I18n.t('Explorer.AdvancedSearch.fields.publication_date.title')}
          {this.publicationDateLabel}
        </label>
        <div className="DateSearchFields" id="AdvancedSearch-PublicationDate-Filter">
          {this.publishedAfterFilter}
          {this.publishedBeforeFilter}
        </div>
      </div>
    );
  }

  get publicationDateLabel() {
    return (
      <HelpLink
        href={I18n.t('Explorer.AdvancedSearch.help_uri')}
        tip={I18n.t('Explorer.AdvancedSearch.fields.publication_date.description')}
        analyticsFieldName="Publication Date"
      />
    );
  }

  onPublishedAfterChange = (dateText) => {
    this.setState({
      minimumDate: dateText
    });
  };

  get publishedAfterFilter() {
    return (
      <div className="Explorer-AdvancedSearch-field">
        <DateField
          fieldID="AdvancedSearch-PublishedAfter-Filter"
          fieldName="published_after"
          label={I18n.t('Explorer.AdvancedSearch.fields.published_after.title')}
          filterSet={this.props.filterSet}
          placeholder={I18n.t('Explorer.AdvancedSearch.fields.published_after.placeholder')}
          onChangeCallback={this.onPublishedAfterChange}
          maximumDate={this.state.maximumDate}
          analyticsEventPrefix="Advanced Search: Date Picker"
          analyticsFieldName="Between Date"
        />
      </div>
    );
  }

  onPublishedBeforeChange = (dateText) => {
    this.setState({
      maximumDate: dateText
    });
  };

  get publishedBeforeFilter() {
    return (
      <div className="Explorer-AdvancedSearch-field">
        <DateField
          fieldID="AdvancedSearch-PublishedBefore-Filter"
          fieldName="published_before"
          label={I18n.t('Explorer.AdvancedSearch.fields.published_before.title')}
          filterSet={this.props.filterSet}
          placeholder={I18n.t('Explorer.AdvancedSearch.fields.published_before.placeholder')}
          onChangeCallback={this.onPublishedBeforeChange}
          minimumDate={this.state.minimumDate}
          analyticsEventPrefix="Advanced Search: Date Picker"
          analyticsFieldName="And Date"
        />
      </div>
    );
  }

  get timeframeFilter() {
    return (
      <div className="Explorer-AdvancedSearch-field Explorer-AdvancedSearch-Timeframe-Filter">
        <label htmlFor="AdvancedSearch-Timeframe-Filter">
          {I18n.t('Explorer.AdvancedSearch.fields.timeframe.title')}
          {this.timeframeLabel}
        </label>
        <SelectField
          fieldID="AdvancedSearch-Timeframe-Filter"
          fieldName="timeframe"
          filterSet={this.props.filterSet}
          collection={this.timeframeOptions}
          emptyLabel={I18n.t('Explorer.AdvancedSearch.fields.timeframe.at')}
          analyticsEventPrefix="Advanced Search: Field"
          analyticsFieldName="Altmetric Mentions During"
        />
      </div>
    );
  }

  get timeframeOptions() {
    const options = {};
    each(config.timeframes, (key) => (options[key] = I18n.t(`Explorer.AdvancedSearch.fields.timeframe.${key}`)));

    return options;
  }

  get timeframeLabel() {
    return (
      <HelpLink
        href={I18n.t('Explorer.AdvancedSearch.help_uri')}
        tip={I18n.t('Explorer.AdvancedSearch.fields.timeframe.description')}
        analyticsFieldName={'Almetric Mentions during'}
      />
    );
  }
}

export default DateSearchFields;
