import React from 'react';
import Query from 'components/Explorer/models/query';
import request from 'superagent';

/**
 * withFeatureFlag HOC
 *
 * @param {function} WrappedComponent Component needs to be feature flipped
 * @param {string} [feature=Component name] Specifies feature flipper name
 * @param {function} [FallbackComponent=null] Component to render when the feature is not enabled for the user. If not provided nothing is rendered.
 * @returns {function}
 */
function withFeatureFlag(WrappedComponent, feature, FallbackComponent = null) {
  class WithFeatureFlag extends React.Component {
    state = {
      features: [],
      requestCompleted: false
    };

    componentDidMount() {
      request
        .get(`${Query.BASE_JSON_PATH}/features`)
        .set('X-Requested-With', 'XMLHttpRequest')
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .end(this.onResponse);
    }

    isFeatureEnabled() {
      const { features } = this.state;
      return features.includes(feature || WrappedComponent.name);
    }

    isRequestCompleted() {
      return this.state.requestCompleted;
    }

    onResponse = (err, response) => {
      if (!err) {
        this.setState({ features: response.body, requestCompleted: true });
      } else {
        this.setState({ features: [], requestCompleted: true });
      }
    };

    render() {
      if (!this.isRequestCompleted()) {
        return null;
      }

      if (this.isFeatureEnabled()) {
        return <WrappedComponent {...this.props} />;
      } else if (FallbackComponent) {
        return <FallbackComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  WithFeatureFlag.displayName = `WithFeatureFlag(${getDisplayName(WrappedComponent, FallbackComponent)})`;

  return WithFeatureFlag;
}

function safeComponentName(Component) {
  return Component.displayName || Component.name || 'Component';
}

function getDisplayName(WrappedComponent, FallbackComponent) {
  if (FallbackComponent) {
    return `${safeComponentName(WrappedComponent)}|${safeComponentName(FallbackComponent)}`;
  } else {
    return safeComponentName(WrappedComponent);
  }
}

export default withFeatureFlag;
