import React from 'react';
import PropTypes from 'prop-types';

import AuthorFilter from './AuthorFilter';
import DepartmentFilter from './DepartmentFilter';

import FilterSet from 'components/Explorer/models/filter_set';
import SearchTerm from 'models/SearchTerm';

class InstitutionSearchFields extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    initialSearchTerm: PropTypes.instanceOf(SearchTerm).isRequired
  };

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field-group InstitutionSearchFields">
        <h3 className="Explorer-AdvancedSearch-field-group-title">
          {I18n.t('Explorer.AdvancedSearch.groups.institution')}
        </h3>
        <AuthorFilter filterSet={this.props.filterSet} initialSearch={this.props.initialSearchTerm.termForAuthor()} />
        <DepartmentFilter
          filterSet={this.props.filterSet}
          initialSearch={this.props.initialSearchTerm.termForDepartment()}
        />
      </div>
    );
  }
}

export default InstitutionSearchFields;
