import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';

import AutocompleteField from '../../AutocompleteField';
import HelpLink from '../HelpLink';

class AffiliationFilter extends React.Component {
  // Setup
  ////////////////////////////////////////////////////////////////////////////

  query = null;
  ANALYTICS_FIELD_NAME = 'Affiliation';

  state = {
    loading: true,
    affiliations: []
  };

  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    initialSearch: PropTypes.string
  };

  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    if (this.gridIDs.length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  ////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field AffiliationFilter">
        {this.label}
        {this.state.loading ? this.loadingMessage : this.field}
      </div>
    );
  }

  get loadingMessage() {
    return <div>{I18n.t('Explorer.AdvancedSearch.fields.affiliations.loading')}</div>;
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.affiliations.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.affiliations.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get field() {
    return (
      <AutocompleteField
        name="affiliations"
        id="affiliations"
        type="affiliations"
        initialValue={this.props.initialSearch}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.affiliations.placeholder')}
        onChangeItems={this.updateSelectedAffiliations}
        initiallySelected={this.state.affiliations}
        ref={this.getFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  // Data
  ////////////////////////////////////////////////////////////////////////////

  updateSelectedAffiliations = (affiliations) => {
    this.props.filterSet.set(
      'affiliations[]',
      affiliations.map((a) => a.id)
    );
  };

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.affiliations();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, affiliations: response.body });
      } else {
        this.setState({ loading: false, affiliations: [] });
      }
    });
  };

  get gridIDs() {
    return this.props.filterSet.get('affiliations[]').map((grid_id) => ({ id: grid_id }));
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };

  componentDidUpdate = () => {
    if (!this.fieldRef) return;
    if (!this.gridIDs.length) this.fieldRef.clearAll();
  };
}

export default withRouter(AffiliationFilter);
