import './styles';

import React from 'react';
import PropTypes from 'prop-types';

import withRouter from 'components/withRouter';

import NumberHelper from 'util/number_helper';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';
import Editor from './Editor';
import HelpLink from '../../HelpLink';

class PubmedFilter extends React.Component {
  ANALYTICS_DIALOGUE_NAME = 'PubMed query';

  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static propTypes = {
    history: PropTypes.object,
    filterSet: PropTypes.instanceOf(FilterSet).isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showEditor: false,
      pubmedSearch: null,
      loading: true
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    if (this.searchID) {
      this.loadPubmedSearch();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  componentDidUpdate = () => {
    if (this.state.pubmedSearch && !this.searchID) this.setState({ pubmedSearch: null });
  };

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field-container PubmedFilter">
        {this.label}
        <fieldset>
          <legend>{I18n.t('Explorer.AdvancedSearch.fields.pubmed.title')}</legend>
          {this.state.loading ? this.loadingMessage : this.content}
        </fieldset>
        {this.editor}
      </div>
    );
  }

  get loadingMessage() {
    return (
      <div className="PubmedFilter-loading-message">{I18n.t('Explorer.AdvancedSearch.fields.pubmed.loading')}</div>
    );
  }

  get content() {
    return this.state.pubmedSearch ? this.editSearchContent : this.createSearchContent;
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.pubmed.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.pubmed.description')}
          analyticsFieldName="Pubmed Query"
        />
      </label>
    );
  }

  get createSearchContent() {
    return (
      <div className="PubmedFilter-content">
        <div className="PubmedFilter-search-create-buttons">
          <button
            type="button"
            className="PubmedFilter-add-button"
            onClick={this.onClickCreate}
            ref={(ref) => (this.newButton = ref)}
          >
            {I18n.t('Explorer.AdvancedSearch.fields.pubmed.add')}
          </button>
        </div>
      </div>
    );
  }

  get editSearchContent() {
    return (
      <div className="PubmedFilter-content">
        <div className="PubmedFilter-search-description">{this.descriptionString}</div>
        <div className="PubmedFilter-search-edit-buttons">
          <button
            type="button"
            className="PubmedFilter-edit-button"
            onClick={this.onClickEdit}
            ref={(ref) => (this.editButton = ref)}
          >
            {I18n.t('Explorer.AdvancedSearch.fields.pubmed.edit')}
          </button>
          <button type="button" className="PubmedFilter-clear-button" onClick={this.onClickClear}>
            {I18n.t('Explorer.AdvancedSearch.fields.pubmed.clear')}
          </button>
        </div>
      </div>
    );
  }

  get editor() {
    if (!this.state.showEditor) return;

    return (
      <Editor
        pubmedSearch={this.state.pubmedSearch}
        onCancel={this.onCancelEdit}
        onSave={this.onSaveEdit}
        analyticsDialogueName={this.ANALYTICS_DIALOGUE_NAME}
      />
    );
  }

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  get descriptionString() {
    if (this.state.pubmedSearch.count === 0) {
      return this.emptyDescription;
    } else if (this.state.pubmedSearch.total > this.state.pubmedSearch.count) {
      return this.tooManyDescription;
    } else {
      return this.basicDescription;
    }
  }

  get emptyDescription() {
    return I18n.t('Explorer.AdvancedSearch.fields.pubmed.empty');
  }

  get tooManyDescription() {
    return I18n.htmlSafe('Explorer.AdvancedSearch.fields.pubmed.too_many', {
      count: NumberHelper.formatNumberWithDelimiter(this.state.pubmedSearch.count),
      total: NumberHelper.formatNumberWithDelimiter(this.state.pubmedSearch.total)
    });
  }

  get basicDescription() {
    return I18n.t('Explorer.AdvancedSearch.fields.pubmed.count', {
      count: this.state.pubmedSearch.count,
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.pubmedSearch.count)
    });
  }

  // Events
  //////////////////////////////////////////////////////////////////////////////

  onClickCreate = () => {
    this.setState({ showEditor: true });
  };

  onClickEdit = () => {
    this.setState({ showEditor: true });
  };

  onClickClear = () => {
    this.props.filterSet.clear('pubmed_search_id');
    this.setState({ pubmedSearch: null }, () => this.refocus());

    Analytics.trackEvent('Advanced Search: Modal Dialogue Cleared', {
      dialogue_name: this.ANALYTICS_DIALOGUE_NAME
    });
  };

  onCancelEdit = () => {
    this.setState({ showEditor: false }, () => this.refocus());
  };

  onSaveEdit = (pubmedSearch) => {
    this.props.filterSet.set('pubmed_search_id', pubmedSearch.id);

    this.setState(
      {
        pubmedSearch: pubmedSearch,
        showEditor: false
      },
      () => this.refocus()
    );
  };

  refocus = () => {
    this.state.pubmedSearch ? this.editButton.focus() : this.newButton.focus();
  };

  // Data handling
  //////////////////////////////////////////////////////////////////////////////

  loadPubmedSearch = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.pubmedSearch();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, pubmedSearch: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  get searchID() {
    return this.props.filterSet.get('pubmed_search_id');
  }
}

export default withRouter(PubmedFilter);
