import {
  compact,
  concat,
  each,
  flatten,
  flow,
  includes,
  isArray,
  map,
  mapValues,
  mergeWith,
  some,
  times,
  uniq,
  uniqBy
} from 'lodash-es';

import Identifiers from '../../util/identifiers';
import { normalize } from '../../util/normalize';

// The ResearchOutput class represents a single research output that has been
// extracted from a user dataset. It contains a data field, which is an object
// mapping field types to values for this research output, and a list of row
// indexes from the original CSV file which were used to build this research
// output.
class ResearchOutput {
  constructor(data, rowIndex) {
    this.data = data;
    this.errors = [];

    // Whenever we create a new research output, the action will be taken as a
    // result of data in a single row of the input CSV. We will wrap this in an
    // array, so that we can easily append additional row indexes as they are
    // seen in the input.
    this.rowIndexes = [rowIndex];
  }

  // Returns a boolean indicating if this research output can have the supplied
  // data merged into it. It will return true if there are no overlapping
  // identifiers, or if the identifiers which overlap are identical.
  canMergeWith(newData) {
    let hasConflict = some(newData, (value, key) => {
      return this.data[key] && !isArray(this.data[key]) && this.data[key] != value;
    });

    return !hasConflict;
  }

  // Merges the supplied data into this research output. Existing values will
  // take precendence – although it is worth noting that both existing and
  // new values should always be the same if we are merging two research outputs
  // without conflicts.
  merge(newData, newIndex) {
    // Add details of the row index that this additional data was derived from
    this.rowIndexes.push(newIndex);

    mergeWith(this.data, newData, (currentValue, nextValue) => {
      // If the current value of this field is an array, then we will append the
      // contents of the new array to the existing one and remove duplicates.
      if (isArray(currentValue)) {
        return flow(compact, uniq)(currentValue.concat(nextValue));
      } else {
        return currentValue || nextValue;
      }
    });
  }

  // Adds the supplied string to the list of errors on this research output.
  // These will later be used to generate a report.
  addError(error) {
    this.errors.push(error);
  }

  get valid() {
    return this.errors.length === 0;
  }

  get hasAnyIdentifiers() {
    return some(Identifiers.TYPES, (type) => this.data[type]);
  }

  asJSON() {
    return this.data;
  }
}

// The ResearchOutputsBuilder consumes columns extracted from the user's data
// set and converts them into a disambiguated, merged, normalized list of
// research outputs, based on the types they have selected for each column.
class ResearchOutputsBuilder {
  constructor(columns) {
    this.columns = columns;
    this.researchOutputs = [];

    // The research outputs identifier map is used to allow us to quickly find
    // out if there is a matching output for a given identifier without having
    // to search all existing research outputs on each request.
    this.researchOutputsByIdentifier = {};

    // The affiliation map keeps track of the relationship between authors and
    // departments as we build research outputs.
    this.affiliationMap = {};

    this.populateResearchOutputs();
  }

  // Called in the constructor to actually build the research outputs.
  populateResearchOutputs() {
    let rowCount = this.columns[0].rows.length;

    // Get details of the data in each field for each row in the input CSV by
    // applying the user-specified column mapping to the input data.
    let rowData = times(rowCount, (index) => this.fieldDataForRowIndex(index));

    // Add a mapping between all extracted authors to all extracted departments.
    // We build the map at this stage because we want to preserve the row-wise
    // association of authors and departments, which will be lost after we
    // create or merge research outputs.
    this.affiliationMap = this.buildAffiliationMap(rowData);

    // For each row we extracted from the source data, determine if we need to
    // create a new research output or merge the data into an existing one.
    each(rowData, (row, index) => {
      // Look at the research outputs that we have already extracted from the
      // CSV, and determine if there are any which have identifiers which
      // overlap with this one.
      let existingResearchOutputs = this.existingResearchOutputsFor(row);

      // If there are no existing research outputs with any identifiers in
      // common, then this row represents a new research output to import.
      if (existingResearchOutputs.length === 0) {
        this.addNewResearchOutput(row, index);

        // If there is a single matching research output with identifiers in
        // common with this one, then they may both refer to the same research
        // research output. Check if they can be merged (i.e. do not conflict with
        // each other) – if they can be, then go ahead and do so.
      } else if (existingResearchOutputs.length === 1 && existingResearchOutputs[0].canMergeWith(row)) {
        this.mergeDataIntoExistingResearchOutput(row, existingResearchOutputs[0], index);

        // If there is more than one research output with an identifier in common
        // then we have encountered conflicting data and can't determine what
        // data we should import. Mark all rows that conflict with errors, so that
        // we can skip them and provide some kind of report later.
      } else {
        let newOutput = this.addNewResearchOutput(row, index);
        this.addConflictErrorsToResearchOutputs(concat(existingResearchOutputs, newOutput));
      }
    });
  }

  // Create a new research output for the given data from the given index, and
  // add it to the identifier map.
  addNewResearchOutput(data, index) {
    let output = new ResearchOutput(data, index);
    this.researchOutputs.push(output);

    if (output.hasAnyIdentifiers) {
      this.updateIdentifierMap(output);
    } else {
      this.addNoIdentifiersError(output);
    }

    return output;
  }

  // Merge data from a new row into the supplied existing research output,
  // adding it to the identifier map again.
  mergeDataIntoExistingResearchOutput(data, existingResearchOutput, index) {
    existingResearchOutput.merge(data, index);
    this.updateIdentifierMap(existingResearchOutput);

    return existingResearchOutput;
  }

  // Add an error message containing information about conflicting rows to the
  // supplied list of outputs.
  addConflictErrorsToResearchOutputs(outputs) {
    let erroredRowIndices = flow(
      (o) => map(o, (r) => r.rowIndexes),
      flatten,
      (o) => map(o, (r) => r + 2)
    )(outputs);
    let erroredRowsString;

    if (erroredRowIndices.length === 2) {
      erroredRowsString = erroredRowIndices.join(' and ');
    } else {
      let lastIndex = erroredRowIndices.pop();
      erroredRowIndices.push(`and ${lastIndex}`);
      erroredRowsString = erroredRowIndices.join(', ');
    }

    let error = I18n.t('dataset_upload.outputs_check.errors.conflict', {
      rows: erroredRowsString
    });

    outputs.forEach((r) => r.addError(error));
  }

  // Add an error message indicating no valid identifiers to the supplied
  // output
  addNoIdentifiersError(output) {
    let error = I18n.t('dataset_upload.outputs_check.errors.no_identifiers');

    output.addError(error);
  }

  // Use the supplied output to update the identifier map that we use for
  // quickly determining if any research outputs exist with an existing
  // identifier.
  updateIdentifierMap(output) {
    each(output.data, (value, fieldType) => {
      // We only apply this logic to fields which are identifiers – we don't
      // want to merge two research outputs just because they have the same
      // author!
      if (!includes(Identifiers.TYPES, fieldType)) return;

      // If there are no existing mappings for this type of identifier, create
      // a new mapping object.
      if (!this.researchOutputsByIdentifier[fieldType]) {
        this.researchOutputsByIdentifier[fieldType] = {};
      }

      // If there are no existing mappings for this identifier, create a new
      // list that we can append to.
      if (!this.researchOutputsByIdentifier[fieldType][value]) {
        this.researchOutputsByIdentifier[fieldType][value] = [];
      }

      // Add this output to the mapping list unless it is already there.
      let newList = uniq(concat(this.researchOutputsByIdentifier[fieldType][value], output));
      this.researchOutputsByIdentifier[fieldType][value] = newList;
    });
  }

  // Build a map of authors to affiliated departments. This is done using the
  // raw, normalized row data of the uploaded file, because we need to retain
  // the original mapping between authors and departments (this would be lost
  // if we built the map *after* the research outputs had been merged.)
  buildAffiliationMap(rowData) {
    let affiliations = {};

    rowData.forEach((row) => {
      if (!row.authors || !row.departments) return;

      row.authors.forEach((author) => {
        if (!affiliations[author]) {
          affiliations[author] = [];
        }

        affiliations[author].push(row.departments);
      });
    });

    return mapValues(affiliations, (departments) => flow(flatten, compact, (d) => uniqBy(d, normalize))(departments));
  }

  // Find any research outputs that we have already encountered that have
  // any identifiers in common with the supplied set of identifiers.
  existingResearchOutputsFor(data) {
    let matchingOutputs = [];

    each(data, (value, fieldType) => {
      let matchingByFieldType = this.researchOutputsByIdentifier[fieldType] || {};
      let matchingByValue = matchingByFieldType[value] || [];
      matchingOutputs.push(matchingByValue);
    });

    return flow(flatten, uniq)(matchingOutputs);
  }

  // Build a data object for the specified index the the originally-supplied CSV
  // file, by walking through every column and fetching the appropriate row.
  fieldDataForRowIndex(index) {
    let data = {};

    this.columns.forEach((column) => {
      // Do not include data from columns which do not have a field type set (
      // i.e. are not going to be imported)
      if (!column.fieldType) return;

      // Get the normalized value of the content of the row/column pair, skpping
      // it if the normalized value is empty.
      let value = column.normalizedRow(index);
      if (!value || value.length === 0) return;

      // If this column contains identifier data, then we should only have one
      // occurance so we can just directly set the value. Otherwise, there may
      // have been a previous column that contained the same data type – we may
      // need to merge!
      if (column.isIdentifierColumn) {
        data[column.fieldType] = value;
      } else {
        data[column.fieldType] = concat(data[column.fieldType] || [], value);
      }
    });

    return data;
  }
}

export { ResearchOutput, ResearchOutputsBuilder };
