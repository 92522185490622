import React from 'react';

import LoadingSpinner from 'components/LoadingSpinner';

class LoadingScreen extends React.Component {
  render() {
    return (
      <div className="DatasetUploadPopover-LoadingScreen">
        <LoadingSpinner message={this.props.children} />
      </div>
    );
  }
}

export { LoadingScreen };
