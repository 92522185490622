import React from 'react';

class IntroPanel extends React.Component {
  render() {
    return (
      <div className="DatasetUploadPopover-IntroPanel DatasetUploadPopover-ContentPanel">
        <div className="DatasetUploadPopover-documentation-panel">
          <div className="header-icon">
            <h2>Create your data implementation</h2>
            <a
              className="help"
              href="https://help.altmetric.com/support/solutions/articles/6000240718-csv-uploader-tool-preparing-the-data"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help
            </a>
          </div>
          <p>
            This wizard will take you through the process of creating a data implementation for your instance of
            Altmetric Explorer for Institutions.
          </p>
          <p>
            First, you will need to upload a CSV spreadsheet file that lists your institution's groups or departments.
            Afterwards, you will need to upload another CSV spreadsheet that contains a list of research outputs (e.g.,
            articles, books, datasets, etc.) to include in your data implementation and associate with your institution.
          </p>
          <p>
            For details about how dataset uploads work, example spreadsheets, or additional help to create your dataset,{' '}
            <a
              href="https://help.altmetric.com/support/solutions/articles/6000240718-csv-uploader-tool-preparing-the-data"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here to read our support documentation.
            </a>
          </p>
          <p>Click the "next" button below to continue.</p>
        </div>
      </div>
    );
  }
}

export { IntroPanel };
