import dayjs from 'util/date';

export function timeframeMinimumDate(timeframe) {
  let result;

  switch (timeframe) {
    case '1d':
      result = dayjs().subtract(1, 'days');
      break;
    case '3d':
      result = dayjs().subtract(3, 'days');
      break;
    case '1w':
      result = dayjs().subtract(1, 'week');
      break;
    case '1m':
      result = dayjs().subtract(1, 'month');
      break;
    case '3m':
      result = dayjs().subtract(3, 'months');
      break;
    case '6m':
      result = dayjs().subtract(6, 'months');
      break;
    case '1y':
      result = dayjs().subtract(1, 'year');
      break;
    default:
      return null;
  }

  return result.format('YYYY-MM-DD');
}
