import { defer, delay } from 'lodash-es';
import $ from 'jquery';
import fetch from 'util/fetch';
import ClipboardJS from 'clipboard';

const setup = function () {
  $(document).on('submit', '.institution-form form', function () {
    let newInstitution = $(this).find('option:selected').text();
    Analytics.trackEvent('changeInstitution', {
      institution: newInstitution
    });
  });

  $(document).on('submit', '.account-settings-form form', () => Analytics.trackEvent('changePassword'));

  $(document).on('click', '.api-keys-form .api-documentation-description a', () => {
    Analytics.trackEvent('viewAPIDocumentation');
  });

  window.addEventListener('DOMContentLoaded', setupClipboardHandler);
  window.addEventListener('DOMContentLoaded', setupResetKeys);
};

const setupClipboardHandler = function () {
  let $form = $('.api-keys-form');
  if (!$form.length) return;

  $form.find('.copy-button').each((_index, button) => {
    new ClipboardJS(button)
      .on('success', () => displayTooltip($(button), I18n.t('settings.api.copied')))
      .on('error', () => displayTooltip($(button), I18n.t('settings.api.manual_copy')));
  });
};

const displayTooltip = function ($button, text) {
  let $tooltip = $("<div class='mini-tooltip'/>").text(text);
  $button.before($tooltip);
  defer(() => $tooltip.addClass('visible'));

  delay(() => {
    $tooltip.removeClass('visible');
    delay(() => $tooltip.remove(), 1000);
  }, 1000);
};

const setupResetKeys = () => {
  document.querySelector('.revoke-button a[data-confirm]')?.addEventListener('click', (e) => {
    e.preventDefault();
    const confirmMessage = e.target.dataset.confirm;

    if (confirm(confirmMessage)) {
      Analytics.trackEvent('revokeAPIKeys');

      fetch(e.target.href, { method: 'PATCH' }).then((res) => {
        if (res.ok && res.redirected) {
          window.location.replace(res.url);
        }
      });
    }
  });
};

export { setup };
