import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';

class DateField extends React.Component {
  static propTypes = {
    fieldID: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    label: PropTypes.string,
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    placeholder: PropTypes.string,
    onChangeCallback: PropTypes.func,
    minimumDate: PropTypes.string,
    maximumDate: PropTypes.string,
    minimumSelectableDate: PropTypes.string,
    analyticsEventPrefix: PropTypes.string,
    analyticsFieldName: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = { selectedDate: this.value };

    this.fieldSelected = false;
    this.previousValue = this.value;
  }

  componentDidUpdate(prevProps) {
    const prevSelectedDate = prevProps.filterSet.get(this.props.fieldName) || '';

    if (this.minimumDateBeyondLimit(prevProps)) {
      this.setDate(this.props.minimumDate);
    } else if (this.maximumDateBeyondLimit(prevProps)) {
      this.setDate(this.props.maximumDate);
    } else if (this.value !== prevSelectedDate && this.value !== this.state.selectedDate) {
      this.setDate(this.value);
    }
  }

  render() {
    return (
      <div className="Explorer-DateField">
        {this.label}
        <input
          type="date"
          id={this.props.fieldID}
          value={this.state.selectedDate}
          onBlur={this.handleBlur}
          onClick={this.handleClick}
          onChange={this.onChange}
          aria-label={this.props.label}
          min={this.props.minimumSelectableDate}
        />
      </div>
    );
  }

  get label() {
    if (!this.props.label) return;

    return <label htmlFor={this.props.fieldID}>{this.props.label}</label>;
  }

  clear = () => {
    this.props.filterSet.clear(this.props.fieldName);
    this.setState({ selectedDate: '' });
  };

  handleClick = () => {
    if (this.fieldSelected) return;

    this.trackEvent('Selected');
    this.fieldSelected = true;
  };

  handleBlur = () => {
    this.fieldSelected = false;

    if (this.valueChanged) {
      this.trackDateChange(this.value);
      this.previousValue = this.value;
      return;
    }

    if (this.manuallyCleared) {
      this.trackEvent('Manually Cleared');
      this.previousValue = '';
      return;
    }
  };

  onChange = (event) => {
    const newDate = event.target.value;
    if (this.props.onChangeCallback) this.props.onChangeCallback(newDate);

    this.setDate(newDate);
  };

  minimumDateBeyondLimit = (prevProps) => {
    const currentValue = new Date(this.value);
    if (this.props.minimumDate && this.props.minimumDate !== prevProps.minimumDate) {
      const min = new Date(this.props.minimumDate);
      return currentValue.getTime() < min.getTime();
    }
    return false;
  };

  maximumDateBeyondLimit = (prevProps) => {
    const currentValue = new Date(this.value);
    if (this.props.maximumDate && this.props.maximumDate !== prevProps.maximumDate) {
      const max = new Date(this.props.maximumDate);
      return currentValue.getTime() > max.getTime();
    }
    return false;
  };

  setDate = (dateText) => {
    this.props.filterSet.set(this.props.fieldName, dateText);
    this.setState({ selectedDate: dateText });
  };

  trackDateChange(dateString) {
    if (!dateString) return;
    if (!this.trackingEnabled) return;

    const date = new Date(dateString);
    if (isNaN(date.getTime())) return;

    Analytics.trackEvent(`${this.props.analyticsEventPrefix} Date Selected`, {
      field_name: this.props.analyticsFieldName,
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    });
  }

  trackEvent(eventName) {
    if (!this.trackingEnabled) return;

    Analytics.trackEvent(`${this.props.analyticsEventPrefix} ${eventName}`, {
      field_name: this.props.analyticsFieldName
    });
  }

  get value() {
    return this.props.filterSet.get(this.props.fieldName) || '';
  }

  get trackingEnabled() {
    return !!(this.props.analyticsEventPrefix && this.props.analyticsFieldName);
  }

  get valueChanged() {
    return this.value && this.value !== this.previousValue;
  }

  get manuallyCleared() {
    return this.value === '' && this.value !== this.previousValue;
  }
}

export default DateField;
