import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import withLocation from 'components/withLocation';
import FooterLinks from 'components/FooterLinks';

import Highlights from './Highlights';
import Outputs from './Outputs';
import Timeline from './Timeline';
import Demographics from './Demographics';
import Mentions from './Mentions';
import Journals from './Journals';
import MentionSources from './MentionSources';

class ContentPanel extends React.Component {
  static propTypes = {
    onRouteChangeUpdate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  render() {
    return window.current_user.librarian ? this.librarianContent : this.allContent;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.onRouteChangeUpdate();
    }
  }

  get allContent() {
    return (
      <main className="Explorer-ContentPanel" id="main-content" tabIndex="-1">
        <Route path="/highlights" component={Highlights} />
        <Route path="/outputs" component={Outputs} />
        <Route path="/timeline" component={Timeline} />
        <Route path="/demographics" component={Demographics} />
        <Route path="/mentions" component={Mentions} />
        <Route path="/journals" component={Journals} />
        <Route path="/mention_sources" component={MentionSources} />
        {this.footer}
      </main>
    );
  }

  get librarianContent() {
    return (
      <main className="Explorer-ContentPanel" id="main-content" tabIndex="-1">
        <Outputs />
        {this.footer}
      </main>
    );
  }

  get footer() {
    return (
      <footer>
        <div className="Explorer-footer">
          <p>
            {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
            <a
              className="info"
              href="https://help.altmetric.com/support/solutions/articles/6000199416-how-often-does-altmetric-explorer-data-get-updated-"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            {I18n.htmlSafe('Explorer.snapshot_info', {
              lastSnapshot: window.current_user.last_snapshot,
              nextSnapshot: window.current_user.next_snapshot,
              dimensionsUpdate: '1 day ago'
            })}
          </p>
          <p>{I18n.htmlSafe('Explorer.twitter_info')}</p>
        </div>
        <FooterLinks />
      </footer>
    );
  }
}

export default withLocation(ContentPanel);
