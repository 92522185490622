import { map } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';

class SelectField extends React.Component {
  static propTypes = {
    fieldID: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    collection: PropTypes.object.isRequired,
    emptyLabel: PropTypes.string,
    onChangeCallback: PropTypes.func,
    analyticsEventPrefix: PropTypes.string,
    analyticsFieldName: PropTypes.string
  };

  render() {
    return (
      <div className="Explorer-SelectField">
        <select
          id={this.props.fieldID}
          name={this.props.fieldName}
          value={this.value}
          onChange={this.onChange}
          onClick={this.onClick}
        >
          {this.emptyOption}
          {map(this.props.collection, (value, key) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
      </div>
    );
  }

  get value() {
    return this.props.filterSet.get(this.props.fieldName) || '';
  }

  get emptyOption() {
    if (!this.props.emptyLabel) return;

    return (
      <option key="emptyLabel" value={''}>
        {this.props.emptyLabel}
      </option>
    );
  }

  get trackingEnabled() {
    return !!(this.props.analyticsEventPrefix && this.props.analyticsFieldName);
  }

  clear = () => {
    this.props.filterSet.clear(this.props.fieldName);
    this.forceUpdate();
  };

  onChange = (e) => {
    if (this.props.onChangeCallback) this.props.onChangeCallback(e.target.value);

    this.props.filterSet.set(this.props.fieldName, e.target.value);
    this.trackEvent('Item Selected', e.target.value);
    this.forceUpdate();
  };

  onClick = () => {
    this.trackEvent('Selected');
  };

  trackEvent(eventName, value = null) {
    if (!this.trackingEnabled) {
      return;
    }

    Analytics.trackEvent(`${this.props.analyticsEventPrefix} ${eventName}`, {
      field_name: this.props.analyticsFieldName,
      ...(value && { value: value })
    });
  }
}

export default SelectField;
