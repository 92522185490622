import React, { useEffect } from 'react';

import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';

import DataPoint from './DataPoint';
import GlobalFilter from './GlobalFilter';
import Pagination from './Pagination';
import ColumnHeader from './ColumnHeader';
import RowHeader from './RowHeader';

const AnalysisTable = ({ columns, data, goToJournal, resultsPerPage }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    state,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    pageCount,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);

  useEffect(() => {
    setPageSize(resultsPerPage);
  }, [setPageSize]);

  const pagination =
    data.length > resultsPerPage ? (
      <Pagination
        {...{
          pageSize,
          pageIndex,
          pageCount,
          canPreviousPage,
          canNextPage,
          gotoPage,
          previousPage,
          nextPage
        }}
      />
    ) : (
      <div className="Explorer-Journals-Pagination-Container"></div>
    );

  return (
    <div>
      <GlobalFilter
        placeholder="e.g. Physics Letters, arXiv, figshare"
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />

      {pagination}

      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={`header-group-${index}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <ColumnHeader key={`column-${index}`} column={column} />
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            const [rowHeader, ...values] = row.cells;

            return (
              <tr key={`row-${index}`} {...row.getRowProps()}>
                <RowHeader
                  key="0"
                  attributes={rowHeader.getCellProps()}
                  value={rowHeader.render('Cell')}
                  onClick={() => goToJournal(rowHeader.row.original.source.id)}
                  mentioned_research_outputs={row.original.mentioned_research_outputs}
                />
                {values.map((cell, index) => (
                  <DataPoint key={index + 1} attributes={cell.getCellProps()} value={cell.render('Cell')} />
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {pagination}
    </div>
  );
};

export default AnalysisTable;
