import React from 'react';
import ReactDOM from 'react-dom';

import ApplicationLoader from '../components/ApplicationLoader';

const setup = function () {
  window.addEventListener('DOMContentLoaded', showReactApplication);
};

const showReactApplication = function () {
  const appComponentWrapper = document.getElementById('application-wrapper');
  if (!appComponentWrapper) return;

  ReactDOM.render(React.createElement(ApplicationLoader, appComponentWrapper.dataset), appComponentWrapper);
};

export { setup };
