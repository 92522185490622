import './styles';

import React from 'react';
import { truncate } from 'lodash-es';

import dayjs from 'util/date';
import PropTypes from 'prop-types';

import OutputsList from 'components/Explorer/OutputsList';

const TITLE_LENGTH = 70; // Characters
const VISIBLE_OUTPUTS_COUNT = 2;

class NewsHighlight extends React.Component {
  state = {
    visible: false
  };

  static propTypes = {
    highlight: PropTypes.object
  };

  toggleOutputsVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };

  toggleOutputsVisibilityButtonText = (outputsCount) => {
    if (this.state.visible) {
      return I18n.t('Explorer.Highlights.LatestMentions.show_fewer');
    } else {
      return I18n.t('Explorer.Highlights.LatestMentions.cites_more', {
        count: Math.max(outputsCount - VISIBLE_OUTPUTS_COUNT, 0)
      });
    }
  };

  render() {
    const title = truncate(this.props.highlight.title, {
      length: TITLE_LENGTH,
      separator: /\W/
    });
    const date = dayjs(this.props.highlight.posted_at).utc().format('D MMM YYYY');
    const outputsCount = this.props.highlight.outputs.length;

    return (
      <tr key={`${this.props.highlight.id}-highlight`}>
        <td className="highlight-logo">
          <img src={this.props.highlight.source.logo} alt={this.props.highlight.source.title} />
        </td>
        <td className="highlight-description">
          <a className="highlight-jump" href={this.props.highlight.url} target="_blank" rel="noreferrer">
            <div className="highlight-title">{title}</div>
            <div className="highlight-meta">
              {I18n.htmlSafe('Explorer.Highlights.NewsHighlights.meta', {
                publisher: this.props.highlight.source.title,
                date: date
              })}
            </div>
          </a>
          <div className="outputs-table-title">
            {I18n.t('Explorer.Highlights.NewsHighlights.cites', {
              count: outputsCount
            })}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events -- [TODO] */}
            <span onClick={this.toggleOutputsVisibility} className="truncated-toggle">
              {this.toggleOutputsVisibilityButtonText(outputsCount)}
            </span>
            :
          </div>
          <OutputsList
            post={this.props.highlight}
            outputs={this.props.highlight.outputs}
            visible={this.state.visible}
            visibleOutputsCount={VISIBLE_OUTPUTS_COUNT}
          />
        </td>
      </tr>
    );
  }
}

export default NewsHighlight;
