import './styles';

import { includes, truncate } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'util/date';
import Striptags from 'striptags';
import ImageWithFallback from 'components/ImageWithFallback';
import LinkIf from 'components/Explorer/LinkIf';
import Outputs from './Outputs';
import ResearchOutput from 'components/Explorer/models/research_output';

class Post extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  outputs = [];

  static propTypes = {
    history: PropTypes.object,
    post: PropTypes.object.isRequired,
    first: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.outputs = props.post.outputs.map((output) => new ResearchOutput(output));
    this.date = dayjs(props.post.postedAt).utc();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className={`Mentions-Post ${this.props.post.postType}`}>
        <div className={`post-type-icon ${this.props.post.postType}`}>
          <div className="icon"></div>
        </div>
        <div className="timestamp">
          {this.dateHeader}
          {this.timestamp}
        </div>
        {this.content}
      </div>
    );
  }

  get dateHeader() {
    if (!this.props.first) return;

    return <div className="date">{this.date.format('YYYY-MM-DD')}</div>;
  }

  get timestamp() {
    const formattedDate = this.date.format('YYYY-MM-DDTHH:mm:ssZ');
    const formattedTime = this.date.format('h:mm A UTC');

    return <time dateTime={formattedDate}>{formattedTime}</time>;
  }

  get content() {
    const extraClasses = this.props.post.thumbnail ? '' : 'missing-image';
    return (
      <div className="content">
        <div className="content-padding">
          <div className="post-content">
            <LinkIf condition={this.hasURL} url={this.props.post.url}>
              <div className={`thumbnail-container ${extraClasses}`}>
                {this.thumbnail}
                {this.thumbnailBadge}
              </div>
              <div className="inner-content">
                {this.title}
                <div className="source">{this.sourceDescription}</div>
                <div className="abstract">
                  {truncate(Striptags(this.props.post.body), {
                    length: 256,
                    separator: /\W/
                  })}
                </div>
              </div>
            </LinkIf>
          </div>
          <div className="output-content">
            <Outputs outputs={this.outputs} />
          </div>
        </div>
      </div>
    );
  }

  get thumbnail() {
    if (!this.props.post.thumbnail) return;

    return <ImageWithFallback src={this.props.post.thumbnail} alt={this.sourceDescription} />;
  }

  get thumbnailBadge() {
    if (!this.isRetweet()) return;

    return (
      <div className="retweet-badge">
        <div className="icon" />
      </div>
    );
  }

  get title() {
    if (!this.props.post.title || !this.props.post.title.length) return;

    return <div className="title">{this.props.post.title}</div>;
  }

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  get sourceDescription() {
    return this.sourceDescriptionWithNameAndScreeName || this.sourceDescriptionWithName || this.basicDescription;
  }

  get sourceDescriptionWithNameAndScreeName() {
    const sources = ['tweet', 'rdt'];
    if (!includes(sources, this.props.post.postType)) return;
    if (!this.hasProfileNameAndScreenName()) return;

    const postType = this.isRetweet() ? 'retweet' : this.props.post.postType;
    return I18n.htmlSafe(postType, {
      scope: 'Explorer.Mentions.type_descriptions',
      screen_name: this.props.post.profileScreenName,
      name: this.props.post.profileName
    });
  }

  get sourceDescriptionWithName() {
    const sources = ['msm', 'blog', 'policy', 'weibo', 'fbwall', 'gplus'];
    if (!includes(sources, this.props.post.postType)) return;
    if (!this.hasProfileName()) return;

    return I18n.htmlSafe(this.props.post.postType, {
      scope: 'Explorer.Mentions.type_descriptions',
      name: this.props.post.profileName
    });
  }

  get basicDescription() {
    const sources = ['peer_review', 'wikipedia', 'linkedin', 'pinterest', 'qna', 'video', 'patent'];
    if (!includes(sources, this.props.post.postType)) return;

    return I18n.htmlSafe(this.props.post.postType, {
      scope: 'Explorer.Mentions.type_descriptions'
    });
  }

  get hasURL() {
    return this.props.post.url && this.props.post.url.length > 0;
  }

  hasProfileNameAndScreenName() {
    return this.hasProfileName() && this.props.post.profileScreenName && this.props.post.profileScreenName.length;
  }

  hasProfileName() {
    return this.props.post.profileName && this.props.post.profileName.length;
  }

  isRetweet() {
    return this.props.post.postType == 'tweet' && !this.props.post.originalPost;
  }
}

export default Post;
