import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';

import Export from 'components/Explorer/Export';
import SaveSearch from 'components/Explorer/SaveSearch';
import SummaryBlock from 'components/Explorer/ContentPanel/SummaryBlock';

import helpFor from 'util/help.js';

class Summary extends React.Component {
  query = null;

  static propTypes = {
    history: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      totalJournals: 0
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  render() {
    return (
      <SummaryBlock>
        {this.state.loading ? (
          I18n.t('Explorer.Journals.Summary.loading')
        ) : (
          <>
            <div>{this.content}</div>
            <Export endpoint="journals" resultsCount={this.state.totalJournals} help={helpFor('journals')} />
            <SaveSearch endpoint="journals" analyticsName="Journals" />
          </>
        )}
      </SummaryBlock>
    );
  }

  get loadingMessage() {
    return I18n.t('Explorer.Journals.Summary.loading');
  }

  get content() {
    if (this.state.loadingFailed) {
      return this.errorMessage;
    } else if (this.state.totalJournals === 0) {
      return this.emptyMessage;
    } else {
      return this.summaryMessage;
    }
  }

  get errorMessage() {
    return I18n.t('Explorer.Journals.Summary.error');
  }

  get emptyMessage() {
    return I18n.t('Explorer.Journals.Summary.empty');
  }

  get summaryMessage() {
    return I18n.htmlSafe('Explorer.Journals.Summary.journals', {
      count: this.state.totalJournals,
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.totalJournals)
    });
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.journalAnalysisSummary();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let totalJournals = 0;
      const loadingFailed = err || typeof response.body === 'undefined';
      if (!loadingFailed) {
        totalJournals = response.body.total_journals;
      }

      this.setState({
        loading: false,
        loadingFailed: loadingFailed,
        totalJournals: totalJournals
      });
    });
  };
}

export default withRouter(Summary);
