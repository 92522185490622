import React from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = (props) => {
  const loadingClass = props.hidden ? 'hidden' : 'not-hidden';

  return (
    <div className={`LoadingSpinner ${loadingClass}`}>
      <div className="LoadingSpinner-spinner" />
      <div className="LoadingSpinner-message">{props.message}</div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  hidden: PropTypes.bool,
  message: PropTypes.string
};

export default LoadingSpinner;
