import React from 'react';
import classNames from 'classnames';
import Info from 'icons/info.svg';

import './styles';

const SummaryBlock = ({ children, variant = null, hasWarning = false }) => (
  <div className={classNames('SummaryBlock', { 'SummaryBlock--alt': variant === 'alt' })}>
    <div
      className={classNames('SummaryBlock-IconWrapper', {
        'SummaryBlock-IconWrapper--warning': hasWarning
      })}
    >
      <Info width="24px" height="24px" />
    </div>
    <div className="SummaryBlock-SummaryWrapper">{children}</div>
  </div>
);

export default SummaryBlock;
