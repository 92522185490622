import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash-es';
import { Link } from 'react-router-dom';

import NumberHelper from 'util/number_helper';

import Tabs from './Tabs';

const COLLAPSED_ROWS = 12;

class Table extends React.PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired,
    selectedSource: PropTypes.oneOf(Tabs.TYPES).isRequired,
    locationBuilder: PropTypes.func.isRequired
  };

  state = {
    collapsed: true
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="Explorer-Demographics-Table">
        <table>
          <thead>
            <tr>
              <th>{I18n.t('country', { scope: this.i18npath })}</th>
              <th>{I18n.t('posts', { scope: this.i18npath })}</th>
              <th>{I18n.t('profiles', { scope: this.i18npath })}</th>
            </tr>
          </thead>
          <tbody>{this.rows}</tbody>
        </table>
        {this.state.collapsed ? this.expander : this.collapser}
      </div>
    );
  }

  componentDidUpdate() {
    this.focussedElement?.focus();
  }

  get i18npath() {
    return `Explorer.Demographics.types.${this.props.selectedSource}.table`;
  }

  get rows() {
    return this.data.map((country, index) => {
      return (
        <tr key={country.code} className={country.code == '' ? 'unknown' : null}>
          <td>{this.countryNameOrUnknown(country, index)}</td>
          <td>
            <span className="total">{NumberHelper.formatNumberWithDelimiter(country.posts)}</span>
            <span className="percent">({country.percent_posts}%)</span>
          </td>
          <td>
            <span className="total">{NumberHelper.formatNumberWithDelimiter(country.profiles)}</span>
            <span className="percent">({country.percent_profiles}%)</span>
          </td>
        </tr>
      );
    });
  }

  get data() {
    const data = sortBy(this.props.data, (d) => -d.posts);
    if (this.state.collapsed) {
      return data.slice(0, COLLAPSED_ROWS);
    } else {
      return data;
    }
  }

  get expander() {
    if (!this.hasTooManyRows) return;

    return (
      <button className="expander" onClick={this.expand}>
        {I18n.htmlSafe('more', {
          scope: this.i18npath,
          count: this.props.data.length - COLLAPSED_ROWS
        })}
      </button>
    );
  }

  get collapser() {
    return (
      <button className="collapser" onClick={this.collapse}>
        {I18n.htmlSafe('less', { scope: this.i18npath })}
      </button>
    );
  }

  get hasTooManyRows() {
    return this.props.data.length > COLLAPSED_ROWS;
  }

  countryNameOrUnknown(country, index) {
    if (country.code) {
      return (
        <Link
          innerRef={index === COLLAPSED_ROWS ? (domLink) => (this.focussedElement = domLink) : null}
          onClick={() => this.trackCountryClick(country.code)}
          to={this.props.locationBuilder(country.code, this.props.selectedSource)}
        >
          {country.name}
        </Link>
      );
    } else {
      return I18n.t('Explorer.Demographics.unknown_country');
    }
  }

  trackCountryClick = (code) => {
    Analytics.trackEvent('demographics-click-table', {
      country: code,
      source: this.props.selectedSource
    });
  };

  expand = () => {
    Analytics.trackEvent('demographics-expand-table', {
      seletedSource: this.props.selectedSource
    });
    this.setState({ collapsed: false });
  };

  collapse = () => {
    Analytics.trackEvent('demographics-collapse-table', {
      selectedSource: this.props.selectedSource
    });
    this.setState({ collapsed: true });
  };
}

export default Table;
