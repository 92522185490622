import { throttle } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';

import fetch from 'util/fetch';

import NumberHelper from 'util/number_helper';
import { attachFocusLimiter } from 'components/Explorer/util/focus_limiter';

class Editor extends React.Component {
  static propTypes = {
    identifierList: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    analyticsDialogueName: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      content: '',
      tokens: []
    };

    this.textEntered = false;
  }

  componentDidMount() {
    this.trackEvent('Opened', this.props.identifierList ? 'Edit' : 'Create');

    if (this.props.identifierList) {
      this.setState(
        {
          content: this.props.identifierList.content
        },
        this.updateTokens
      );
    }

    attachFocusLimiter(this.containerElement);
  }

  render() {
    return (
      <div className="IdentifierFilter-editor" ref={(ref) => (this.containerElement = ref)}>
        <div className="IdentifierFilter-editor-content">
          {this.title}
          {this.content}
          {this.footer}
          {this.state.saving ? this.savingIndicator : null}
        </div>
      </div>
    );
  }

  get savingIndicator() {
    return <div className="IdentifierFilter-editor-saving-message" />;
  }

  get title() {
    return (
      <div className="IdentifierFilter-editor-title">
        {I18n.t('Explorer.AdvancedSearch.fields.identifiers.editor.header')}
      </div>
    );
  }

  get content() {
    return (
      <div className="IdentifierFilter-editor-inner">
        <div className="IdentifierFilter-editor-note">
          {I18n.t('Explorer.AdvancedSearch.fields.identifiers.editor.notes', {
            maxCount: NumberHelper.formatNumberWithDelimiter(this.maxTokens)
          })}
        </div>
        <div className="IdentifierFilter-editor-form">
          <textarea
            value={this.state.content}
            placeholder={I18n.t('Explorer.AdvancedSearch.fields.identifiers.editor.placeholder')}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            ref={this.fieldRef}
          />
        </div>
      </div>
    );
  }

  get footer() {
    return (
      <div className="IdentifierFilter-editor-footer">
        <div className={`IdentifierFilter-editor-meta ${this.tooManyIdentifiers ? 'error' : 'no-error'}`}>
          {I18n.htmlSafe('Explorer.AdvancedSearch.fields.identifiers.editor.meta', {
            maxCount: NumberHelper.formatNumberWithDelimiter(this.maxTokens),
            formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.tokens.length)
          })}
        </div>
        <div className="IdentifierFilter-editor-buttons">
          <button type="button" className="cancel" onClick={this.onCancel}>
            {I18n.t('Explorer.AdvancedSearch.fields.identifiers.editor.cancel')}
          </button>
          <button
            type="button"
            disabled={!this.canSave}
            className={`submit ${this.canSave ? 'enabled' : 'disabled'}`}
            onClick={this.onSave}
          >
            {I18n.t('Explorer.AdvancedSearch.fields.identifiers.editor.save')}
          </button>
        </div>
      </div>
    );
  }

  updateTokens = throttle(() => {
    this.setState({
      tokens: this.state.content.split(/\s+/).filter((t) => t.length)
    });
  }, 250);

  get maxTokens() {
    return window.current_user.maxIdentifierTokens;
  }

  get canSave() {
    return this.state.tokens.length > 0 && !this.tooManyIdentifiers;
  }

  get tooManyIdentifiers() {
    return this.state.tokens.length > this.maxTokens;
  }

  fieldRef = (f) => {
    this.field = f;

    if (f) f.focus();
  };

  onChange = (e) => {
    if (!this.textEntered && e.target.value.length > this.state.content.length) {
      this.textEntered = true;
      this.trackEvent('Filled');
    }

    this.setState(
      {
        content: e.target.value
      },
      this.updateTokens
    );
  };

  onKeyDown = (e) => {
    if (e.key === 'Escape') this.onCancel();
  };

  onFocus = () => {
    if (this.field) this.field.select();
  };

  onSave = async () => {
    this.setState({ saving: true });

    const body = {
      identifier_list: {
        identifiers: this.state.content
      }
    };

    try {
      const response = await fetch('/explorer/identifier_lists', {
        method: 'post',
        body: JSON.stringify(body)
      });

      const identifierList = await response.json();
      this.trackEvent('Data Updated', null, this.state.tokens.length);
      this.props.onSave(identifierList);
    } catch (_error) {
      window.alert(I18n.t('Explorer.AdvancedSearch.fields.identifiers.editor.error'));
      this.setState({ saving: false });
    }
  };

  onCancel = () => {
    this.trackEvent('Closed');
    this.props.onCancel();
  };

  trackEvent(eventName, action = null, itemsCount = null) {
    Analytics.trackEvent(`Advanced Search: Modal Dialogue ${eventName}`, {
      dialogue_name: this.props.analyticsDialogueName,
      ...(action !== null && { action: action }),
      ...(itemsCount !== null && { items_count: itemsCount })
    });
  }
}

export default Editor;
