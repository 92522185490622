import React from 'react';
import PropTypes from 'prop-types';

import TypeFilter from './TypeFilter';
import OpenAccessTypeFilter from './OpenAccessTypeFilter';
import IdentifierFilter from './IdentifierFilter';
import PubmedFilter from './PubmedFilter';
import AffiliationFilter from './AffiliationFilter';
import FieldOfResearchFilter from './FieldOfResearchFilter';
import SustainableDevelopmentGoalsFilter from './SustainableDevelopmentGoalsFilter';

import FilterSet from 'components/Explorer/models/filter_set';
import SearchTerm from 'models/SearchTerm';
import { SearchField } from 'components/Explorer/Form';
import HelpLink from '../HelpLink';

import './styles';

class OutputSearchFields extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    initialSearchTerm: PropTypes.instanceOf(SearchTerm).isRequired
  };

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field-group OutputSearchFields">
        <h3 className="Explorer-AdvancedSearch-field-group-title">
          {I18n.t('Explorer.AdvancedSearch.groups.outputs')}
        </h3>
        {this.keywordFilter}
        {this.fieldOfResearchFilter}
        {this.affiliationFilter}
        {this.titleFilter}
        {this.sustainableDevelopmentGoalsFilter}
        <TypeFilter filterSet={this.props.filterSet} analyticsFieldName="Type Of Output" />
        {this.openAccessFilter}
        <IdentifierFilter filterSet={this.props.filterSet} />
        {this.orcidFilter}
        <PubmedFilter filterSet={this.props.filterSet} />
      </div>
    );
  }

  get keywordFilter() {
    return (
      <div className="Explorer-AdvancedSearch-field">
        <label htmlFor="AdvancedSearch-Keyword-Filter">
          {I18n.t('Explorer.AdvancedSearch.fields.keywords.title')}
          <HelpLink
            href={I18n.t('Explorer.AdvancedSearch.help_uri')}
            tip={I18n.t('Explorer.AdvancedSearch.fields.keywords.description')}
            analyticsFieldName="Keywords"
          />
        </label>
        <SearchField
          fieldID="AdvancedSearch-Keyword-Filter"
          fieldName="q"
          filterSet={this.props.filterSet}
          placeholder={I18n.t('Explorer.AdvancedSearch.fields.keywords.placeholder')}
          analyticsEventPrefix="Advanced Search: Field"
          analyticsFieldName="Keywords"
        />
      </div>
    );
  }

  get affiliationFilter() {
    if (window.current_user.librarian) return;

    return (
      <AffiliationFilter
        filterSet={this.props.filterSet}
        initialSearch={this.props.initialSearchTerm.termForAffiliation()}
      />
    );
  }

  get fieldOfResearchFilter() {
    if (window.current_user.librarian) return;

    return (
      <FieldOfResearchFilter
        filterSet={this.props.filterSet}
        initialSearch={this.props.initialSearchTerm.termForFieldOfResearch()}
      />
    );
  }

  get openAccessFilter() {
    if (window.current_user.librarian) return;

    return <OpenAccessTypeFilter filterSet={this.props.filterSet} analyticsFieldName="Type Of Open Access" />;
  }

  get sustainableDevelopmentGoalsFilter() {
    if (window.current_user.librarian) return;

    return (
      <SustainableDevelopmentGoalsFilter
        filterSet={this.props.filterSet}
        initialSearch={this.props.initialSearchTerm.termForSustainableDevelopmentGoals()}
      />
    );
  }

  get titleFilter() {
    return (
      <div className="Explorer-AdvancedSearch-field">
        <label htmlFor="AdvancedSearch-Title-Filter">
          {I18n.t('Explorer.AdvancedSearch.fields.title.title')}
          <HelpLink
            href={I18n.t('Explorer.AdvancedSearch.help_uri')}
            tip={I18n.t('Explorer.AdvancedSearch.fields.title.description')}
            analyticsFieldName="Title Of Output"
          />
        </label>
        <SearchField
          fieldID="AdvancedSearch-Title-Filter"
          fieldName="title"
          filterSet={this.props.filterSet}
          placeholder={I18n.t('Explorer.AdvancedSearch.fields.title.placeholder')}
          analyticsEventPrefix="Advanced Search: Field"
          analyticsFieldName="Title Of Output"
        />
      </div>
    );
  }

  get orcidFilter() {
    return (
      <div className="Explorer-AdvancedSearch-field">
        <label htmlFor="AdvancedSearch-ORCID-Filter">
          {I18n.t('Explorer.AdvancedSearch.fields.orcid.title')}
          <HelpLink
            href={I18n.t('Explorer.AdvancedSearch.help_uri')}
            tip={I18n.t('Explorer.AdvancedSearch.fields.orcid.description')}
            analyticsFieldName="ORCID"
          />
        </label>
        <SearchField
          fieldID="AdvancedSearch-ORCID-Filter"
          fieldName="orcid"
          filterSet={this.props.filterSet}
          placeholder={I18n.t('Explorer.AdvancedSearch.fields.orcid.placeholder')}
          analyticsEventPrefix="Advanced Search: Field"
          analyticsFieldName="ORCID"
        />
      </div>
    );
  }
}

export default OutputSearchFields;
