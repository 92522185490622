import React from 'react';
import { delay } from 'lodash-es';

class UploadWidget extends React.Component {
  render() {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- [TODO]
      <div className="DatasetUploadPopover-UploadWidget" onClick={this.didClickButton}>
        <input
          type="file"
          accept="text/csv"
          onChange={this.handleInputFieldSelection}
          ref={(elm) => (this.inputField = elm)}
        />
        <label>
          <span className="label">{this.props.children}</span>
        </label>
      </div>
    );
  }

  didClickButton = () => {
    this.inputField && this.inputField.dispatchEvent(new MouseEvent('click'));
  };

  handleInputFieldSelection = (e) => {
    // The event must be persisted before we can access it asynchronously
    e.persist();
    delay(() => {
      this.processFiles(e.target.files);
    }, 250);
  };

  processFiles(files) {
    if (files.length === 0) {
      alert(I18n.t('dataset_upload.upload.error_format'));
      return;
    } else if (files.length > 1) {
      alert(I18n.t('dataset_upload.upload.error_multiple'));
      return;
    }

    let file = files[0];

    // We need to check both conditions, because IE does not appear to supply
    // the file type.
    if (file.type !== 'text/csv' && !file.name.match(/\.csv$/)) {
      alert(I18n.t('dataset_upload.upload.error_format'));
      return;
    }

    this.props.onSelectFile(file);
  }
}

export { UploadWidget };
