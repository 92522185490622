class Source {
  static colors = {
    blog: '#e89500',
    f1000: '#CB2D2D',
    fbwall: '#071D70',
    gplus: '#912470',
    linkedin: '#00BFFF',
    msm: '#B60000',
    peer_review: '#bdbdbd',
    pinterest: '#CC3300',
    policy: '#270a63',
    qna: '#EFEFEF',
    rdt: '#B9DDEB',
    tweet: '#2F90B9',
    video: '#98C973',
    weibo: '#df931b',
    wikipedia: '#3b2a3d',
    patent: '#d45029'
  };

  constructor(name, mentionCount) {
    this.name = name;
    this.mentionCount = mentionCount;
  }

  get color() {
    return Source.colors[this.name];
  }
}

export default Source;
