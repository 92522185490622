import React from 'react';
import { compact, slice } from 'lodash-es';

export default class AutocompleteFieldResults extends React.Component {
  render() {
    return (
      <div className="AutocompleteFieldResults">
        <div className="AutocompleteFieldResults-content" onScroll={this.props.didScroll}>
          {this.props.results.map((result) => this.renderResult(result))}
        </div>
      </div>
    );
  }

  renderResult(result) {
    const selected = this.props.currentlySelected === result;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions -- [TODO]
      <div
        key={result.id}
        className={`AutocompleteFieldResults-row ${selected ? 'selected' : 'not-selected'}`}
        onClick={() => this.props.onSelectResult(result)}
      >
        {this.renderContent(result)}
      </div>
    );
  }

  renderContent(result) {
    switch (this.props.type) {
      case 'authors':
        return <Author result={result} />;
      case 'departments':
        return <Department result={result} />;
      case 'publishers':
        return <Publisher result={result} />;
      case 'journals':
        return <Journal result={result} />;
      case 'doi_prefixes':
        return <DoiPrefix result={result} />;
      case 'handle_prefixes':
        return <HandlePrefix result={result} />;
      case 'uber_research_institutions':
        return <UberResearchInstitution result={result} />;
      case 'mention_sources':
        return <MentionSource result={result} />;
      case 'countries':
        return <Country result={result} />;
    }
  }
}

class Author extends React.PureComponent {
  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.formattedName}</span>
        <span className="meta">{this.formattedDepartments}</span>
      </div>
    );
  }

  get formattedDepartments() {
    const totalCount = this.props.result.departments.length;
    const selected = slice(this.props.result.departments, 0, 2);
    const str = selected.join(', ');

    if (selected.length < totalCount) {
      return I18n.t('autocomplete.authors.more_departments', {
        departments: str,
        more: totalCount - selected.length
      });
    } else {
      return str;
    }
  }

  get formattedName() {
    return compact([this.props.result.first_name, this.props.result.last_name]).join(' ');
  }
}

class Department extends React.PureComponent {
  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.props.result.name}</span>
      </div>
    );
  }
}

class Publisher extends React.PureComponent {
  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.props.result.name}</span>
      </div>
    );
  }
}

class Journal extends React.PureComponent {
  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.props.result.title}</span>
        {this.optionalMetadata}
      </div>
    );
  }

  get optionalMetadata() {
    if (!this.props.result.issns.length) return;

    return (
      <span className="meta">
        {I18n.htmlSafe('autocomplete.journals.issns', {
          issns: this.props.result.issns.join(', ')
        })}
      </span>
    );
  }
}

class DoiPrefix extends React.PureComponent {
  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.props.result.description}</span>
      </div>
    );
  }
}

class HandlePrefix extends React.PureComponent {
  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.props.result.description}</span>
      </div>
    );
  }
}

class UberResearchInstitution extends React.PureComponent {
  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.props.result.name}</span>
      </div>
    );
  }
}

class Country extends React.PureComponent {
  render() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <span className="title">{this.props.result.name}</span>
      </div>
    );
  }
}

class MentionSource extends React.PureComponent {
  state = {
    imageError: false
  };

  render() {
    switch (this.props.result.type) {
      case 'mention_source':
        return this.mentionSource;
      case 'post_type':
        return this.postType;
    }
  }

  get mentionSource() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <div className={`image ${this.state.imageError ? 'missing-image' : 'present-image'}`}>
          {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
          <img src={this.props.result.imageURL} onError={this.imageDidError} />
        </div>
        <div className="content">
          <span className="meta">
            {I18n.t(this.props.result.profileType, {
              scope: 'mentions.filter.mention_source_types'
            })}
          </span>
          <span className="name">{this.props.result.name}</span>
        </div>
      </div>
    );
  }

  get postType() {
    return (
      <div className="AutocompleteFieldResults-row-content">
        <div className={`image source-icon ${this.props.result.postType}`} />
        <div className="content">
          <span className="name">
            {
              I18n.t(this.props.result.postType, {
                scope: 'mentions.filter.mention_source_post_type_results'
              }).title
            }
          </span>
          <span className="meta">
            {
              I18n.t(this.props.result.postType, {
                scope: 'mentions.filter.mention_source_post_type_results'
              }).description
            }
          </span>
        </div>
      </div>
    );
  }

  imageDidError() {
    this.setState({ imageError: true });
  }
}
