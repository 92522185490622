import React from 'react';
import { useHistory } from 'react-router-dom';

const withRouter = (WrappedComponent) => {
  const ComponentWithHistory = (props) => {
    const history = useHistory();

    return <WrappedComponent history={history} {...props} />;
  };

  ComponentWithHistory.WrappedComponent = WrappedComponent;

  return ComponentWithHistory;
};

export default withRouter;
