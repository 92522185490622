import React from 'react';
import { delay, flatten, flow, uniq } from 'lodash-es';

import { UploadWidget } from './UploadWidget';

import { Dataset } from '../../models/dataset';

class OutputsUploadPanel extends React.Component {
  render() {
    return (
      <div className="DatasetUploadPopover-OutputsUploadPanel DatasetUploadPopover-ContentPanel">
        {this.documentation}

        <div className="DatasetUploadPopover-outputs-cta-panel">
          <UploadWidget onSelectFile={this.selectFile}>{I18n.t('dataset_upload.upload_outputs.upload')}</UploadWidget>
        </div>
      </div>
    );
  }

  get documentation() {
    return (
      <div className="DatasetUploadPopover-documentation-panel">
        <div className="header-icon">
          <h2>{I18n.t('dataset_upload.upload_outputs.title')}</h2>
          <a
            className="help"
            href={I18n.t('dataset_upload.upload_outputs.help_uri')}
            title={I18n.t('dataset_upload.help_title')}
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </div>
        <p>
          Next, you need to upload a CSV spreadsheet file that lists all of your institution's research outputs. This
          spreadsheet should include a list of research output identifiers (such as DOIs or PubMed IDs), as well as the
          names of groups/departments, and authors at your institution that are associated with each output.
        </p>
        <p>
          You can use a single row for each output, and separate authors and groups using semicolons. Alternatively, you
          can list each individual author and their associated departments on separate rows with the same research
          output identifier, and these rows will be merged together for you.
        </p>
        <p>
          For more details, examples, and help creating your research output spreadsheet,{' '}
          <a href={I18n.t('dataset_upload.upload_outputs.help_uri')} target="_blank" rel="noopener noreferrer">
            click here to read our support documentation.
          </a>
        </p>
        <h3>Example research output spreadsheet</h3>
        <div className="DatasetUploadPopover-csv-example-table">
          <table>
            <thead>
              <tr>
                <th className="row">1</th>
                <th>DOI</th>
                <th>PubMed ID</th>
                <th>Department</th>
                <th>Author</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="row">2</th>
                <td>10.1038/nplants.2007.05</td>
                <td>884472</td>
                <td>Extraterrestrial Studies</td>
                <td>Mulder, Fox; Scully, Dana</td>
              </tr>
              <tr>
                <th className="row">3</th>
                <td>10.1038/nplants.2007.05</td>
                <td>884472</td>
                <td>Exolinguistics</td>
                <td>Picard, Jean-Luc; Janeway, Kathryn</td>
              </tr>
              <tr>
                <th className="row">4</th>
                <td>10.1038/cc7.2017.SRGB.12</td>
                <td></td>
                <td>School of Engineering</td>
                <td>Lovelace, Ada</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          When you have formatted your groups into a CSV spreadsheet that matches this format, click the button below to
          upload it.
        </p>
      </div>
    );
  }

  selectFile = (file) => {
    this.props.setLoadingState(true);

    delay(() => {
      let departments = [];

      if (this.props.hierarchy && this.props.hierarchy.results) {
        departments = flow(uniq, flatten)(this.props.hierarchy.results);
      }

      const dataset = new Dataset(file, departments);

      dataset.onLoad = () => {
        this.props.setLoadingState(false);
        this.props.onNewDataset(dataset);
      };

      dataset.load();
    }, 500);
  };
}

export { OutputsUploadPanel };
