import React from 'react';
import FileSaver from 'file-saver';

import NumberHelper from '../../util/number_helper';

class OutputsCheckPanel extends React.Component {
  render() {
    return (
      <div className="DatasetUploadPopover-OutputsCheckPanel DatasetUploadPopover-ContentPanel">
        {this.documentation}

        <div className="DatasetUploadPopover-check-outputs-content">
          <div className="DatasetUploadPopover-check-outputs-table">
            <h3>{I18n.t('dataset_upload.outputs_check.extract_title')}</h3>
            {this.renderTable()}
          </div>
          <div className="DatasetUploadPopover-check-outputs-next-steps">
            <h3>{I18n.t('dataset_upload.outputs_check.check_title')}</h3>
            {this.renderOverview()}
          </div>
        </div>
      </div>
    );
  }

  get documentation() {
    return (
      <div className="DatasetUploadPopover-documentation-panel">
        <div className="header-icon">
          <h2>{I18n.t('dataset_upload.outputs_check.title')}</h2>
          <a
            className="help"
            href={I18n.t('dataset_upload.outputs_check.help_uri')}
            title={I18n.t('dataset_upload.help_title')}
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </div>
      </div>
    );
  }

  renderTable() {
    return (
      <table className="table-outputs-check">
        <tbody>
          <tr>
            <th className="label">
              <span className="label-content">{I18n.t('dataset_upload.outputs_check.total_rows.label')}</span>
              <span className="label-description">{I18n.t('dataset_upload.outputs_check.total_rows.caption')}</span>
            </th>
            <td className="number">{NumberHelper.formatNumberWithDelimiter(this.props.dataset.totalRows)}</td>
          </tr>
          <tr>
            <th className="label">
              <span className="label-content">{I18n.t('dataset_upload.outputs_check.total_outputs.label')}</span>
              <span className="label-description">{I18n.t('dataset_upload.outputs_check.total_outputs.caption')}</span>
            </th>
            <td className="number">
              {NumberHelper.formatNumberWithDelimiter(this.props.dataset.totalResearchOutputs)}
            </td>
          </tr>
          <tr>
            <th className="label">
              <span className="label-content">{I18n.t('dataset_upload.outputs_check.outputs_invalid.label')}</span>
              <span className="label-description">
                {I18n.t('dataset_upload.outputs_check.outputs_invalid.caption')}
              </span>
            </th>
            <td className="number">
              {NumberHelper.formatNumberWithDelimiter(this.props.dataset.totalInvalidResearchOutputs)}
            </td>
          </tr>
          <tr>
            <th className="label">
              <span className="label-content">{I18n.t('dataset_upload.outputs_check.outputs_valid.label')}</span>
              <span className="label-description">{I18n.t('dataset_upload.outputs_check.outputs_valid.caption')}</span>
            </th>
            <td className="number">
              {NumberHelper.formatNumberWithDelimiter(this.props.dataset.totalValidResearchOutputs)}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderOverview() {
    return (
      <div className={`content-status-overview ${this.overviewClass}`}>
        <div className="alert-block">
          <span className="alert-block-icon" />
          <span className="alert-block-message">{this.overviewMessage()}</span>
        </div>
        <div className="report-block">{this.reportMessage()}</div>
        <div className="report-button">
          {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
          <a onClick={this.sendValidationReport}>{I18n.t('dataset_upload.outputs_check.results.report_button')}</a>
        </div>
        <h3>{I18n.t('dataset_upload.outputs_check.next_steps_title')}</h3>
        <div className="next-step-block">{this.nextStepMessage()}</div>
        {this.renderPublishButton()}
      </div>
    );
  }

  overviewMessage() {
    if (!this.props.dataset.valid) {
      return I18n.t('dataset_upload.outputs_check.results.failed_overview');
    } else if (this.props.dataset.perfect) {
      return I18n.t('dataset_upload.outputs_check.results.perfect_overview');
    } else {
      return I18n.t('dataset_upload.outputs_check.results.some_errors_overview');
    }
  }

  nextStepMessage() {
    if (!this.props.dataset.valid) {
      return I18n.htmlSafe('dataset_upload.outputs_check.results.failed_next_step_html');
    } else if (this.props.dataset.perfect) {
      return I18n.htmlSafe('dataset_upload.outputs_check.results.perfect_next_step_html');
    } else {
      return I18n.htmlSafe('dataset_upload.outputs_check.results.some_errors_next_step_html');
    }
  }

  reportMessage() {
    if (!this.props.dataset.valid) {
      return I18n.t('dataset_upload.outputs_check.results.failed_report');
    } else if (this.props.dataset.perfect) {
      return I18n.t('dataset_upload.outputs_check.results.perfect_report');
    } else {
      return I18n.t('dataset_upload.outputs_check.results.some_errors_report');
    }
  }

  renderPublishButton() {
    return (
      <div className="next-step-button">
        {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
        <a className="button re-upload" onClick={this.props.onReUpload}>
          {I18n.t('dataset_upload.outputs_check.results.re_upload_button')}
        </a>
        {this.props.dataset.valid && (
          // eslint-disable-next-line -- jsx-a11y [TODO]
          <a className="button publish" onClick={this.props.onSave}>
            {I18n.t('dataset_upload.outputs_check.results.publish_button')}
          </a>
        )}
      </div>
    );
  }

  get overviewClass() {
    if (!this.props.dataset.valid) {
      return 'error';
    } else if (this.props.dataset.perfect) {
      return 'good';
    } else {
      return 'warning';
    }
  }

  sendValidationReport = () => {
    let csv = this.props.dataset.validationReport;
    FileSaver.saveAs(new Blob([csv], { type: 'text/csv' }), 'Validation Report.csv');
  };
}

export { OutputsCheckPanel };
