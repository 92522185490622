const urlConfig = {
  default: {
    api: 'https://help.altmetric.com/support/home',
    csv: 'https://help.altmetric.com/support/home'
  },
  mentions: {
    api: 'https://help.altmetric.com/support/solutions/articles/6000241368-introduction-to-the-explorer-api',
    csv: 'https://help.altmetric.com/support/solutions/articles/6000241087-export-search-results-exporting-mentions-from-the-mentions-tab'
  },
  mention_sources: {
    api: 'https://help.altmetric.com/support/solutions/articles/6000241368-introduction-to-the-explorer-api',
    csv: 'https://help.altmetric.com/support/solutions/articles/6000241087-export-search-results-exporting-mentions-from-the-mentions-tab'
  },
  demographics: {
    api: 'https://help.altmetric.com/support/solutions/articles/6000241368-introduction-to-the-explorer-api',
    csv: 'https://help.altmetric.com/support/solutions/articles/6000241088-export-search-results-downloading-results-to-a-csv-spreadsheet'
  },
  journals: {
    api: 'https://help.altmetric.com/support/solutions/articles/6000241368-introduction-to-the-explorer-api',
    csv: 'https://help.altmetric.com/support/solutions/articles/6000241088-export-search-results-downloading-results-to-a-csv-spreadsheet'
  },
  research_outputs: {
    api: 'https://help.altmetric.com/support/solutions/articles/6000241368-introduction-to-the-explorer-api',
    csv: 'https://help.altmetric.com/support/solutions/articles/6000241088-export-search-results-downloading-results-to-a-csv-spreadsheet'
  },
  attention: {
    api: 'https://help.altmetric.com/support/solutions/articles/6000241368-introduction-to-the-explorer-api',
    csv: 'https://help.altmetric.com/support/solutions/articles/6000241088-export-search-results-downloading-results-to-a-csv-spreadsheet'
  }
};

function helpFor(endpoint) {
  const result = urlConfig[endpoint];
  return result ? result : urlConfig.default;
}

export default helpFor;
