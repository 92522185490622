import './styles';

import React from 'react';
import PropTypes from 'prop-types';

class LinkIf extends React.Component {
  static propTypes = {
    condition: PropTypes.bool.isRequired,
    url: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
  };

  render() {
    if (this.props.condition) {
      return (
        <a href={this.props.url} rel="noopener noreferrer" target="_blank">
          {this.props.children}
        </a>
      );
    } else {
      return <div>{this.props.children}</div>;
    }
  }
}

export default LinkIf;
