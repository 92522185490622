import { mapValues } from 'lodash-es';
// prettier-ignore
const intercomEmbedCode = function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/'+window.intercomSettings.app_id;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}}; // eslint-disable-line

const setupIntercom = () => {
  if (window.intercomSettings) {
    intercomEmbedCode();
    window.Intercom('boot', window.intercomSettings);
  } else {
    window.Intercom = () => {};
    // No-op – this is an empty function, which allows us to remove lots
    // of boring guards to check if Intercom is available.
  }
};

const pageChange = async () => {
  try {
    window.Intercom('update');
  } catch (e) {
    // no-op
  }

  const url = window.location.pathname + window.location.search;

  return trackEvent('viewPage', { url: url });
};

const setup = () => {
  setupIntercom();
  setupSegment();
};

const trackEvent = async (type, metadata = {}) => {
  if (process.env.NODE_ENV == 'development') logTracking(type, metadata); //eslint-disable-line

  const data = mapValues(metadata, (item) => (typeof item == 'object' ? JSON.stringify(item) : item));

  sendEvent(type, data);
};

const sendEvent = async (type, data) => {
  try {
    window.Intercom('trackEvent', type, data);
  } catch (e) {
    // No-op
  }

  return trackSegment(type, data);
};

const logTracking = (type, metadata) => {
  console.log(
    `%cAnalytics event: %c${type} %c${JSON.stringify(metadata)}`,
    'font-style: italic',
    'font-style: italic; font-weight: bold',
    'font-family: monospace'
  );
};

const trackSegment = async (type, data) => {
  if (!window.analytics || typeof window.analytics.user != 'function') {
    console.warn('analytics is not ready');
    return;
  }

  if (window.current_user && window.analytics.user().id() != window.current_user.id) {
    await window.analytics.identify(window.current_user.id);
  }

  if (type == 'viewPage') {
    return trackSegmentPage();
  } else {
    return window.analytics.track(type, data);
  }
};

const trackSegmentPage = async () => {
  // This can be part of trackSegment when Intercom is removed
  return window.analytics.page(window.altmetric.page_name);
};

function setupSegment() {
  if (!window.altmetric.segment_analytics_settings) {
    console.log('Skipping Segment Analytics setup');
    return;
  }

  // --- segment analytics snippet: --- //
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware'
      ];
      analytics.factory = function (e) {
        return function () {
          let t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        let t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
        let n = document.getElementsByTagName('script')[0];
        if (n) {
          n.parentNode.insertBefore(t, n);
        }
        analytics._loadOptions = e;
      };
      analytics._writeKey = window.altmetric.segment_analytics_settings.write_key;
      analytics.SNIPPET_VERSION = '4.13.2';
      analytics.load(window.altmetric.segment_analytics_settings.write_key);
      window.addEventListener('DOMContentLoaded', trackSegmentPage);
    }
}

export { setup, trackEvent, pageChange };
