import React from 'react';

import LoadingSpinner from 'components/LoadingSpinner';

class SavingMessage extends React.Component {
  render() {
    return (
      <div className="DatasetUploadPopover-SavingMessage DatasetUploadPopover-ContentPanel">
        <LoadingSpinner hidden={this.props.completed} />
        {this.props.completed ? this.renderCompleted() : this.renderWaiting()}
      </div>
    );
  }

  renderCompleted() {
    return (
      <div className="completed-message">
        <Tick />
        {I18n.t('dataset_upload.saving.completed_message')}
      </div>
    );
  }

  renderWaiting() {
    return <div className="waiting-message">{this.props.extraMessage || I18n.t('dataset_upload.saving.message')}</div>;
  }
}

class Tick extends React.Component {
  render() {
    return (
      <svg className="Tick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="Tick-Circle" cx="26" cy="26" r="25" fill="none" />
        <path className="Tick-Tick" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    );
  }
}

export { SavingMessage };
