import './styles';

import React from 'react';
import PropTypes from 'prop-types';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import { truncate } from 'lodash-es';

import BarVisualisation from 'components/Explorer/BarVisualisation';
import ResearchOutput from 'components/Explorer/models/research_output';
import Query from 'components/Explorer/models/query';

const DEFAULT_TITLE_LENGTH = 70; // Characters
const DEFAULT_VISIBLE_OUTPUTS_COUNT = 2;

class OutputsList extends React.Component {
  static defaultProps = {
    titleLength: DEFAULT_TITLE_LENGTH,
    visible: false,
    visibleOutputsCount: DEFAULT_VISIBLE_OUTPUTS_COUNT
  };

  static propTypes = {
    history: PropTypes.object,
    post: PropTypes.object.isRequired,
    outputs: PropTypes.array.isRequired,
    visible: PropTypes.bool,
    visibleOutputsCount: PropTypes.number,
    titleLength: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.query = new Query(this.props.history);
  }

  render() {
    if (!this.props.outputs.length) return null;

    return (
      <table className="OutputsList">
        <tbody>{this.visibleOutputs.map((output) => this.output(new ResearchOutput(output)))}</tbody>
      </table>
    );
  }

  output(output) {
    const title = truncate(output.title, {
      length: this.props.titleLength,
      separator: /\W/
    });
    const onClick = () => this.trackClick(output);

    return (
      <tr key={`${this.props.post.id}-${output.id}`}>
        <td className="visualisation">
          <Link onClick={onClick} to={this.query.withShowDetails(output.id).location}>
            <BarVisualisation output={output} />
          </Link>
        </td>
        <td className="output">
          <Link onClick={onClick} to={this.query.withShowDetails(output.id).location}>
            {title}
          </Link>
        </td>
      </tr>
    );
  }

  get visibleOutputs() {
    if (this.props.visible) {
      return this.props.outputs;
    } else {
      return this.props.outputs.slice(0, this.props.visibleOutputsCount);
    }
  }

  trackClick = (output) => {
    Analytics.trackEvent('highlights-open-details-page', {
      outputId: output.id
    });
  };
}

export default withRouter(OutputsList);
