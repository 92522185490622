import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import config from 'util/site_config.js.erb';

import FilterSet from 'components/Explorer/models/filter_set';
import HelpLink from '../HelpLink';

const TYPES = config.openAccessTypes;
const OA_ALL = config.openAccessAllTypesFilter;
const OA_CLOSED = config.openAccessClosedType;

class OpenAccessTypeFilter extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    analyticsFieldName: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = { selectedTypes: this.typesForUI(this.value) };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filterSet !== this.props.filterSet) {
      this.setState({ selectedTypes: this.typesForUI(this.value) });
    }
  }

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field OpenAccessTypeFilter">
        {this.label}
        <fieldset>
          <header>
            <legend>{I18n.t('Explorer.AdvancedSearch.fields.open_access_types.title')}</legend>
            <label className={`select-all ${this.noneSelected ? 'selected' : 'not-selected'}`}>
              <input
                id="AdvancedSearch-OpenAccessType-Filter-all"
                type="checkbox"
                checked={this.noneSelected}
                onChange={this.onSelectAll}
              />
              <span className="label">{I18n.t('Explorer.AdvancedSearch.fields.open_access_types.types.all')}</span>
            </label>
            {this.openAccessOnlyToggle}
          </header>
          <div className="others">
            <div className="open-types">{this.openAccessTypesList}</div>
            <div className="closed-types">{this.typeField(OA_CLOSED)}</div>
          </div>
        </fieldset>
      </div>
    );
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.open_access_types.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.open_access_types.description')}
          analyticsFieldName="Type Of Open Access"
        />
      </label>
    );
  }

  get openAccessOnlyToggle() {
    const selected = this.isSelected(OA_ALL);

    return (
      <label
        htmlFor={`AdvancedSearch-OpenAccessType-Filter-${OA_ALL}`}
        className={classNames('select-all-open-access', { selected: selected })}
      >
        <input
          id={`AdvancedSearch-OpenAccessType-Filter-${OA_ALL}`}
          type="checkbox"
          checked={selected}
          onChange={this.onSelectOpenAccessOnly}
        />
        <span
          className="icon"
          role="checkbox"
          tabIndex="0"
          aria-checked={selected}
          onClick={this.toggleSelectOpenAccessOnly}
          onKeyPress={(e) => {
            if (e.key === ' ' || e.key === 'Spacebar') {
              this.toggleSelectOpenAccessOnly(e);
            }
          }}
        />
        {I18n.t(`Explorer.AdvancedSearch.fields.open_access_types.types.${OA_ALL}`)}
      </label>
    );
  }

  get openAccessTypesList() {
    return [...this.onlyOpenTypes].map((type) => this.typeField(type));
  }

  typeField(type) {
    const id = `AdvancedSearch-OpenAccessType-Filter-${type}`;
    const selected = this.isSelected(type);

    return (
      <div key={type}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={id} className={classNames({ selected: selected })}>
          <input id={id} type="checkbox" checked={selected} onChange={(e) => this.onSelectType(type, e)} />
          <span className="label">
            <span className="text">
              {I18n.t(type, {
                scope: 'Explorer.AdvancedSearch.fields.open_access_types.types'
              })}
            </span>
          </span>
        </label>
      </div>
    );
  }

  get value() {
    return new Set(this.props.filterSet.get('open_access_types[]'));
  }

  isSelected(type) {
    return this.state.selectedTypes.has(type);
  }

  get noneSelected() {
    return this.state.selectedTypes.size === 0;
  }

  /**
   * @returns {Array}
   */
  get onlyOpenTypes() {
    return TYPES.filter((type) => type != OA_CLOSED);
  }

  /**
   * @param {Set} types
   * @returns {Array}
   */
  unselectedOpenAccessTypes(types) {
    return [...this.onlyOpenTypes].filter((type) => !types.has(type));
  }

  /**
   * @param {Set} types
   * @returns {Boolean}
   */
  allOpenAreSelected(types) {
    return this.unselectedOpenAccessTypes(types).length == 0;
  }

  /**
   * @param {Set} types
   * @returns {Boolean}
   */
  onlyAllOpenAreSelected(types) {
    return !types.has(OA_CLOSED) && this.allOpenAreSelected(types);
  }

  onSelectAll = () => {
    this.selectNone();
    this.trackEvent('Item Selected', 'all');
  };

  selectNone = () => {
    this.setSelected(new Set());
  };

  toggleSelectOpenAccessOnly = (e) => {
    let selectedTypes = new Set();

    if (!this.state.selectedTypes.has(OA_ALL)) {
      selectedTypes.add(OA_ALL);
      this.trackEvent('Item Selected', OA_ALL);
    } else {
      this.trackEvent('Item Deselected', OA_ALL);
    }

    e.preventDefault();
    this.setSelected(selectedTypes);
  };

  onSelectOpenAccessOnly = (e) => {
    const isSelected = e.target.checked;
    let selectedTypes = new Set();

    if (isSelected) {
      selectedTypes.add(OA_ALL);
      this.trackEvent('Item Selected', OA_ALL);
    } else {
      this.trackEvent('Item Deselected', OA_ALL);
    }

    this.setSelected(selectedTypes);
  };

  onSelectType = (type, e) => {
    const isSelected = e.target.checked;
    let selectedTypes = this.state.selectedTypes;

    if (isSelected) {
      selectedTypes.add(type);
      this.trackEvent('Item Selected', type);
    } else {
      selectedTypes.delete(type);
      this.trackEvent('Item Deselected', type);
    }

    this.setAllFlagFromSelectedTypes(selectedTypes); // Unselect OA_ALL when not all OA are selected
    this.setSelected(selectedTypes);
  };

  /**
   * @param {Set} types
   */
  setSelected = (types) => {
    this.props.filterSet.set('open_access_types[]', this.typesForFilter(types));
    this.setState({ selectedTypes: this.typesForUI(types) });
  };

  /**
   * @param {Set} types
   * @returns {Array}
   */
  typesForFilter(types) {
    let forFilter = new Set([...types]);

    if (this.allOpenAreSelected(forFilter)) {
      forFilter.add(OA_ALL);
      this.onlyOpenTypes.forEach((type) => forFilter.delete(type));
    }

    return [...forFilter];
  }

  /**
   * @param {Set} types
   * @returns {Set}
   */
  typesForUI(types) {
    let forUI = new Set([...types]);

    this.setSelectedTypesFromAllFlag(forUI);
    this.setAllFlagFromSelectedTypes(forUI);

    return forUI;
  }

  /**
   * @param {Set} types
   */
  setSelectedTypesFromAllFlag(types) {
    if (types.has(OA_ALL)) {
      [...this.onlyOpenTypes].forEach((value) => types.add(value));
    }
  }

  /**
   * @param {Set} types
   */
  setAllFlagFromSelectedTypes(types) {
    if (!types.has(OA_ALL) && this.onlyAllOpenAreSelected(types)) {
      types.add(OA_ALL);
    } else if (types.has(OA_ALL) && !this.onlyAllOpenAreSelected(types)) {
      types.delete(OA_ALL);
    }
  }

  trackEvent(eventName, value = null) {
    if (!this.trackingEnabled) return;

    Analytics.trackEvent(`Advanced Search: Field ${eventName}`, {
      field_name: this.props.analyticsFieldName,
      ...(value && { value: value })
    });
  }

  get trackingEnabled() {
    return !!this.props.analyticsFieldName;
  }
}
export default OpenAccessTypeFilter;
