import React from 'react';
import PropTypes from 'prop-types';

import FilterSet from 'components/Explorer/models/filter_set';

class OpenAccessFilter extends React.Component {
  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired
  };

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field OpenAccessFilter">
        {this.label}
        {this.options}
      </div>
    );
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.open_access.title')}
        <a
          className="help"
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          data-tooltip-content={I18n.t('Explorer.AdvancedSearch.fields.open_access.description')}
          target="_blank"
          rel="noreferrer"
        >
          {I18n.t('Explorer.AdvancedSearch.help')}
        </a>
      </label>
    );
  }

  get options() {
    return (
      <fieldset>
        <legend>{I18n.t('Explorer.AdvancedSearch.fields.open_access.title')}</legend>
        <label className="selected">
          <input
            id="AdvancedSearch-OpenAccess-Filter"
            type="checkbox"
            checked={this.value}
            onChange={(e) => this.onSelectOA(e)}
          />
          <span className="label">{I18n.t('Explorer.AdvancedSearch.fields.open_access.label')}</span>
        </label>
      </fieldset>
    );
  }

  get value() {
    return this.props.filterSet.get('open_access') === 'true';
  }

  onSelectOA = (e) => {
    const isSelected = e.target.checked;

    if (isSelected) {
      this.props.filterSet.set('open_access', 'true');
    } else {
      this.props.filterSet.clear('open_access');
    }

    this.forceUpdate();
  };
}

export default OpenAccessFilter;
