import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';

import AutocompleteField from '../AutocompleteField';
import HelpLink from './HelpLink';

class DepartmentFilter extends React.Component {
  // Setup
  ////////////////////////////////////////////////////////////////////////////

  query = null;
  ANALYTICS_FIELD_NAME = 'Verified Department';

  state = {
    loading: true,
    departments: []
  };

  static propTypes = {
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    history: PropTypes.object,
    initialSearch: PropTypes.string
  };

  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);

    if (this.departmentIDs.length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  ////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-AdvancedSearch-field DepartmentFilter">
        {this.label}
        {this.state.loading ? this.loadingMessage : this.field}
      </div>
    );
  }

  get loadingMessage() {
    return <div>{I18n.t('Explorer.AdvancedSearch.fields.departments.loading')}</div>;
  }

  get label() {
    return (
      <label>
        {I18n.t('Explorer.AdvancedSearch.fields.departments.title')}
        <HelpLink
          href={I18n.t('Explorer.AdvancedSearch.help_uri')}
          tip={I18n.t('Explorer.AdvancedSearch.fields.departments.help')}
          analyticsFieldName={this.ANALYTICS_FIELD_NAME}
        />
      </label>
    );
  }

  get field() {
    return (
      <AutocompleteField
        name="department_id"
        id="filter_department_id"
        type="departments"
        initialValue={this.props.initialSearch}
        placeholder={I18n.t('Explorer.AdvancedSearch.fields.departments.placeholder')}
        onChangeItems={this.updateSelectedAuthors}
        initiallySelected={this.state.departments}
        ref={this.getFieldRef}
        analyticsEventPrefix="Advanced Search: Field"
        analyticsFieldName={this.ANALYTICS_FIELD_NAME}
      />
    );
  }

  // Data
  ////////////////////////////////////////////////////////////////////////////

  updateSelectedAuthors = (departments) => {
    this.props.filterSet.set(
      'department_id[]',
      departments.map((a) => a.id)
    );
  };

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.departmentNames();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, departments: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  get departmentIDs() {
    return this.props.filterSet.get('department_id[]').map((id) => ({ id: id }));
  }

  getFieldRef = (elm) => {
    this.fieldRef = elm;
  };

  componentDidUpdate = () => {
    if (!this.fieldRef) return;
    if (!this.departmentIDs.length) this.fieldRef.clearAll();
  };
}

export default withRouter(DepartmentFilter);
