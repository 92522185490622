import Ajax from '../util/ajax';
import { delay } from 'lodash-es';

const POLL_DELAY = 4000;

class HistoricalDataset {
  constructor(id) {
    this.id = id;
    this.jobID = null;
  }

  publish(opts) {
    Ajax.publishExistingDataset(this.id)
      .done((data) => {
        this.jobID = data.job_id;
        this.deferProgressCheck(opts.onComplete, opts.onError, opts.onProgress);
      })
      .fail(opts.onError);
  }

  deferProgressCheck(onComplete, onError, onProgress) {
    delay(() => {
      this.progressCheck(onComplete, onError, onProgress);
    }, POLL_DELAY);
  }

  progressCheck(onComplete, onError, onProgress) {
    Ajax.checkPublishingProgress(this.jobID)
      .done((_data, _status, xhr) => {
        if (xhr.status === 202) {
          if (onProgress) {
            onProgress();
          }
          this.deferProgressCheck(onComplete, onError, onProgress);
        } else {
          if (onComplete) {
            onComplete(xhr.status);
          }
        }
      })
      .fail(onError);
  }
}

export { HistoricalDataset };
