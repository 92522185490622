import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import updateLocale from 'dayjs/plugin/updateLocale';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.extend(utc);

dayjs.updateLocale('en', {
  calendar: {
    lastDay: 'h:mm A [yesterday]',
    sameDay: '[today]',
    nextDay: '[tomorrow]',
    lastWeek: '[last] dddd',
    nextWeek: 'dddd',
    sameElse: 'DD/MM/YYYY'
  }
});

export default dayjs;
