import request from 'superagent';
import { getCSRF } from '../../application/util/rails';

const milliseconds_between_checks = 24 * 60 * 60 * 1000;

const setup = () => {
  window.addEventListener('focus', () => {
    checkRelease();
  });
};

const resetLastReleaseCheck = () => {
  sessionStorage.setItem('last_release_check', Date.now());
};

const checkRelease = () => {
  if (!window.current_user) {
    return;
  }

  const last_release_check = sessionStorage.getItem('last_release_check');

  if (Date.now() - last_release_check > milliseconds_between_checks) {
    request
      .get('/explorer/release_check')
      .then((response) => {
        handleReleaseCheckResponse(response).then(resetLastReleaseCheck).catch(console.log);
      })
      .catch((error) => {
        console.log('Error while checking current release: ' + error.message);
      });
  }
};

async function handleReleaseCheckResponse(response) {
  if (response.body.refresh_segment_identity) {
    await refreshSegmentIdentity();
  }

  return true;
}

async function refreshSegmentIdentity() {
  return request.post('/explorer/identify').set('X-CSRF-Token', getCSRF());
}

export { setup };
