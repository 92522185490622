import hljs from 'highlight.js/lib/core';
import langBash from 'highlight.js/lib/languages/bash';
import langJson from 'highlight.js/lib/languages/json';
import langJavascript from 'highlight.js/lib/languages/javascript';
import langMarkdown from 'highlight.js/lib/languages/markdown';
import langRuby from 'highlight.js/lib/languages/ruby';

const setup = function () {
  window.addEventListener('load', () => {
    hljs.registerLanguage('bash', langBash);
    hljs.registerLanguage('json', langJson);
    hljs.registerLanguage('javascript', langJavascript);
    hljs.registerLanguage('markdown', langMarkdown);
    hljs.registerLanguage('ruby', langRuby);

    document.querySelectorAll('.main-documentation .code-example pre').forEach((block) => {
      hljs.highlightElement(block);
    });
  });
};

export { setup };
