import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import config from 'util/site_config.js.erb';

class SortBy extends React.Component {
  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  render() {
    return (
      <div className="Explorer-Outputs-SortBy">
        <label className="label" htmlFor="sortby-select">
          {I18n.t('research_outputs.sort_by')}:
        </label>{' '}
        <select id="sortby-select" onChange={this.onSortByChange} value={this.query.sortOrder}>
          <option value="score">{I18n.t('Explorer.Outputs.SortBy.sort_options.score')}</option>
          <option value="score_asc">{I18n.t('Explorer.Outputs.SortBy.sort_options.score_asc')}</option>
          <option value="publication_date">{I18n.t('Explorer.Outputs.SortBy.sort_options.publication_date')}</option>
          <option value="publication_date_asc">
            {I18n.t('Explorer.Outputs.SortBy.sort_options.publication_date_asc')}
          </option>
          <optgroup label={I18n.t('Explorer.Outputs.SortBy.sort_option_groups.source')}>
            {[...config.sources, ...config.otherSources].map((source) => (
              <option key={source} value={source}>
                {I18n.t(`Explorer.Outputs.SortBy.sort_options.${source}`)}
              </option>
            ))}
          </optgroup>
          <optgroup label={I18n.t('Explorer.Outputs.SortBy.sort_option_groups.mentions')}>
            {config.mentionTimeframes.map((timeframe) => (
              <option key={timeframe} value={timeframe}>
                {I18n.t(`Explorer.Outputs.SortBy.sort_options.${timeframe}`)}
              </option>
            ))}
          </optgroup>
        </select>
      </div>
    );
  }

  onSortByChange = (event) => {
    Analytics.trackEvent('research-outputs-sort', { key: event.target.value });

    this.props.history.push(this.query.withSortOrder(event.target.value).location);
  };
}

export default withRouter(SortBy);
