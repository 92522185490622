import { merge } from 'lodash-es';
import { getCSRF } from './rails';

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

const fetchWithOptions = (url, options) => {
  const fetchOptions = {
    credentials: 'same-origin',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRF()
    }
  };

  merge(fetchOptions, options);

  return fetch(url, fetchOptions).then(checkStatus);
};

export default fetchWithOptions;
