import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';
import { isEmpty } from 'lodash-es';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import Query from 'components/Explorer/models/query';
import { ContinentsWorldMap, CountriesWorldMap } from './WorldMap';

class Demographics extends React.Component {
  static DETAIL_LEVEL = {
    continents: 'continents',
    countries: 'countries'
  };

  // Setup
  //////////////////////////////////////////////////////////////////////////////

  state = {
    loading: true,
    loadingFailed: false,
    data: {},
    detailLevel: Demographics.DETAIL_LEVEL.continents
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-Demographics Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.Demographics.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition classNames="Content" timeout={250}>
            {this.state.loading ? this.loadingMessage : this.content}
          </Transition>
          {this.jumpLink}
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div key="loadingMessage" className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div key="content" className="Highlights-content Highlights-Demographics-content">
        {this.hasResults ? this.map : this.emptyMessage}
      </div>
    );
  }

  get errorMessage() {
    return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get map() {
    return (
      <div>
        {this.detailLevelSelector}
        {this.state.detailLevel == Demographics.DETAIL_LEVEL.continents ? (
          <ContinentsWorldMap data={this.state.data} />
        ) : (
          <CountriesWorldMap data={this.state.data} />
        )}
      </div>
    );
  }
  get detailLevelSelector() {
    return (
      <div className="detail-level-selector">
        <button
          className={this.state.detailLevel == Demographics.DETAIL_LEVEL.continents ? 'active' : 'not-active'}
          onClick={() => this.setDetailLevel(Demographics.DETAIL_LEVEL.continents)}
        >
          {I18n.t('Explorer.Highlights.Demographics.toggle.continents')}
        </button>

        <button
          className={this.state.detailLevel == Demographics.DETAIL_LEVEL.countries ? 'active' : 'not-active'}
          onClick={() => this.setDetailLevel(Demographics.DETAIL_LEVEL.countries)}
        >
          {I18n.t('Explorer.Highlights.Demographics.toggle.countries')}
        </button>
      </div>
    );
  }

  get emptyMessage() {
    return <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.Demographics.empty')}</div>;
  }

  get jumpLink() {
    if (!this.hasResults) return;

    return (
      <Link
        onClick={this.trackJumpLink}
        to={this.query.locationWithPathname('/demographics')}
        className="Highlights-jump-link"
      >
        {I18n.t('Explorer.Highlights.Demographics.more')}
      </Link>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsDemographics();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body;
      }

      this.setState({ loading: false, loadingFailed, data });
    });
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  setDetailLevel = (detailLevel) => {
    Analytics.trackEvent('highlights-demographics-set-view', { detailLevel });
    this.setState({ detailLevel });
  };

  get hasResults() {
    return this.state.data && !isEmpty(this.state.data);
  }

  trackJumpLink = () => {
    Analytics.trackEvent('highlights-click-jump-link', {
      jumpedTo: 'demographics'
    });
  };
}

export default withRouter(Demographics);
