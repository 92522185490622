import URI from 'urijs';

const extract = (text) => {
  const uris = [];

  URI.withinString(text, (u) => uris.push(URI(u).normalize().toString()));

  return uris;
};

export default { extract };
