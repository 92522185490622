import doi from 'identifiers-doi';
import pmid from 'identifiers-pubmed';
import nct_id from 'identifiers-nct';
import repec_id from 'identifiers-repec';
import arxiv_id from 'identifiers-arxiv';
import ads_bibcode from 'identifiers-bibcode';
import handle from 'identifiers-handle';
import urn from 'identifiers-urn';
import isbn from './identifiers/isbn';
import uri from './identifiers/uri';

const HANDLERS = {
  doi,
  pmid,
  nct_id,
  repec_id,
  arxiv_id,
  ads_bibcode,
  handle,
  urn,
  isbn,
  uri
};

const TYPES = Object.keys(HANDLERS);

const extract = (id, text) => {
  if (!validParams(id, text)) return [];

  return HANDLERS[id].extract(text);
};

const normalize = (id, text) => {
  return extract(id, text)[0] || '';
};

const validParams = (id, text) => {
  return TYPES.includes(id) && text;
};

const typeOf = (str) => {
  if (doi.extract(str).length) return 'doi';
  if (isbn.extract(str).length) return 'isbn';
  if (pmid.extract(str).length) return 'pmid';
  if (nct_id.extract(str).length) return 'nct_id';
  if (repec_id.extract(str).length) return 'repec_id';
  if (arxiv_id.extract(str).length) return 'arxiv_id';
  if (ads_bibcode.extract(str).length) return 'ads_bibcode';
  if (uri.extract(str).length) return 'uri';
  if (handle.extract(str).length) return 'handle';
  if (urn.extract(str).length) return 'urn';
};

export default { extract, normalize, typeOf, TYPES };
