import React from 'react';
import PropTypes from 'prop-types';

import Tabs from './Tabs';
import NumberHelper from 'util/number_helper';

import Export from 'components/Explorer/Export';
import SaveSearch from 'components/Explorer/SaveSearch';
import SummaryBlock from 'components/Explorer/ContentPanel/SummaryBlock';

import helpFor from 'util/help.js';
import withFeatureFlag from 'components/FeatureFlipper';

const VerboseMessage = ({ posts, profiles, countries }) => {
  return (
    <>
      <span className="part">{posts} </span>
      <span className="part">{profiles} </span>
      <span className="part">{countries} </span>
    </>
  );
};

const TerseMessage = ({ posts }) => {
  return <span className="part">{posts}</span>;
};

const FeatureFlaggedMessage = withFeatureFlag(TerseMessage, 'timeframe_filtered_mentions', VerboseMessage);

class Summary extends React.PureComponent {
  static propTypes = {
    selectedSource: PropTypes.oneOf(Tabs.TYPES).isRequired,
    data: PropTypes.shape({
      posts: PropTypes.number.isRequired,
      profiles: PropTypes.number.isRequired,
      countries: PropTypes.number.isRequired
    }).isRequired
  };

  render() {
    return (
      <SummaryBlock variant="alt">
        <div>
          <FeatureFlaggedMessage
            posts={this.part('posts')}
            profiles={this.part('profiles')}
            countries={this.part('countries')}
          />
          {this.props.selectedSource === 'tweet' && (
            <div>{I18n.htmlSafe(`Explorer.Demographics.types.tweet.unknown_country_warning`)}</div>
          )}
        </div>

        <Export endpoint="demographics" resultsCount={this.props.data.posts} help={helpFor('demographics')} />
        <SaveSearch endpoint="demographics" analyticsName="Demographics" />
      </SummaryBlock>
    );
  }

  part(name) {
    return I18n.htmlSafe(`Explorer.Demographics.types.${this.props.selectedSource}.counts.${name}`, {
      count: this.props.data[name],
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.props.data[name])
    });
  }
}

export default Summary;
