import React from 'react';

class ImageWithFallback extends React.Component {
  constructor() {
    super();

    this.state = {
      imageError: false
    };
  }

  render() {
    return (
      <div className={`thumbnail ${this.state.imageError ? 'missing-image' : 'present-image'}`}>
        <img src={this.props.src} alt={this.props.alt} onError={this.imageDidError} />
      </div>
    );
  }

  imageDidError = (_e) => {
    this.setState({
      imageError: true
    });
  };
}

export default ImageWithFallback;
