import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import HighmapsWorldData from 'vendor/highmaps-world';
import HighmapsContinentsData from 'vendor/highmaps-continents';

import NumberHelper from 'util/number_helper';
import { each, groupBy, keyBy, map, mergeWith, template, values } from 'lodash-es';

highchartsAccessibility(Highcharts);

const tooltipTemplate = template(
  `<table class="Highlights-Demographics-tooltip">
    <thead>
      <tr>
        <td colspan="3">
          <%= point.name %>:
          <%= I18n.t("Explorer.Highlights.Demographics.post", { count: NumberHelper.formatStatistic(point.value) }) %>
        </td>
      </tr>
    </thead>
    <tbody>
      <% each(['tweet', 'fbwall', 'msm', 'policy'], function (type) { %>
        <% var count = point[type] || 0 %>
        <tr>
          <td class="total"><%= NumberHelper.formatStatistic(count) %></td>
          <td class="type <%= type %>">●</td>
          <td><%= I18n.t("Explorer.Highlights.Demographics." + type, { count: count }) %></td>
        </tr>
      <% }); %>
    </tbody>
  </table>`,
  { imports: { NumberHelper: NumberHelper, each: each } }
);

const countries = keyBy(Highcharts.geojson(HighmapsWorldData), 'properties.iso-a2');

class WorldMap extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      data: this.normalizeData(props.data)
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.data == this.props.data) return;

    this.setState({
      data: this.normalizeData(newProps.data)
    });
  }

  normalizeData(data) {
    return values(data)
      .map((counts) => {
        const country = countries[counts.code];
        if (country) {
          return {
            ...counts,
            continent: country.properties.continent
          };
        }
      })
      .filter((count) => !!count);
  }

  render() {
    return (
      <div className="Highlights-Demographics-map">
        <HighchartsReact
          allowChartUpdate={false}
          highcharts={Highcharts}
          options={this.config}
          constructorType={'mapChart'}
        />
      </div>
    );
  }

  get config() {
    return {
      title: false,
      legend: false,
      credits: { enabled: false },
      mapNavigation: {
        enabled: true,
        enableMouseWheelZoom: false
      },
      chart: {
        height: 300,
        width: 500
      },
      colorAxis: { type: 'logarithmic' },
      tooltip: {
        shadow: false,
        backgroundColor: 'white',
        style: {
          lineHeight: '17px',
          padding: '13px'
        },
        borderWidth: 1,
        borderRadius: 3,
        borderColor: 'rgba(235, 235, 245, 1)',
        useHTML: true,
        formatter: function () {
          return tooltipTemplate({ point: this.point });
        }
      },
      plotOptions: {
        map: {
          joinBy: this.joinBy,
          mapData: this.mapData
        }
      },
      series: [
        {
          data: this.state.data,
          states: {
            hover: {
              color: '#FF0081',
              borderWidth: 1
            }
          }
        }
      ]
    };
  }
}

class ContinentsWorldMap extends WorldMap {
  normalizeData(data) {
    const countriesByContinent = groupBy(super.normalizeData(data), 'continent');

    return map(countriesByContinent, (values, continent) => {
      const continentData = values.reduce((acc, current) =>
        mergeWith(acc, current, (a, b) => (Number.isInteger(a) && Number.isInteger(b) ? a + b : a))
      );

      return {
        ...continentData,
        name: continent
      };
    });
  }

  get mapData() {
    return HighmapsContinentsData;
  }

  get joinBy() {
    return ['name', 'continent'];
  }
}

class CountriesWorldMap extends WorldMap {
  get mapData() {
    return HighmapsWorldData;
  }

  get joinBy() {
    return ['iso-a2', 'code'];
  }
}

export { ContinentsWorldMap, CountriesWorldMap };
