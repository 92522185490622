import { cloneDeep, each, filter, flatMap, flow, isArray, isEqual, mapKeys, mapValues, pick, uniq } from 'lodash-es';

import QueryString from 'query-string';
import config from 'util/site_config.js.erb';
import fetchWithOptions from 'util/fetch';
import request from 'superagent';

// The Query class wraps up an Explorer query for use by front-end components.
//
// The object can be derived in full from a location object extracted from
// the history – so we pass it a full history object, monitor the history for
// any updates, and trigger callbacks when particular parts change so that
// components know they need to re-fetch their data.
//
// To make things simpler, the Query object also provides methods for performing
// the underlying AJAX calls for fetching data, meaning we don't have to keep
// them scattered around the components.
class Query {
  static DEFAULT_SORT = 'score';
  static DEFAULT_MENTION_SOURCE_ORDER = 'mentions_count_desc';
  static DEFAULT_VIEW = 'grid';
  static BASE_PATH = '/explorer';
  static BASE_JSON_PATH = `${Query.BASE_PATH}/json_data`;
  static EVENTS = {
    didChangePage: 'didChangePage',
    didChangeFilters: 'didChangeFilters',
    didChangeSortOrder: 'didChangeSortOrder',
    didChangeMentionSourceOrder: 'didChangeMentionSourceOrder',
    didChangeShowDetails: 'didChangeShowDetails',
    didChangeView: 'didChangeView'
  };

  page = null;
  perPage = null;
  filters = null;
  sortOrder = null;
  mentionSourceOrder = null;
  showDetails = null;
  view = null;
  history = null;
  unlisten = null; // Function returned by history listener – call to stop listening

  // This object stores any callbacks registered, so that we can call them when
  // a change is detected.
  callbacks = {
    didChangePage: [],
    didChangeFilters: [],
    didChangeSortOrder: [],
    didChangeMentionSourceOrder: [],
    didChangeShowDetails: [],
    didChangeView: []
  };

  // A Query is always built from the underlying browser history object, and
  // immediately updated with the data parsed from the current location. We then
  // register as a listener with the history, so we are informed when changes
  // happen.
  constructor(history) {
    this.history = history;
    this.updateData(history.location);
    this.unlisten = this.history.listen(this.updateData.bind(this));
  }

  // Calling cleanup on a query makes it stop listening to change events and
  // remove all callbacks. We call this when a component is unmounted to stop
  // us from continuing to receive change events.
  cleanup() {
    this.unlisten();
    each(this.callbacks, (v, k) => (v[k] = []));
  }

  // updateData is called whenever the location on the history object changes.
  //
  // We look at this location to determine which parts of it have changed – for
  // example, the filters, or the page number. Based on this, we can then
  // trigger any registered callbacks to notify components that they need to
  // fetch new data.
  updateData(location) {
    const params = QueryString.parse(location.search.replace(/^\?/, ''));

    // Unlike the backend, we don't consider sort order to be a 'filter' – this
    // is just a semantic difference, but it's because we don't want to update
    // things that only depend on the filter (e.g. the header text) whenever
    // the sort order changes.
    //
    // We include both the raw parameter names, and a version with trailing
    // square brackets – these are used to indicate an array parameter.
    const filterParams = flow(
      (fp) => filter(fp, (p) => p !== 'order' && p !== 'view' && p !== 'mention_source_order'),
      (fp) => flatMap(fp, (p) => [p, `${p}[]`])
    )(config.queryFilterParams);

    // Extract the contents of these fields from the parameters, falling back to
    // default values if they don't exist.
    const newPage = Number.parseInt(params.page, 10) || 1;
    const newSortOrder = params.order || Query.DEFAULT_SORT;
    const newMentionSourceOrder = params.mention_source_order || Query.DEFAULT_MENTION_SOURCE_ORDER;
    const newShowDetails = Number.parseInt(params.show_details, 10) || undefined;
    const newFilters = pick(params, filterParams);
    const newView = params.view || Query.DEFAULT_VIEW;

    // Check each monitored field and see if it has changed from the current
    // value. If it has, update the stored value and queue the necessary
    // callbacks to inform listeners.

    let callbacks = [];

    if (newPage !== this.page) {
      this.page = newPage;
      callbacks.push(...this.callbacks.didChangePage);
    }

    if (newSortOrder !== this.sortOrder) {
      this.sortOrder = newSortOrder;
      callbacks.push(...this.callbacks.didChangeSortOrder);
    }

    if (newMentionSourceOrder !== this.mentionSourceOrder) {
      this.mentionSourceOrder = newMentionSourceOrder;
      callbacks.push(...this.callbacks.didChangeMentionSourceOrder);
    }

    if (newShowDetails !== this.showDetails) {
      this.showDetails = newShowDetails;
      callbacks.push(...this.callbacks.didChangeShowDetails);
    }

    if (newView !== this.view) {
      this.view = newView;
      callbacks.push(...this.callbacks.didChangeView);
    }

    // Equality is different for filters because we are not comparing
    // a primitive - we need to perform a deep equality check.
    if (!isEqual(newFilters, this.filters)) {
      this.filters = newFilters;
      callbacks.push(...this.callbacks.didChangeFilters);
    }

    // Trigger the callbacks to be informed that something about the query
    // has changed. We make sure to `uniq` this array first, as some callbacks
    // will be registered multiple times for different events, and we only
    // want to call each one once.
    uniq(callbacks).forEach((c) => c());
  }

  // Return a clone of this query with the page number updated to the supplied
  // value.
  withPage(number) {
    const newQuery = cloneDeep(this);

    newQuery.page = number;

    return newQuery;
  }

  // Return a clone of this query with the perPage number updated to the supplied value.
  // perPage is not expected to be changed: there is no logic in place to handle a change
  // in perPage
  withPerPage(number) {
    const newQuery = cloneDeep(this);

    newQuery.perPage = number;

    return newQuery;
  }

  // Return a clone of this query with the filters object updated to the
  // requested value, and the page reset to the first one.
  withFilters(filters) {
    const newQuery = cloneDeep(this);

    newQuery.filters = filters;
    newQuery.page = 1;

    return newQuery;
  }

  // Return a clone of this query with the sort order updated to the supplied
  // value, and the page reset to the first one.
  withSortOrder(order) {
    const newQuery = cloneDeep(this);

    newQuery.sortOrder = order;
    newQuery.page = 1;

    return newQuery;
  }

  withMentionSourceOrder(order) {
    const newQuery = cloneDeep(this);

    newQuery.mentionSourceOrder = order;
    newQuery.page = 1;

    if (order === 'followers_desc') {
      newQuery.filters['mention_sources_types[]'] = 'type:tw';
    }

    return newQuery;
  }

  // Return a clone of this query with the showDetails parameter set
  withShowDetails(id) {
    const newQuery = cloneDeep(this);

    newQuery.showDetails = id;

    return newQuery;
  }

  // Return a clone of this query with the view updated to the supplied
  // value.
  withView(view) {
    const newQuery = cloneDeep(this);

    newQuery.view = view;

    return newQuery;
  }

  // Register a new callback that will be informed when the query changes.
  // Also takes a list of events indicating when the callback should be called.
  registerCallback(callback, ...events) {
    events.forEach((e) => {
      if (!this.callbacks[e]) throw `Trying to register callback for unknown event type ${e}`;

      this.callbacks[e].push(callback);
    });
  }

  // Return a location object that represents this query.
  get location() {
    const params = this.cloneParams();

    return {
      search: QueryString.stringify(params),
      pathname: this.history.location.pathname
    };
  }

  // Return a location with a pathname included in it. This is useful for things
  // like links, where we can call this to get an object to pass into a `Link`
  // component. It also resets the page to 1, so that changing the pathname will
  // always start from the first page.
  locationWithPathname(pathname) {
    const params = this.cloneParams();
    delete params['page'];

    return {
      search: QueryString.stringify(params),
      pathname: pathname
    };
  }

  cloneParams() {
    let params = cloneDeep(this.filters);
    params = this.assignUnfilteredParams(params);

    return params;
  }

  clonePOSTParams() {
    const cleanParams = flow(
      (f) => mapValues(f, (value, key) => (key.endsWith('[]') && !isArray(value) ? [value] : value)),
      (f) => mapKeys(f, (_value, key) => key.replace('[]', ''))
    )(this.filters);

    const params = this.assignUnfilteredParams(cleanParams);
    delete params['page'];

    return params;
  }

  assignUnfilteredParams(params) {
    if (this.page !== 1) params.page = this.page;
    if (this.sortOrder !== Query.DEFAULT_SORT) params.order = this.sortOrder;
    if (this.mentionSourceOrder !== Query.DEFAULT_MENTION_SOURCE_ORDER)
      params.mention_source_order = this.mentionSourceOrder;
    if (this.view !== Query.DEFAULT_VIEW) params.view = this.view;
    if (this.showDetails) params.show_details = this.showDetails;
    if (this.perPage) params.per_page = this.perPage;

    return params;
  }

  saveSearch(endpoint = 'outputs') {
    const path = `${Query.BASE_PATH}/saved_searches`;

    let params = this.clonePOSTParams();
    params.action_path = endpoint;

    return fetchWithOptions(path, {
      method: 'POST',
      body: JSON.stringify(params)
    });
  }

  // Return a listing of research outputs for this query
  researchOutputs() {
    return this.jsonFor('research_outputs');
  }

  // Return a research outputs summary counts for this query
  researchOutputsSummary() {
    return this.jsonFor('research_outputs/summary');
  }

  // Return the timeline summary for this query
  timelineSummary() {
    return this.jsonFor('timeline/summary');
  }

  // Return the descriptive title for this query
  title() {
    return this.jsonFor('metadata/query_description');
  }

  highlightsSummary() {
    return this.jsonFor('highlights/summary');
  }

  highlightsDemographics() {
    return this.jsonFor('highlights/demographics');
  }

  highlightsTopJournals() {
    return this.jsonFor('highlights/top_journals');
  }

  highlightsDistribution() {
    return this.jsonFor('highlights/distribution');
  }

  highlightsBreakdown() {
    return this.jsonFor('highlights/breakdown');
  }

  highlightsNewsHighlights() {
    return this.jsonFor('highlights/news_highlights');
  }

  highlightsTopOutputs() {
    return this.jsonFor('highlights/top_outputs');
  }

  highlightsTopMentionSources() {
    return this.jsonFor('highlights/top_mention_sources');
  }

  highlightsLatestMentions() {
    return this.jsonFor('highlights/latest_mentions');
  }

  highlightsTopAffiliations() {
    return this.jsonFor('highlights/top_affiliations');
  }

  highlightsTopSubjectAreas() {
    return this.jsonFor('highlights/top_subject_areas');
  }

  exportUrls(endpoint) {
    return this.jsonFor(`exports/${endpoint}`);
  }

  identifierList() {
    return this.jsonFor('filters/identifier_list');
  }

  pubmedSearch() {
    return this.jsonFor('filters/pubmed_search');
  }

  dimensionsSearch() {
    return this.jsonFor('filters/dimensions_search');
  }

  mentions() {
    return this.jsonFor('mentions');
  }

  mentionsSummary() {
    return this.jsonFor('mentions/summary');
  }

  publisherNames() {
    return this.jsonFor('filters/publisher_names');
  }

  authorNames() {
    return this.jsonFor('filters/author_names');
  }

  departmentNames() {
    return this.jsonFor('filters/department_names');
  }

  fieldsOfResearch() {
    return this.jsonFor('filters/fields_of_research');
  }

  mentionSourcesForFilter() {
    return this.jsonFor('filters/mention_sources');
  }

  mentionSourcesTypesForFilter() {
    return this.jsonFor('filters/mention_sources_types');
  }

  journalNames() {
    return this.jsonFor('filters/journal_names');
  }

  journalList() {
    return this.jsonFor('filters/journal_list');
  }

  affiliations() {
    return this.jsonFor('filters/affiliations');
  }

  funders() {
    return this.jsonFor('filters/funders');
  }

  journalAnalysis() {
    return this.jsonFor('journals');
  }

  journalAnalysisSummary() {
    return this.jsonFor('journals/summary');
  }

  timeline() {
    return this.jsonFor('timeline');
  }

  demographics(_source) {
    return this.jsonFor('demographics');
  }

  mentionSources() {
    return this.jsonFor('mention_sources');
  }

  mentionSourcesSummary() {
    return this.jsonFor('mention_sources/summary');
  }

  sustainable_development_goals() {
    return this.jsonFor('filters/sustainable_development_goals');
  }

  // Given a JSON data path, fetch the JSON from the server for this query and
  // return it.
  jsonFor(path) {
    let jsonPath = `${Query.BASE_JSON_PATH}/${path}?${this.location.search}`;

    return request
      .get(jsonPath)
      .set('X-Requested-With', 'XMLHttpRequest')
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');
  }
}

export default Query;
