import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import NumberHelper from 'util/number_helper';

import Query from 'components/Explorer/models/query';
import Source from './Source';

const InspectorSource = ({ history, source, isFocused }) => {
  const query = new Query(history);
  const style = { backgroundColor: source.color };
  const link = () => {
    const filters = query.cloneParams();
    filters['mention_sources[]'] = [`type:${source.name}`];
    const url = query.withFilters(filters).locationWithPathname('mentions');

    return `${url.pathname}?${url.search}`;
  };

  useEffect(() => {
    if (isFocused) {
      anchorEl.current.focus();
    }
  }, []);

  const anchorEl = useRef();

  return (
    <div className={`source ${source.name}`}>
      <a href={link()} ref={anchorEl}>
        <div className="title">
          <div className="swatch" style={style} />
          {I18n.t(source.name, {
            scope: 'Explorer.Highlights.Breakdown.sources'
          })}
        </div>

        <div className="count">
          {I18n.t('Explorer.Highlights.Breakdown.mentions', {
            count: source.mentionCount,
            formattedCount: NumberHelper.formatNumberWithDelimiter(source.mentionCount)
          })}
        </div>
      </a>
    </div>
  );
};

InspectorSource.propTypes = {
  source: PropTypes.instanceOf(Source).isRequired,
  history: PropTypes.object,
  isFocused: PropTypes.bool
};

export default withRouter(InspectorSource);
