import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import AddSourceDropdown from './AddSourceDropdown';

import AutocompleteField from 'components/Explorer/AutocompleteField';
import AutocompleteFieldConfig from 'components/Explorer/models/autocomplete_field_config';
import FilterSet from 'components/Explorer/models/filter_set';
import Query from 'components/Explorer/models/query';

class MentionSourcesFilter extends React.Component {
  state = {
    loading: true,
    mentionSources: []
  };

  static propTypes = {
    history: PropTypes.object,
    sourcesTypes: PropTypes.object.isRequired,
    filterSet: PropTypes.instanceOf(FilterSet).isRequired,
    queryFilter: PropTypes.string.isRequired,
    assignRef: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    dropdownSourcesType: PropTypes.string.isRequired,
    autocompleteFieldConfig: PropTypes.instanceOf(AutocompleteFieldConfig).isRequired
  };

  static defaultProps = {
    disabled: false
  };

  UNSAFE_componentWillMount() {
    this.props.assignRef(this);
    this.query = new Query(this.props.history);

    if (this.props.filterSet.get(this.props.autocompleteFieldConfig.name).length) {
      this.fetchData();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-FilterPanel-field Explorer-MentionSourcesField">
        {this.state.loading ? this.loadingMessage : this.form}
      </div>
    );
  }

  get loadingMessage() {
    return <div>{I18n.t('Explorer.Mentions.FilterPanel.fields.mention_sources.loading')}</div>;
  }

  get form() {
    return (
      <div>
        <div className="Explorer-MentionSourcesField-wrapper">
          <AddSourceDropdown
            sourcesTypes={this.props.sourcesTypes}
            addSelectedSource={this.addSelectedSource}
            disabled={this.props.disabled}
          />
          {this.props.disabled ? this.fakeSearchField : this.searchField}
        </div>
      </div>
    );
  }
  get fakeSearchField() {
    return <div className="FakeAutocompleteField" data-testid="fake-autocomplete"></div>;
  }

  get searchField() {
    return (
      <AutocompleteField
        id={this.props.autocompleteFieldConfig.id}
        name={this.props.autocompleteFieldConfig.name}
        type={this.props.autocompleteFieldConfig.type}
        placeholder={this.props.autocompleteFieldConfig.placeholder}
        onChangeItems={this.updateSelectedMentionSources}
        initiallySelected={this.state.mentionSources}
        ref={this.storeAutocompleteField}
        delayShortQuery={true}
      />
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = async () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query[this.props.queryFilter]();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      if (!err) {
        this.setState({ loading: false, mentionSources: response.body });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  // Actions
  //////////////////////////////////////////////////////////////////////////////

  storeAutocompleteField = (field) => {
    this.autocompleteField = field;
  };

  sourceFromType = (sourceType) => {
    const source = {
      id: `type:${sourceType}`,
      type: this.props.dropdownSourcesType
    };

    switch (this.props.dropdownSourcesType) {
      case 'post_type':
        source['post_type'] = sourceType;
        break;
      case 'mention_source_type':
        source['mention_source_type'] = sourceType;
        break;
    }

    return this.autocompleteField.modelizeResult(source);
  };

  addSelectedSource = (sourceType) => {
    const mentionSource = this.sourceFromType(sourceType);
    this.autocompleteField.selectResult(mentionSource);
  };

  updateSelectedMentionSources = (mentionSources) => {
    Analytics.trackEvent('mentions-update-sources');
    this.props.filterSet.set(
      this.props.autocompleteFieldConfig.name,
      mentionSources.map((source) => source.id)
    );
  };

  clear() {
    this.clearSelected();
    this.updateSelectedMentionSources([]);
  }

  overrideSelectedMentionSourcesFromTypes(sourceTypes) {
    if (this.autocompleteField) {
      const sources = sourceTypes.map((sourceType) => this.sourceFromType(sourceType));
      this.overrideSelectedMentionSources(sources);
    }
  }

  overrideSelectedMentionSources(mentionSources) {
    if (this.autocompleteField) this.autocompleteField.overrideSelectedResults(mentionSources);
  }

  // Removes selected mention sources, keeping filterSet untouched
  clearSelected() {
    if (this.autocompleteField) this.autocompleteField.clearAll();
  }
}

export default withRouter(MentionSourcesFilter);
