import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';

import { includes, truncate } from 'lodash-es';
import dayjs from 'util/date';
import striptags from 'striptags';

import LinkIf from 'components/Explorer/LinkIf';
import OutputsList from 'components/Explorer/OutputsList';
import QuotesIcon from 'icons/quotes-left.svg';

const VISIBLE_OUTPUTS_COUNT = 2;

class LatestMention extends React.Component {
  static propTypes = {
    post: PropTypes.object
  };

  state = {
    visible: false
  };

  render() {
    return (
      <div key={this.props.post.id} className="container">
        <div className="content">
          <div className={`marker ${this.props.post.postType}`}>
            <div className="icon"></div>
          </div>
          <div className="title">{this.postTitle}</div>
          {this.postBody}
          {this.outputs}
        </div>
      </div>
    );
  }

  get postTitle() {
    return (
      <LinkIf condition={this.hasURL} url={this.props.post.url}>
        <span>
          {I18n.t('Explorer.Highlights.LatestMentions.mentioned')}{' '}
          <TimeAgo date={dayjs(this.props.post.postedAt).format('YYYY-MM-DD h:mm A UTC')} /> {this.sourceDescription}
        </span>
      </LinkIf>
    );
  }

  get postBody() {
    if (!this.props.post.body) return;

    return (
      <p className="quote">
        <QuotesIcon className="icon" />
        {truncate(striptags(this.props.post.body), {
          length: 250,
          separator: /\W/
        })}
      </p>
    );
  }

  toggleOutputsVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };

  toggleOutputsVisibilityButtonText = (outputsCount) => {
    if (this.state.visible) {
      return I18n.t('Explorer.Highlights.LatestMentions.show_fewer');
    } else {
      return I18n.t('Explorer.Highlights.LatestMentions.cites_more', {
        count: Math.max(outputsCount - VISIBLE_OUTPUTS_COUNT, 0)
      });
    }
  };

  get outputs() {
    const outputsCount = this.props.post.outputs.length;

    return (
      <div className="outputs-container">
        <p className="outputs-title">
          {I18n.t('Explorer.Highlights.LatestMentions.cites', {
            count: outputsCount
          })}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events -- [TODO] */}
          <span onClick={this.toggleOutputsVisibility} className="truncated-toggle">
            {this.toggleOutputsVisibilityButtonText(outputsCount)}
          </span>
          :
        </p>
        <OutputsList
          post={this.props.post}
          outputs={this.props.post.outputs}
          visible={this.state.visible}
          visibleOutputsCount={VISIBLE_OUTPUTS_COUNT}
        />
      </div>
    );
  }

  get sourceDescription() {
    const sourcesWithName = ['tweet', 'msm', 'blog', 'policy', 'weibo', 'fbwall', 'gplus', 'rdt'];

    if (
      (!this.props.post.profile || !this.props.post.profile.name || !this.props.post.profile.name.length) &&
      includes(sourcesWithName, this.props.post.postType)
    )
      return;

    const options = {};
    if (this.props.post.profile && this.props.post.profile.name && this.props.post.profile.name.length) {
      options.name = this.props.post.profile.name;
    }

    return I18n.t(`Explorer.Highlights.LatestMentions.type_descriptions.${this.props.post.postType}`, options);
  }

  get hasURL() {
    return this.props.post.url && this.props.post.url.length > 0;
  }
}

export default LatestMention;
