import './styles';

import React, { useMemo, useState, useEffect } from 'react';

import { useJournalAnalysis } from './hooks';
import LoadingMessage from './LoadingMessage';
import ErrorMessage from './ErrorMessage';
import EmptyMessage from './EmptyMessage';
import AnalysisTable from './AnalysisTable';

import FilterSet from 'components/Explorer/models/filter_set';
import NumberHelper from 'util/number_helper';
import Query from 'components/Explorer/models/query';

const COLUMNS = [
  {
    name: I18n.t('Explorer.Journals.headers.journal_collection'),
    accessor: 'title',
    sortDirection: 'asc'
  },
  {
    name: I18n.t('Explorer.Journals.headers.total_mentions'),
    accessor: 'total_mentions',
    type: 'number'
  },
  { name: I18n.t('Explorer.Journals.headers.news_mentions'), accessor: 'msm', type: 'number' },
  { name: I18n.t('Explorer.Journals.headers.blog_mentions'), accessor: 'blog', type: 'number' },
  { name: I18n.t('Explorer.Journals.headers.policy_mentions'), accessor: 'policy', type: 'number' },
  { name: I18n.t('Explorer.Journals.headers.patent_mentions'), accessor: 'patent', type: 'number' },
  { name: I18n.t('Explorer.Journals.headers.twitter_mentions'), accessor: 'tweet', type: 'number' },
  {
    name: I18n.t('Explorer.Journals.headers.peer_reviews_mentions'),
    accessor: 'peer_review',
    type: 'number'
  },
  {
    name: I18n.t('Explorer.Journals.headers.sina_weibo_mentions'),
    accessor: 'weibo',
    type: 'number'
  },
  {
    name: I18n.t('Explorer.Journals.headers.facebook_mentions'),
    accessor: 'fbwall',
    type: 'number'
  },
  {
    name: I18n.t('Explorer.Journals.headers.wikipedia_mentions'),
    accessor: 'wikipedia',
    type: 'number'
  },
  { name: I18n.t('Explorer.Journals.headers.google_mentions'), accessor: 'gplus', type: 'number' },
  {
    name: I18n.t('Explorer.Journals.headers.linked_in_mentions'),
    accessor: 'linkedin',
    type: 'number'
  },
  { name: I18n.t('Explorer.Journals.headers.reddit_mentions'), accessor: 'rdt', type: 'number' },
  {
    name: I18n.t('Explorer.Journals.headers.pinterest_mentions'),
    accessor: 'pinterest',
    type: 'number'
  },
  {
    name: I18n.t('Explorer.Journals.headers.faculty_opinions_mentions'),
    accessor: 'f1000',
    type: 'number'
  },
  { name: I18n.t('Explorer.Journals.headers.q_a_mentions'), accessor: 'qna', type: 'number' },
  { name: I18n.t('Explorer.Journals.headers.video_mentions'), accessor: 'video', type: 'number' },
  {
    name: I18n.t('Explorer.Journals.headers.syllabi_mentions'),
    accessor: 'syllabus',
    type: 'number'
  }
];

const JournalsAnalysisTable = ({ history }) => {
  const [query] = useState(new Query(history));
  const [mode, setMode] = useState('loading');

  const setModeToLoading = () => setMode('loading');
  const setModeToOK = () => setMode('ok');
  const setModeToError = () => setMode('error');

  const sortByMentionedResearchOutputs = useMemo(() => {
    const mentions = (row) => {
      if (!row || !row?.original?.mentioned_research_outputs) return 0;
      return parseInt(row.original.mentioned_research_outputs);
    };

    return (rowA, rowB, desc) => {
      const [valA, valB] = [mentions(rowA), mentions(rowB)];
      if (desc) {
        if (valA > valB) return 1;
        if (valA < valB) return -1;
      } else {
        if (valA < valB) return -1;
        if (valA > valB) return 1;
      }
      return 0;
    };
  });

  const journalAnalysis = useJournalAnalysis(query, {
    onFetchStarted: setModeToLoading,
    onFetchSuccess: setModeToOK,
    onFetchError: setModeToError
  });

  const goToJournal = useMemo(() => {
    return (journalId) => {
      const filterSet = new FilterSet(query.filters);
      filterSet.set('journal_id[]', journalId);
      history.push(query.withFilters(filterSet.populatedFilters).locationWithPathname('outputs'));
    };
  });

  useEffect(() => {
    query.registerCallback(() => journalAnalysis.refresh(), Query.EVENTS.didChangeFilters);
  }, [query]);

  const data = useMemo(() => {
    return journalAnalysis.rows.map((row) => {
      const { mentioned_research_outputs } = row;

      return COLUMNS.reduce(
        (result, { accessor }) => {
          return { ...result, [accessor]: row[accessor] };
        },
        { mentioned_research_outputs, source: row }
      );
    });
  }, [journalAnalysis]);

  const columns = useMemo(() => {
    return COLUMNS.map(({ name: Header, accessor, sortDirection }, index) => {
      const sortType = index == 0 ? sortByMentionedResearchOutputs : 'basic';
      const sortDescFirst = sortDirection !== 'asc';
      return {
        Header,
        accessor,
        sortType,
        sortDescFirst,
        Cell: ({ value }) => NumberHelper.formatNumberWithDelimiter(value)
      };
    });
  }, []);

  const Messages = () => {
    if (mode === 'loading') return <LoadingMessage />;
    if (mode === 'error') return <ErrorMessage />;
    if (data.length < 1) return <EmptyMessage />;
    return null;
  };

  return (
    <div className="Explorer-Journals-Table-Container">
      <AnalysisTable {...{ columns, data, goToJournal, resultsPerPage: 50 }} />
      <Messages />
    </div>
  );
};

export default JournalsAnalysisTable;
