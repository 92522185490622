import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import MentionedIcon from 'icons/chat.svg';
import OutputIcon from 'icons/file.svg';
import MentionsIcon from 'icons/bubbles.svg';

import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';

import SaveSearch from 'components/Explorer/SaveSearch';

class Summary extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static propTypes = {
    history: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      data: {}
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return <div className="Highlights-Summary">{this.content}</div>;
  }

  get loadingMessage() {
    return <div className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div className="Highlights-content Highlights-Summary-content">
        <div className="stats">
          <div className="stat mentions">
            <div className="icon">
              <MentionsIcon />
            </div>
            <div className="content">
              <div className="title">{I18n.t('Explorer.Highlights.Summary.total_mentions')}</div>
              <div className="count">
                {this.state.loading ? this.loadingMessage : NumberHelper.formatStatistic(this.state.data.mentions)}
              </div>
            </div>
          </div>

          <div className="stat mentioned_outputs">
            <div className="icon">
              <MentionedIcon />
            </div>
            <div className="content">
              <div className="title">{I18n.t('Explorer.Highlights.Summary.mentioned_outputs')}</div>
              <div className="count">
                {this.state.loading
                  ? this.loadingMessage
                  : NumberHelper.formatStatistic(this.state.data.research_outputs)}
              </div>
            </div>
          </div>

          <div className="stat total_outputs">
            <div className="icon">
              <OutputIcon />
            </div>
            <div className="content">
              <div className="title">{I18n.t('Explorer.Highlights.Summary.outputs')}</div>
              <div className="count">
                {this.state.loading
                  ? this.loadingMessage
                  : NumberHelper.formatStatistic(this.state.data.total_research_outputs)}
              </div>
            </div>
          </div>
        </div>
        <div className="tools">
          <SaveSearch endpoint="highlights" analyticsName="Highlights" />
        </div>
      </div>
    );
  }

  get errorMessage() {
    return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsSummary();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body;
      }

      this.setState({
        loading: false,
        loadingFailed: loadingFailed,
        data: data
      });
    });
  };
}

export default withRouter(Summary);
