import './styles';

import React from 'react';
import PropTypes from 'prop-types';

const SearchLoadingIcon = (props) => {
  const className = props.loading ? 'loading' : 'not-loading';

  return (
    <svg className={`SearchLoadingIcon ${className}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <g className="SearchLoadingIcon-ring-wrapper">
        <path
          className="SearchLoadingIcon-ring"
          fill="#34373E"
          d="M396.8 209.3c49.8 0 96.7 19.4 131.9 54.6s54.6 82.1 54.6 131.9-19.4 96.7-54.6 131.9-82.1 54.6-131.9 54.6-96.7-19.4-131.9-54.6-54.6-82.1-54.6-131.9 19.4-96.7 54.6-131.9 82-54.6 131.9-54.6m0-84c-149.4 0-270.6 121.1-270.6 270.6s121.1 270.6 270.6 270.6 270.6-121.1 270.6-270.6-121.2-270.6-270.6-270.6z"
        />
      </g>
      <g className="SearchLoadingIcon-handle-wrapper">
        <path
          className="SearchLoadingIcon-handle"
          fill="#34373E"
          d="M552.674 615.244l64.346-64.346 246.637 246.636-64.346 64.346z"
        />
      </g>
    </svg>
  );
};

SearchLoadingIcon.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default SearchLoadingIcon;
