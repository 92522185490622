import React from 'react';

const LoadingMessage = () => {
  return (
    <div className="Journals-Table-Message-Container Journals-Table-Loading-Message">
      <div className="spinner"></div>
      <p>{I18n.t('Explorer.Journals.loading')}</p>
    </div>
  );
};

export default LoadingMessage;
