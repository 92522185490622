import React from 'react';

export default class FooterLinks extends React.Component {
  render() {
    return (
      <ul className="footer-links">
        <li>
          <a href="https://www.altmetric.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            {I18n.t('footer.privacy_policy')}
          </a>
        </li>
        <li>
          <a href="https://www.altmetric.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
            {I18n.t('footer.terms_of_use')}
          </a>
        </li>
        <li>
          <a href="https://www.altmetric.com/cookie-policy/" target="_blank" rel="noopener noreferrer">
            {I18n.t('footer.cookie_policy')}
          </a>
        </li>
      </ul>
    );
  }
}
