import React from 'react';
import { delay } from 'lodash-es';

import Hierarchy from '../../models/hierarchy.js';

import { UploadWidget } from './UploadWidget';

class UploadHierarchyPanel extends React.Component {
  render() {
    return (
      <div className="DatasetUploadPopover-UploadHierarchyPanel DatasetUploadPopover-ContentPanel">
        {this.documentation}

        <div className="DatasetUploadPopover-hierarchy-cta-panel">
          <UploadWidget onSelectFile={this.selectFile}>{I18n.t('dataset_upload.upload_hierarchy.upload')}</UploadWidget>
          {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
          <a className="skip" onClick={this.skip}>
            {I18n.t('dataset_upload.upload_hierarchy.skip')}
          </a>
        </div>
      </div>
    );
  }

  // This method is not internationalized. Consider extracting it in the future.
  get documentation() {
    return (
      <div className="DatasetUploadPopover-documentation-panel">
        <div className="header-icon">
          <h2>{I18n.t('dataset_upload.upload_hierarchy.title')}</h2>
          <a
            className="help"
            href={I18n.t('dataset_upload.upload_hierarchy.help_uri')}
            title={I18n.t('dataset_upload.help_title')}
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </div>
        <p>
          First, you need to upload a CSV spreadsheet file that lists your institution&apos;s groups or departments. You
          can submit your groups as either a flat list, or as a hierarchy. To create a hierarchy, follow the format
          shown below. If you are uploading a flat list of groups, you only need to populate the first column of the
          spreadsheet.
        </p>
        <p>
          For more details, examples, and help creating your group spreadsheet,{' '}
          <a href={I18n.t('dataset_upload.upload_hierarchy.help_uri')} target="_blank" rel="noopener noreferrer">
            click here to read our support documentation.
          </a>
        </p>

        <h3>Example groups spreadsheet</h3>
        <div className="DatasetUploadPopover-csv-example-table">
          <table>
            <thead>
              <tr>
                <th className="row">1</th>
                <th>Top level</th>
                <th>Level 1</th>
                <th>Level 2</th>
                <th>Level 3</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="row">2</th>
                <td>Faculty of Arts</td>
                <td>Department of Dance</td>
                <td>Breakdance Group</td>
                <td></td>
              </tr>
              <tr>
                <th className="row">3</th>
                <td>Faculty of Arts</td>
                <td>Department of Music</td>
                <td>Oboe Studies</td>
                <td></td>
              </tr>
              <tr>
                <th className="row">4</th>
                <td>Faculty of Arts</td>
                <td>Department of Music</td>
                <td>Piano Group</td>
                <td>Pedagogy</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          When you have formatted your groups into a CSV spreadsheet that matches this format, click the button below to
          upload it.
        </p>
      </div>
    );
  }

  skip = (e) => {
    e.preventDefault();

    let message = I18n.t('dataset_upload.upload_hierarchy.skip_warning');
    let confirmedSkip = window.confirm(message);

    if (confirmedSkip) {
      this.props.onSkip();
    }
  };

  selectFile = (file) => {
    this.props.setLoadingState(true);

    delay(() => {
      const hierarchy = new Hierarchy(file);

      hierarchy.onLoad = () => {
        this.props.setLoadingState(false);
        if (this.props.onUpload) this.props.onUpload(hierarchy);
      };

      hierarchy.load();
    }, 500);
  };
}

export { UploadHierarchyPanel };
