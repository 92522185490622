import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';

class Pagination extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    lastPage: PropTypes.bool,
    onChangePage: PropTypes.func
  };

  static defaultProps = {
    lastPage: false,
    marginBottom: false
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  render() {
    return (
      <div className="Explorer-Pagination">
        {this.previousNavLink}
        {this.separator}
        {this.nextNavLink}
      </div>
    );
  }

  get previousNavLink() {
    if (this.query.page <= 1) return;

    const newPage = this.query.page - 1;

    return (
      <Link onClick={() => this.trackChange(newPage)} to={this.query.withPage(newPage).location} className="previous">
        &larr; {I18n.t('Explorer.Pagination.previous')}
      </Link>
    );
  }

  get separator() {
    if (this.query.page <= 1 || this.props.lastPage) return;

    return <span className="separator">|</span>;
  }

  get nextNavLink() {
    if (this.props.lastPage) return;

    const newPage = this.query.page + 1;

    return (
      <Link onClick={() => this.trackChange(newPage)} to={this.query.withPage(newPage).location} className="next">
        {I18n.t('Explorer.Pagination.next')} &rarr;
      </Link>
    );
  }

  trackChange = (newPage) => {
    if (!this.props.onChangePage) return;

    this.props.onChangePage(newPage);
  };
}

export default withRouter(Pagination);
