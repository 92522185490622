import React from 'react';
import { SavingMessage } from './DatasetUploadPopover/SavingMessage';
import { delay } from 'lodash-es';

const MSG_INTERVAL = 4000;

class DatasetPublishProgressPanel extends React.Component {
  constructor() {
    super();

    this.state = {
      extraSaveMessages: [],
      messageIndex: 0,
      extraSaveMessage: null
    };
  }

  render() {
    return (
      <div className="DatasetUploadPopover">
        {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
        <a className="DatasetUploadPopover-overlay" />
        <div className="DatasetUploadPopover-popover forced-small">
          <div className="DatasetUploadPopover-content Popover-content">
            <SavingMessage extraMessage={this.state.extraSaveMessage} />
            {this.props.showCloseButton ? (
              <button className="DatasetUploadPopover-close-button" onClick={this.refreshAndClose}>
                Close
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const extraSaveMessages = I18n.t('dataset_upload.saving.extra_messages');
    this.setState({ extraSaveMessages });

    if (this.props.dataset) {
      this.props.dataset.publish({
        onProgress: () => {
          this.updateExtraSaveMessage();
        }
      });
    } else {
      this.messageInterval = setInterval(this.updateExtraSaveMessage.bind(this), MSG_INTERVAL);
    }
  }

  componentWillUnmount() {
    clearInterval(this.messageInterval);
  }

  updateExtraSaveMessage() {
    const { extraSaveMessages, messageIndex } = this.state;
    const nextIndex = (messageIndex + 1) % extraSaveMessages.length;
    const extraSaveMessage = extraSaveMessages[nextIndex];
    this.setState({ extraSaveMessage, messageIndex: nextIndex });
  }

  close = () => {
    require('../modules/admin_panel').hidePublishingPopovers();
  };

  refreshAndClose = () => {
    require('../modules/admin_panel').refreshCSVPanel();
    delay(this.close, 1500);
  };
}

export { DatasetPublishProgressPanel };
