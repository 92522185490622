import Papa from 'papaparse';
import { normalize } from '../util/normalize';
import { compact, countBy, find, some } from 'lodash-es';

class Hierarchy {
  constructor(file) {
    this.file = file;
    this.onLoad = null;
    this.results = [];
    this.header = [];
    this.hasErroredNodes = false;
    this.root = new Node();
  }

  load() {
    Papa.parse(this.file, {
      header: false,
      skipEmptyLines: true,
      beforeFirstChunk: (chunk) => {
        let rows = chunk.split(/\r\n|\r|\n/);
        this.header = rows.shift().split(',');
        return rows.join('\r\n');
      },
      chunk: (results) => {
        this.results = this.results.concat(results.data);
      },
      complete: () => {
        this.trimNames();
        this.buildTree();
        this.onLoad();
      }
    });
  }

  trimNames() {
    this.results = this.results.map((row) => {
      return row.map((field) => {
        return field.trim();
      });
    });
  }

  buildTree() {
    this.results.forEach((result) => {
      this.root.createPath(result, []);
    });

    this.validateNames();

    this.root.walk((node) => {
      if (node.errors.length > 0) {
        this.hasErroredNodes = true;
      }
    });
  }

  validateNames() {
    let names = [];
    this.root.walk((node) => names.push(normalize(node.name)));

    let counts = countBy(names);
    this.root.walk((node) => {
      if (counts[normalize(node.name)] > 1) {
        node.errors.push(I18n.t('dataset_upload.verify_hierarchy.errors.duplicate'));
      }
    });
  }

  get totalCount() {
    return this.results.length;
  }

  get extractedCount() {
    return this.root.count - 1;
  }

  get valid() {
    return this.hasValidHeader && !this.hasErroredNodes;
  }

  get hasValidHeader() {
    let headers = compact(this.header);
    return (
      headers[0] &&
      headers[0].match(/Top Level/i) &&
      (!headers[1] || headers[1].match(/Level 1/i)) &&
      (!headers[2] || headers[2].match(/Level 2/i)) &&
      (!headers[3] || headers[3].match(/Level 3/i))
    );
  }

  toJSON() {
    return this.root.toJSON();
  }
}

class Node {
  constructor(name) {
    this.name = name;
    this.errors = [];
    this.children = [];
  }

  createPath(path) {
    let next = path[0];

    // If the next path component is present, descend and create more children
    if (next && next.length > 0) {
      let child = this.findChild(next);
      if (!child) {
        child = this.createChild(next);
      }
      child.createPath(path.slice(1));

      // The next path component is present, but there are children specified
      // later in the same path! This means the imported data is invalid, because
      // the user has left a column blank.
    } else if (some(path, (e) => e && e.length > 0)) {
      this.errors.push(I18n.t('dataset_upload.verify_hierarchy.errors.missing_components'));
    }
  }

  findChild(name) {
    return find(this.children, (c) => c.name === name);
  }

  createChild(name) {
    let child = new Node(name);
    this.children.push(child);
    return child;
  }

  walk(callback) {
    callback(this);
    this.children.forEach((child) => child.walk(callback));
  }

  get count() {
    if (this.children.length === 0) {
      return 1;
    } else {
      return (
        1 +
        this.children.reduce((acc, val) => {
          return (acc += val.count);
        }, 0)
      );
    }
  }

  get valid() {
    return this.errors.length == 0;
  }

  toJSON() {
    return {
      name: this.name,
      children: this.children.map((child) => child.toJSON())
    };
  }
}

export default Hierarchy;
