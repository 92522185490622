import { useEffect } from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ metaTitle }) => {
  useEffect(() => {
    document.title = metaTitle + ' - ' + I18n.t('site.default_meta_title');
    window.altmetric.page_name = metaTitle;

    return () => {
      document.title = I18n.t('site.default_meta_title');
      window.altmetric.page_name = document.title;
    };
  }, []);

  return null;
};

PageTitle.propTypes = {
  metaTitle: PropTypes.string.isRequired
};

export default PageTitle;
