import React from 'react';

const Pagination = ({
  pageSize,
  pageIndex,
  pageCount,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage
}) => {
  return (
    <div className="Explorer-Journals-Pagination-Container">
      <button className="btn--first" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {'<<'}
      </button>
      <button className="btn--previous" onClick={previousPage} disabled={!canPreviousPage}>
        {'<'}
      </button>
      <span>
        {I18n.htmlSafe('Explorer.Journals.pagination.current_page_info', {
          currentPage: pageIndex + 1,
          pageCount
        })}
      </span>
      <button className="btn--next" onClick={nextPage} disabled={!canNextPage}>
        {'>'}
      </button>
      <button className="btn--last" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {'>>'}
      </button>
      <span> {I18n.htmlSafe('Explorer.Journals.pagination.results_per_page', { pageSize })}</span>
    </div>
  );
};

export default Pagination;
