import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';

import Export from 'components/Explorer/Export';
import SaveSearch from 'components/Explorer/SaveSearch';
import SummaryBlock from 'components/Explorer/ContentPanel/SummaryBlock';

import helpFor from 'util/help.js';

class Summary extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  query = null;

  static propTypes = {
    history: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingFailed: false,
      posts: null,
      mentions: null
    };

    this.query = new Query(this.props.history);
  }

  componentDidMount() {
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <SummaryBlock hasWarning={this.state.mentions === 0}>
        {this.state.loading ? (
          I18n.t('Explorer.Mentions.Summary.loading')
        ) : (
          <>
            <div>{this.summarySentence}</div>
            <Export endpoint="mentions" resultsCount={this.state.mentions || 0} help={helpFor('mentions')} />
            <SaveSearch endpoint="mentions" analyticsName="Mentions" />
          </>
        )}
      </SummaryBlock>
    );
  }

  get summarySentence() {
    if (this.state.loadingFailed) return I18n.t('Explorer.Mentions.Summary.error');

    const postsSentence = I18n.t('Explorer.Mentions.Summary.individual_posts', {
      count: this.state.posts,
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.posts)
    });

    const sentence = I18n.htmlSafe('Explorer.Mentions.Summary.result_description', {
      count: this.state.mentions,
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.state.mentions),
      postsSentence: postsSentence
    });

    return sentence;
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.mentionsSummary();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let newState = { posts: null, mentions: null };
      const loadingFailed = err || typeof response.body === 'undefined';
      if (!loadingFailed) {
        newState = {
          posts: response.body.posts,
          mentions: response.body.mentions
        };
      }

      this.setState({
        loading: false,
        loadingFailed: loadingFailed,
        ...newState
      });
    });
  };
}

export default withRouter(Summary);
