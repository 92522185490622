const Author = class {
  constructor(result) {
    this.id = result.id;
    this.first_name = result.first_name;
    this.last_name = result.last_name;
    this.departments = result.departments;
    this.description = `${this.first_name} ${this.last_name}`;
  }
};

const Department = class {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = this.name;
  }
};

const Publisher = class {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
    this.description = this.name;
  }
};

const Journal = class {
  constructor(result) {
    this.id = result.id;
    this.title = result.title;
    this.issns = result.issns;
    this.description = this.title;
  }
};

const DoiPrefix = class {
  constructor(result) {
    this.id = result.id;
    this.description = result.id;
  }
};

const HandlePrefix = class {
  constructor(result) {
    this.id = result.id;
    this.description = result.id;
  }
};

const UberResearchInstitution = class {
  constructor(result) {
    this.id = result.id;
    this.name = result.name;
  }
};

const MentionSource = class {
  constructor(result) {
    this.id = result.id;
    this.type = result.type;

    switch (this.type) {
      case 'mention_source':
        this.profileType = result.profile_type;
        this.name = result.name;
        this.description = I18n.t(result.profile_type, {
          scope: 'mentions.filter.mention_source_type_descriptions',
          name: result.name
        });
        this.imageURL = result.image_url;
        break;
      case 'post_type': {
        this.postType = result.post_type;
        const all = I18n.t('mentions.filter.all');
        const typeDescription = I18n.t(result.post_type, {
          scope: 'mentions.filter.mention_source_post_types'
        });
        this.description = `${all} ${typeDescription}`;
        break;
      }
    }
  }
};

const Country = class {
  constructor(result) {
    this.id = result.code;
    this.description = result.name;
    this.name = result.name;
  }
};

export default {
  Author,
  Department,
  Publisher,
  Journal,
  DoiPrefix,
  HandlePrefix,
  UberResearchInstitution,
  MentionSource,
  Country
};
