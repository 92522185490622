import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'components/Explorer/Transition';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';

import Gauge from 'components/Explorer/Gauge';
import Query from 'components/Explorer/models/query';
import NumberHelper from 'util/number_helper';

class TopJournals extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static COLORS = ['#03A9F4', '#E91E63', '#FFA000', '#4CAF50', '#673AB7'];

  state = {
    loading: true,
    loadingFailed: false,
    data: {}
  };

  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.fetchData, Query.EVENTS.didChangeFilters);

    this.fetchData();
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Highlights-TopJournals Highlights-block">
        <h2 className="Highlights-title">{I18n.t('Explorer.Highlights.TopJournals.title')}</h2>

        <div className="Highlights-content-wrapper">
          <Transition classNames="Content" timeout={250}>
            {this.state.loading ? this.loadingMessage : this.content}
          </Transition>
          {this.jumpLink}
        </div>
      </div>
    );
  }

  get loadingMessage() {
    return <div key="loadingMessage" className="Highlights-loading-message" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div key="content" className="Highlights-content Highlights-TopJournals-content">
        {this.hasResults ? this.contentTable : this.emptyMessage}
      </div>
    );
  }

  get errorMessage() {
    return <div className="Highlights-error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get contentTable() {
    return (
      <table className="content-table">
        <tbody>{this.state.data.map((rank, index) => this.journalRank(rank, index))}</tbody>
      </table>
    );
  }

  get emptyMessage() {
    return <div className="Highlights-content-empty-message">{I18n.t('Explorer.Highlights.TopJournals.empty')}</div>;
  }

  get jumpLink() {
    if (!this.hasResults) return;

    return (
      <Link
        onClick={this.trackJumpLink}
        to={this.query.locationWithPathname('/journals')}
        className="Highlights-jump-link"
      >
        {I18n.t('Explorer.Highlights.TopJournals.more')}
      </Link>
    );
  }

  journalRank(rank, index) {
    return (
      <tr key={rank.journal.id}>
        <td className="percent">
          <Gauge percentage={rank.percent} color={TopJournals.COLORS[index % TopJournals.COLORS.length]} />
          <span className="number">
            {NumberHelper.formatPercentage(rank.percent)}
            <sup>%</sup>
          </span>
        </td>
        <td className="description">
          <div className="title">
            <Link to={this.linkForJournal(rank.journal)}>{rank.journal.title}</Link>
          </div>
          <div className="meta">
            {I18n.htmlSafe('Explorer.Highlights.TopJournals.meta.outputs', {
              count: rank.output_count,
              formattedCount: NumberHelper.formatStatistic(rank.output_count)
            })}
            <span>&nbsp;</span>
            {I18n.htmlSafe('Explorer.Highlights.TopJournals.meta.mentions', {
              count: rank.count,
              formattedCount: NumberHelper.formatStatistic(rank.count)
            })}
          </div>
        </td>
      </tr>
    );
  }

  linkForJournal(journal) {
    const filters = this.query.cloneParams();
    filters['journal_id[]'] = [journal.id];

    return this.query.withFilters(filters).locationWithPathname('/mentions');
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    this.inFlightRequest = this.query.highlightsTopJournals();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let data = null;
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        data = response.body;
      }

      this.setState({ loading: false, loadingFailed, data });
    });
  };

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  get hasResults() {
    return this.state.data && this.state.data.length > 0;
  }

  trackJumpLink = () => {
    Analytics.trackEvent('highlights-click-jump-link', {
      jumpedTo: 'top-journals'
    });
  };
}

export default withRouter(TopJournals);
