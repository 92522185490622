import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';

import ListIcon from 'icons/list.svg';
import GridIcon from 'icons/box-unchecked.svg';

class ViewSelector extends React.Component {
  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  render() {
    return (
      <div className="Explorer-Outputs-ViewSelector">
        <fieldset>
          <ReactTooltip effect="solid" />
          <legend>{I18n.t('Explorer.Outputs.view')}</legend>
          <div className="options">
            {this.option('grid')}
            {this.option('list')}
          </div>
        </fieldset>
      </div>
    );
  }

  option(type) {
    const Icon = type == 'list' ? ListIcon : GridIcon;

    return (
      <Link
        data-tooltip-content={I18n.t(type, { scope: 'Explorer.Outputs.view_options' })}
        to={this.query.withView(type).location}
        className={`option ${this.query.view === type ? 'active' : ''}`}
        onClick={() => this.trackEvent(type)}
      >
        <Icon />
        <label>{I18n.t(type, { scope: 'Explorer.Outputs.view_options' })}</label>
      </Link>
    );
  }

  trackEvent = (type) => {
    Analytics.trackEvent('research-outputs-change-view', { type });
  };
}

export default withRouter(ViewSelector);
