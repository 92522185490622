import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import Color from 'color';

class Gauge extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static propTypes = {
    color: PropTypes.string.isRequired,
    percentage: PropTypes.number.isRequired
  };

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    const backgroundPath = this.getPath(0, 1);
    const foregroundPath = this.getPath(0, this.props.percentage);
    const backgroundColor = new Color(this.props.color).alpha(0.2).rgb().string();

    return (
      <svg className="Gauge" viewBox="-10 -10 120 120">
        <path d={backgroundPath} fill="none" strokeWidth="15" stroke={backgroundColor} />
        <path d={foregroundPath} fill="none" strokeWidth="15" stroke={this.props.color} />
      </svg>
    );
  }

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  getPath(startProportion, endProportion) {
    let c = 0.99;
    let fullAngle = Math.PI * 2 * c;
    let startAngle = fullAngle - startProportion * fullAngle;
    let endAngle = fullAngle - endProportion * fullAngle;

    let x1, x2, y1, y2;
    [x1, y1] = this.angleToCoordinates(startAngle);
    [x2, y2] = this.angleToCoordinates(endAngle);

    let isLong = startAngle - endAngle >= Math.PI ? 1 : 0;

    return `M ${x1} ${y1} A 50 50 0 ${isLong} 1 ${x2} ${y2}`;
  }

  angleToCoordinates(θ) {
    θ = θ + Math.PI * 0.5 || 0;
    θ = θ + 0.001 * Math.PI;
    let x = 50 + Math.cos(θ) * -50;
    let y = 50 + Math.sin(θ) * 50;

    return [x, y];
  }
}

export default Gauge;
