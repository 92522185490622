import './styles';

import { map, uniq } from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';

import withRouter from 'components/withRouter';
import { Link } from 'react-router-dom';
import Transition from 'components/Explorer/Transition';

import Query from 'components/Explorer/models/query';
import Spinner from 'components/Explorer/Spinner';

import Post from '../Mentions/Post';

class RecentMentions extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  query = null;

  static propTypes = {
    history: PropTypes.object,
    visible: PropTypes.bool.isRequired,
    mentionSource: PropTypes.object.isRequired,
    mentionsLink: PropTypes.object.isRequired,
    filtersForMentionSource: PropTypes.object.isRequired
  };

  state = {
    visible: false,
    loading: false,
    loadingFailed: false,
    loaded: false,
    data: []
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    if (this.inFlightRequest) this.inFlightRequest.abort();
    this.query.cleanup();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ visible: nextProps.visible });

    if (!this.state.loaded && nextProps.visible) {
      this.fetchData();
    }
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    if (!this.state.visible) return null;

    return (
      <div className="MentionSources-RecentMentions">
        <Transition classNames="Content" timeout={250}>
          {this.state.loading ? this.loadingMessage : this.content}
        </Transition>
      </div>
    );
  }

  get loadingMessage() {
    return <Spinner key="spinner" />;
  }

  get content() {
    if (this.state.loadingFailed) return this.errorMessage;

    return (
      <div key="content">
        <div className="mention-timeline">
          {this.state.data.map(([date, posts]) => {
            const uniqPosts = uniq(posts);

            return (
              <div className="mention-date-boundary" key={`mentions-${date}`}>
                {map(uniqPosts, (post, index) => (
                  <Post first={index === 0} post={post} key={post.id} />
                ))}
              </div>
            );
          })}
        </div>
        {this.viewAllButton}
      </div>
    );
  }

  get errorMessage() {
    return <div key="error-message">{I18n.t('Explorer.loading_error')}</div>;
  }

  get viewAllButton() {
    return (
      <div className="MentionSources-RecentMentions-All">
        <Link className="view-all-button" to={this.props.mentionsLink}>
          {I18n.t('Explorer.MentionSources.mentions_button', {
            count: this.props.mentionSource.mentions
          })}
        </Link>
      </div>
    );
  }

  // Data
  //////////////////////////////////////////////////////////////////////////////

  fetchData = () => {
    this.setState({ loading: true, loadingFailed: false });
    if (this.inFlightRequest) this.inFlightRequest.abort();

    let scopedQuery = this.query.withFilters(this.props.filtersForMentionSource).withPerPage(3);

    this.inFlightRequest = scopedQuery.mentions();
    this.inFlightRequest.end((err, response) => {
      this.inFlightRequest = null;

      let newState = { data: null };
      const loadingFailed = err || typeof response.body === 'undefined';

      if (!loadingFailed) {
        newState = { data: response.body.data, loaded: true };
      }

      this.setState({ loading: false, loadingFailed, ...newState });
    });
  };
}

export default withRouter(RecentMentions);
