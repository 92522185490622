import { flow, map, orderBy, reject } from 'lodash-es';

class DataProvider {
  constructor(dataset) {
    this.unfilteredSize = dataset.length;
    this.size = dataset.length;
    this.sortKey = 'mentioned_research_outputs';
    this.dataset = dataset;
    this.filteredIndexes = [];
    this.filterText = '';

    this.sortAndFilter();
  }

  getObjectAt(index) {
    return this.dataset[this.filteredIndexes[index]];
  }

  sortAndFilter() {
    this.dataset = flow((d) => orderBy(d, [this.sortKey, 'title'], ['desc', 'asc']))(this.dataset);

    this.filteredIndexes = flow(
      (d) =>
        map(d, (journal, index) => {
          if (journal.title.toLowerCase().indexOf(this.filterText) > -1) return index;
        }),
      (d) => reject(d, (index) => index === undefined)
    )(this.dataset);

    this.size = this.filteredIndexes.length;
  }

  sortedBy(key) {
    this.sortKey = key;
    this.sortAndFilter();

    return this;
  }

  filteredBy(filterText) {
    this.filterText = filterText;
    this.sortAndFilter();

    return this;
  }
}

export default DataProvider;
