const setup = () => {
  handleSkipLink();
};

const handleSkipLink = () => {
  const skipTo = document.querySelector('a.skip-to-content');
  if (!skipTo) return;

  skipTo.addEventListener('click', (e) => {
    e.preventDefault();
    skipTo.focus();
  });
};

export { setup };
