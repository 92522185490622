import ISBN from 'isbn-utils';

const extract = (text) => {
  const isbn = ISBN.parse(text.replace(/\s+/g, ''));
  if (isbn) {
    return [isbn.asIsbn13()];
  } else {
    return [];
  }
};

export default { extract };
