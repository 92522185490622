import React from 'react';
import { useLocation } from 'react-router-dom';

const withLocation = (Component) => {
  const WrappedComponent = (props) => {
    const location = useLocation();

    return <Component location={location} {...props} />;
  };

  return WrappedComponent;
};

export default withLocation;
