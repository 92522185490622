import './styles';

import React from 'react';
import PropTypes from 'prop-types';

import Output from './Output';

const DEFAULT_VISIBLE_OUTPUTS_COUNT = 2;

class Outputs extends React.Component {
  static defaultProps = {
    visibleOutputsCount: DEFAULT_VISIBLE_OUTPUTS_COUNT
  };

  static propTypes = {
    outputs: PropTypes.array.isRequired,
    visibleOutputsCount: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.outputsCount = this.props.outputs.length;
  }

  render() {
    if (!this.outputsCount) return null;

    return (
      <div className="Outputs">
        {this.toggle}
        {this.researchOutputs}
      </div>
    );
  }

  get toggle() {
    return (
      <p className="toggle">
        <strong>
          {I18n.t('Explorer.Mentions.Mention.cites', {
            count: this.outputsCount
          })}
        </strong>
        {this.toggleButton}
        <strong>:</strong>
      </p>
    );
  }

  get toggleButton() {
    if (this.outputsCount <= this.props.visibleOutputsCount) return;

    let text;
    if (this.state.visible) {
      text = I18n.t('Explorer.Mentions.Mention.show_fewer');
    } else {
      text = I18n.t('Explorer.Mentions.Mention.cites_more', {
        count: Math.max(this.outputsCount - this.props.visibleOutputsCount, 0)
      });
    }

    return (
      <span>
        &nbsp;
        {/* eslint-disable-next-line -- jsx-a11y [TODO] */}
        <span onClick={this.toggleOutputsVisibility} className="truncated-toggle">
          {text}
        </span>
      </span>
    );
  }

  get researchOutputs() {
    return this.visibleOutputs.map((output) => (
      <Output key={`output-${output.id}`} output={output} onBadgeClick={this.onBadgeClick} />
    ));
  }

  get visibleOutputs() {
    if (this.state.visible) {
      return this.props.outputs;
    } else {
      return this.props.outputs.slice(0, this.props.visibleOutputsCount);
    }
  }

  toggleOutputsVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };
}

export default Outputs;
